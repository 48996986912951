<template>
  <div id="GameChat">
    <div class="chatContainer">
      <div class="simple-popup" :class="{ show: isShowPopup }"> {{ popupMsg }} </div>

      <!-- 對話紀錄 -->
      <div class="chat-list-view" :class="chatViewListClass">
        <i class="btn-hide el-icon-close" @click="toggleChatRoom()"></i>
        <i
          class="btn-extend el-icon-arrow-up"
          :class="isExtended ? 'active' : ''"
          @click="toggleHeight()"
        ></i>
        <div class="chat-history" ref="history">
          <template v-for="(it, index) in ChatList">
            <template v-if="it.isAnnMessage">
              <div :key="index" class="annMessage"> {{ it.annMsg }} </div>
            </template>
            <template v-else>
              <div class="msg-wrap" :class="isSelfMessage(it.mbID) ? 'self' : ''" :key="index">
                <div class="msg-row">
                  <div
                    class="UserName"
                    :key="'name' + index"
                    v-if="!isSelfMessage(it.mbID)"
                    @click="insertAtNameToInput(it.Name)"
                  >
                    {{ it.Name }}
                  </div>
                  <template v-if="it.AtNameFlag">
                    <div class="msg" v-html="parseEmojiMessage(JSON.parse(it.Mes)[1])"></div>
                  </template>
                  <template v-else>
                    <div class="msg" v-html="parseEmojiMessage(it.Mes)"></div>
                  </template>
                </div>
                <div class="time">
                  {{ funnyFormat(it.CreateTime) }}
                </div>
              </div>
            </template>
          </template>
        </div>
      </div>

      <!-- 操作區 -->
      <div class="chat-control">
        <!-- 遮罩 -->
        <div class="cover" v-if="!isChatEnable" @click="toggleChatRoom()"></div>
        <div
          class="btn-toggleChat"
          :class="isChatEnable ? '' : 'active'"
          @click="toggleChatRoom()"
        ></div>
        <div
          class="btn-quickMsg"
          :class="isShowQuickMsgList ? 'active' : ''"
          @click="onQuickMsgListBtnClick()"
        ></div>
        <div class="input-wrap">
          <emoji-input
            ref="emojiInput"
            class="emoji-input"
            :maxlength="50"
            :placeholder="placeholder"
            @input="onEmojiInputInput"
            @keydown.enter="sendMessage"
            @focusin="focusin"
            @focusout="focusout"
          />
          <div class="btn-toggleEmoji" @click="onEmojiPanelBtnClick()"></div>
        </div>
        <div class="btn-submit" @click="sendMessage"> </div>
      </div>

      <!-- 快選訊息 list -->
      <ul class="quickMsgList" :class="isShowQuickMsgList ? 'active' : ''">
        <li
          v-for="(str, i) in quickMsgList"
          :key="i"
          @click="
            $refs.emojiInput.setInputValue(str);
            $refs.emojiInput.focus();
            isShowQuickMsgList = false;
          "
          >{{ str }}
        </li>
      </ul>

      <!-- 表情選單 list -->
      <div class="emoji-panel" :class="isShowEmojiPanel ? 'active' : ''">
        <ul class="emoji-group-nav" :class="isShowQuickMsgList ? 'active' : ''">
          <li
            v-for="(group, index) in emojiGroupsArray"
            :class="curEmojiGroupIndex === index ? 'active' : ''"
            :key="index"
            @click="curEmojiGroupIndex = index"
          >
            <img :src="group[0] ? group[0].base64Img : ''" />
          </li>
        </ul>
        <ul class="emoji-list">
          <li
            v-for="(emoji, index) in currentEmojiGroup"
            :key="index"
            @click="insertEmojiToInput(emoji.base64Img, emoji.gid, emoji.uniqueKey)"
          >
            <img :src="emoji.base64Img" :title="emoji.uniqueKey" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  import emojiInput from '@/components/EmojiInput';
  export default {
    name: 'GameChat',
    components: {
      emojiInput,
    },
    data() {
      return {
        isChatEnable: false,
        maxlength: 50,
        placeholder: '大家好~',
        isExtended: false,
        isShowQuickMsgList: false,
        isShowEmojiPanel: false,
        curEmojiGroupIndex: 0,
        isMounted: false,

        // popup
        isShowPopup: false,
        popupMsg: '',
        popupTimer: null,
      };
    },
    created() {
      console.log('gameChat created');
      window.chat && window.chat.initEmojiData();
    },
    computed: {
      ChatList() {
        return this.$store.state.Chat.ChatList;
      },
      quickMsgList() {
        return this.$store.state.Chat.defaultMSG;
      },
      emojiDataRaw() {
        return this.$store.state.Chat.emojiDataRaw;
      },
      emojiGroups() {
        return this.$store.getters['Chat/emojiGroups'];
      },
      emojiGroupsArray() {
        return Object.keys(this.emojiGroups).map((groupName) => this.emojiGroups[groupName]);
      },
      allEmojis() {
        return this.emojiGroupsArray.flat();
      },
      currentEmojiGroup() {
        return this.emojiGroupsArray[this.curEmojiGroupIndex] || [];
      },
      chatViewListClass() {
        return {
          active: this.isChatEnable,
          extended: this.isExtended,
        };
      },
      tagName() {
        if (!this.isMounted) return null;
        const msg = this.$refs?.emojiInput?.value || '';
        const indexOfAt = msg.indexOf('@');
        if (indexOfAt !== -1) {
          const str = msg.slice(indexOfAt + 1);
          const indexOfSpace = str.indexOf(' '); // 尋找空格(名字結尾依據)
          if (indexOfSpace !== -1) {
            const name = str.slice(0, indexOfSpace);
            return name;
          } else {
            if (str.length > 1) {
              const name = str.slice(0);
              return name;
            }
          }
        }
        return null;
      },
      loginResultCode() {
        return this.$store.state.Chat.loginResultCode;
      },
    },
    watch: {
      isChatEnable(enabled) {
        this.$nextTick(() => {
          if (enabled) {
            this.scrollBottom();
            this.placeholder = '請輸入文字...';
            this.$refs.emojiInput.focus();

            if (this.loginResultCode === -100) {
              // 登入失敗, 重新登入
              window.chat.APILoginMB();
            } else if (this.loginResultCode === -105) {
              // 提示未設定暱稱
              this.showNameUnsetMessage();
            }
          } else {
            this.$refs.emojiInput.setInputValue('');
            this.placeholder = '大家好~';
            this.isExtended = false;
            this.isShowQuickMsgList = false;
            this.isShowEmojiPanel = false;
          }
        });
      },
      ChatList: {
        handler() {
          this.scrollBottom();
        },
        immediate: true,
      },
    },
    methods: {
      focusin() {
        this.$store.commit('Chat/setChatInputFocus', true);
      },
      focusout() {
        this.$store.commit('Chat/setChatInputFocus', false);
      },
      toggleChatRoom() {
        this.isChatEnable = !this.isChatEnable;
      },
      toggleHeight() {
        this.isExtended = !this.isExtended;
      },
      sendMessage(e) {
        e.preventDefault();
        if (this.loginResultCode === -105) {
          this.showNameUnsetMessage();
          return;
        }
        const msg = this.$refs.emojiInput.value;
        const tagName = this.tagName;
        if (msg !== '') {
          if (tagName) {
            window.chat.SendMessageWithTagName(tagName, msg);
          } else {
            window.chat.SendMessage(msg);
          }
          this.$refs.emojiInput.setInputValue('');
          this.isShowQuickMsgList = false;
          this.isShowEmojiPanel = false;
        }
      },
      scrollBottom() {
        if (this.$refs.history !== undefined) {
          this.$nextTick(() => {
            const view = this.$refs.history;
            view.scrollTo({ top: view.scrollHeight });
          });
        }
      },
      isSelfMessage(mbID) {
        return this.$store.state.userAccount === mbID;
      },
      funnyFormat(data) {
        const createTimeStr = data.toString();
        return `${createTimeStr[8]}${createTimeStr[9]}:${createTimeStr[10]}${createTimeStr[11]}`;
      },
      insertEmojiToInput(base64, gid, uniqueKey) {
        this.$refs.emojiInput.insertEmojiToInput(base64, gid, uniqueKey);
      },
      insertAtNameToInput(name) {
        this.$refs.emojiInput.insertTextAtCaret(`@${name} `);
        this.$refs.emojiInput.placeCaretAtEnd();
      },
      parseEmojiMessage(msg) {
        let result = msg;
        this.allEmojis.forEach(({ uniqueKey, base64Img }) => {
          if (result.indexOf(uniqueKey) !== -1) {
            result = result.replaceAll(uniqueKey, `<img class="emoji" src="${base64Img}">`);
          }
        });
        // 過濾掉 <img> 以外的所有 HTML TAG
        result = result.replace(/(<\/?(?:a|img)[^>]*>)|<[^>]+>/gi, '$1');

        // 渲染tagName
        const atNameRegex = new RegExp(/(?=@)(.*?)(?=\s)/);
        const atNameRegexAll = new RegExp(/(?=@)(.*?)(?=\s)/g);
        const match = result.match(atNameRegexAll);
        const names = Array.isArray(match) ? match : [];
        // console.log(names);
        while (names.length) {
          const name = names.shift();
          result = result.replace(atNameRegex, `<span style="color: #03A9F4">${name}</span>`);
        }
        return result;
      },
      onEmojiInputInput(val) {
        // console.warn('@input: ', val);
      },
      onQuickMsgListBtnClick() {
        this.isShowQuickMsgList = !this.isShowQuickMsgList;
        this.isShowEmojiPanel = false;
      },
      onEmojiPanelBtnClick() {
        this.isShowEmojiPanel = !this.isShowEmojiPanel;
        this.isShowQuickMsgList = false;
      },
      showPopup({ msg, cb } = {}) {
        clearTimeout(this.popupTimer);
        this.popupMsg = msg || '';
        this.isShowPopup = true;
        this.popupTimer = setTimeout(() => {
          this.isShowPopup = false;
          cb && cb();
        }, 3000);
      },
      showNameUnsetMessage() {
        this.showPopup({
          msg: '您尚未設定暱稱，正為您轉跳至設定頁....',
          cb: () => {
            this.$emit('openPersonalPanel');
          },
        });
      },
    },
    mounted() {
      this.isMounted = true;
    },
    beforeDestroy() {
      clearTimeout(this.popupTimer);
    },
  };
</script>

<style lang="scss" scoped>
  #app[data-theme^='light'] {
    #GameChat {
      .chat-history {
      }
    }
  }

  #app[data-theme^='dark'] {
    #GameChat {
      .chat-history {
        background-color: #4f4f4f;
      }
    }
  }
  #GameChat {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    ::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    ::-webkit-scrollbar:vertical {
      width: 4px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background-color: rgba(0, 0, 0, 0.3);
    }

    .chatContainer {
      position: relative;
      height: 100%;
      display: flex;
      flex-flow: column;
      justify-content: flex-end;
      pointer-events: none;
      & > * {
        pointer-events: auto;
      }
      .cover {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 87;
        cursor: pointer;
        transition: 50ms ease;
        background-color: rgba(0, 0, 0, 0.25);
        &:hover {
          background-color: rgba(255, 255, 255, 0.05);
        }
      }
      .chat-control {
        flex-shrink: 0;
        position: relative;
        display: flex;
        height: 60px;
        width: 100%;
        align-items: center;
        background-color: #333;
        padding: 0 1rem;
        z-index: 10;
        & :first-child {
          margin-left: 0 !important;
        }
        .btn-toggleChat {
          flex-shrink: 0;
          width: 26px;
          height: 26px;
          cursor: pointer;
          background: url(~@/assets/img/common/chat/btn_chatroom.svg);
          background-position: center top;
          background-repeat: no-repeat;
          background-size: 100% 200%;
          &.active {
            background-position: center bottom;
          }
          &:hover {
            opacity: 0.7;
          }
        }
        .btn-quickMsg {
          flex-shrink: 0;
          width: 23px;
          height: 23px;
          background: url(~@/assets/img/common/chat/btn_quickMsg.svg);
          background-size: 100% 200%;
          background-repeat: no-repeat;
          background-position: center top;
          margin: 0 0 0 1rem;
          cursor: pointer;
          &:hover {
            opacity: 0.7;
          }

          &.active {
            background-position: center bottom;
          }
        }
        .input-wrap {
          flex-grow: 1;
          position: relative;
          width: 0;
          height: calc(100% - 20px);
          line-height: 40px;
          background-color: white;
          border-radius: 5px;
          color: gray;
          font-size: 16px;
          padding: 0 10px;
          margin: 0 1rem;
          cursor: pointer;
          input {
            border: none !important;
            width: 100%;
          }
          .emoji-input {
            width: calc(100% - 30px);
          }
          .btn-toggleEmoji {
            position: absolute;
            top: 50%;
            right: 8px;
            transform: translateY(-50%);
            width: 23px;
            height: 23px;
            background: url(~@/assets/img/common/chat/btn_emoji.png);
            background-size: 100%;
            background-repeat: no-repeat;
            background-position: center top;
            margin: 0 0 0 1.5rem;
            cursor: pointer;
            opacity: 0.5;
            &:hover {
              opacity: 1;
            }
          }
        }
        .btn-submit {
          flex-shrink: 0;
          width: 26px;
          height: 26px;
          background-image: url(~@/assets/img/common/chat/icon_send.svg);
          background-position: center !important;
          background-repeat: no-repeat;
          background-size: 100%;
          cursor: pointer;
          &:hover {
            opacity: 0.7;
          }
        }
      }
      .chat-list-view {
        position: relative;
        width: 100%;
        height: calc(100% - 130px);
        overflow: hidden;
        border-top: 2px solid #bbb;
        background-color: #fff;
        box-shadow: 0px -15px 10px -15px rgba(0, 0, 0, 0.3);
        transition: all 400ms ease;
        opacity: 0;
        transform: translateY(100%);
        pointer-events: none;

        &.active {
          opacity: 1;
          transform: translateY(0);
          pointer-events: auto;
        }
        &.extended {
          height: 100%;
        }
        i.btn-hide {
          position: absolute;
          right: 0.35rem;
          top: 0.5rem;
          font-size: 1.65rem;
          font-weight: 600;
          color: #888;
          text-shadow: 0 0 10px rgba(0, 0, 0, 0.35);
          cursor: pointer;
          z-index: 1;
          &:hover {
            color: #000;
          }
        }
        i.btn-extend {
          position: absolute;
          right: 0.35rem;
          top: 2.5rem;
          font-size: 1.65rem;
          font-weight: 600;
          color: #888;
          text-shadow: 0 0 10px rgba(0, 0, 0, 0.35);
          cursor: pointer;
          z-index: 1;
          &:hover {
            color: #000;
          }
          &.active {
            transform: rotate(180deg);
          }
        }
        .chat-history {
          display: flex;
          flex-flow: column nowrap;
          height: 100%;
          overflow-x: hidden;
          overflow-y: overlay;
          padding: 10px;
          padding-right: 15px;
          .UserName {
            margin-bottom: 5px;
            font-size: 1.25rem;
            color: #444;
          }
          .msg-wrap {
            display: flex;
            margin-bottom: 1rem;
            .msg-row {
              max-width: 100%;
              .avatar {
                flex-shrink: 0;
                width: 45px;
                height: 45px;
                border: 1px solid #000;
                border-radius: 50rem;
                background-color: #333;
                overflow: hidden;
                margin-right: 20px;
                img {
                  width: 100%;
                }
              }
              .msg {
                color: #444;
                padding: 6px 12px;
                line-height: normal;
                font-size: 1.153rem;
                min-height: 2rem;
                max-width: 100%;
                border-radius: 7px;
                display: inline-block;
                position: relative;
                background: #efefef;
                word-break: break-all;

                /* 泡泡之尾巴 */
                // &::after {
                //   position: absolute;
                //   content: ' ';
                //   bottom: 0;
                //   height: 0;
                //   width: 0;
                //   border: 0 solid transparent;
                //   pointer-events: none;
                //   border-right-color: #efefef;
                //   border-width: 10px;
                //   transform: translateX(-100%);
                //   left: 0;
                //   top: 20%;
                // }

                &::v-deep img {
                  max-width: 100%;
                  &.emoji {
                    height: 1.6rem;
                    width: auto;
                    vertical-align: text-bottom;
                    margin: 0 3px;
                  }
                }
              }
            }
            .time {
              margin: 0 8px;
              color: #6c757d;
              font-size: 1rem;
              text-align: center;
              display: flex;
              align-items: flex-end;
            }

            &.self {
              align-self: flex-end;
              flex-flow: row-reverse;

              .avatar {
                display: none;
              }
              .msg {
                background-color: #e8f1f3;
                &::after {
                  border-left-color: #e8f1f3;
                  border-right-color: transparent;
                  transform: translateX(100%);
                  right: 0;
                  left: unset;
                }
              }
            }
          }
          .annMessage {
            align-self: center;
            color: #000;
            font-size: 1.076rem;
            line-height: normal;
            min-width: 50px;
            margin: 10px auto;
            padding: 8px;
            border-radius: 8px;
            background-color: #fff0af;
            box-shadow: 1px 2px 2px -2px rgba(0, 0, 0, 50%);
          }
        }
      }
      ul.quickMsgList {
        position: absolute;
        bottom: 60px;
        width: 100%;
        height: 250px;
        overflow-y: auto;
        transform: translateY(100%);
        transition: 300ms ease;
        background: rgba(42, 79, 107, 0.9);
        opacity: 0.5;
        z-index: 2;
        &.active {
          opacity: 1;
          transform: translateY(0);
        }

        li {
          border-bottom: 1px solid #547894;
          line-height: 40px;
          color: #fff;
          font-size: 1.3rem;
          padding: 0 0.8rem;
          cursor: pointer;
          &:hover {
            background-color: rgba(0, 107, 214, 0.8);
          }
        }
      }

      .emoji-panel {
        display: flex;
        flex-flow: column;
        position: absolute;
        bottom: 70px;
        left: 50%;
        width: 80%;
        max-width: 400px;
        // height: 250px;
        overflow: hidden;
        border: 1px solid #555;
        border-radius: 6px;
        background: rgba(255, 255, 255, 0.9);
        box-shadow: 0 0px 10px rgba(0, 0, 0, 0.3);
        opacity: 0;
        z-index: 3;
        pointer-events: none;
        transform: translate(-50%, 10px);
        transition: 300ms ease;
        &.active {
          opacity: 1;
          pointer-events: auto;
          transform: translate(-50%, 0);
        }

        ul.emoji-group-nav {
          flex: 0 0;
          display: flex;
          background-color: #ddd;
          box-shadow: 0 0px 6px rgba(0, 0, 0, 0.5);
          min-height: 40px;
          z-index: 1;

          li {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: transparent;
            width: 50px;
            height: 40px;
            padding: 5px;
            border-right: 1px solid transparent;
            cursor: pointer;
            img {
              height: 100%;
            }
            &:hover {
              background-color: #a6c4dc;
            }
            &:active,
            &.active {
              background-color: #70aada;
            }
          }
        }

        ul.emoji-list {
          display: flex;
          flex-flow: row wrap;
          padding: 3px;
          overflow-y: auto;
          li {
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            border-radius: 4px;
            padding: 3px;
            width: 10%;
            opacity: 0.9;
            // transition: 100ms ease;
            img {
              width: 100%;
            }
            &:hover {
              transform: scale(1.1);
              background-color: rgba(0, 0, 0, 0.2);
              opacity: 1;
            }
            &:active {
              transform: scale(1);
            }
          }
        }
      }

      .simple-popup {
        position: absolute;
        width: 80%;
        height: 100px;
        background: rgb(0 0 0 / 80%);
        border-radius: 15px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale(0.8);
        transition: all 200ms ease;
        opacity: 0;
        color: white;
        font-size: 15px;
        padding: 10px;
        pointer-events: none;
        z-index: 20;

        &.show {
          display: flex;
          justify-content: center;
          align-items: center;
          transform: translate(-50%, -50%) scale(1);
          opacity: 1;
          pointer-events: auto;
        }
      }
    }
  }
</style>
