<template>
  <div id="BetViewList" ref="BetViewList" v-loading="isLoading">
    <template v-if="!isQuickBetEnable || groupIndex === 1">
      <!-- 購物車 -->
      <template v-if="groupIndex === 0">
        <template v-if="isShowChartList || isShowCharStrayList">
          <!-- item -->
          <listCardItem
            v-for="(cartData, cartIndex) in showBetCartList"
            :cartData="cartData"
            :childIndex="childIndex"
            :cartIndex="cartIndex"
            :listCardItemClassJudge="listCardItemClassJudge(cartData.GameID, cartData)"
            :currShowKeyboardIndex="currShowKeyboardIndex"
            :key="cartData.GameID"
            :isControlByBetSingle="isControlByBetSingle"
            :isShowMinText="cartData.isShowMinText"
            :isShowMaxText="cartData.isShowMaxText"
            :isShowMaxChip="isShowMaxChip"
            :theMaxChipValue="theMaxChipValue"
            :wholeFocusTarget="lastClickInput"
            @cancelSingleHandler="cancelSingleHandler"
            @inputRowItemChangeHandler="inputRowItemChangeHandler"
            @onCartListItemKeyboardShow="onCartListItemKeyboardShow"
            @inputFocusEvent="inputFocusEvent"
            @lastBlurInputEvent="listCardItemLastBlurInputEvent"
            @Add="keyBoardAddEvent"
            @Assign="keyBoardAssignEvent"
            @MobileListItemSubmitBet="submitHandler"
            @clearItemInputByIndex="clearItemInputByIndex"
          ></listCardItem>
        </template>
      </template>

      <!-- 注單紀錄 -->
      <template v-if="groupIndex === 1">
        <HistoryCardItem
          v-for="(historyItem, historyIndex) in showBetHistoryList"
          :key="historyIndex"
          :historyItem="historyItem"
          :isSettlement="isSettlement"
        >
        </HistoryCardItem>
      </template>

      <div
        class="cardOptionBlock"
        v-if="isMobileMode && isControlByBetSingle && panelMode == PanelModeEnum.lock"
      >
        <div class="buttonRow">
          <div class="submitBtn" style="text-align: center" @click="submitHandler">
            {{ $t('Common.SubmitBet') }}
          </div>
        </div>
      </div>

      <!-- 單向投注下方面板 -->
      <div class="cardOptionBlock" v-if="isShowChartList && !isControlByBetSingle">
        <div class="betRows-wrap">
          <ul class="betInputRow" v-if="panelMode === PanelModeEnum.normal">
            <li class="betInputTitle"> {{ $t('Common.SingleOdd') }} </li>
            <li>
              <input
                :class="{ gray: lastClickInput === 2 }"
                ref="fillEachBetAmount"
                v-model="fillEachBetAmount"
                type="number"
                :readonly="isMobileMode"
                @focus="
                  onInputFocus();
                  lastClickInput = 1;
                "
                @input="fillEachBetAmountHandler"
                @click="
                  isShowBetKB = lastClickInput !== 1 || !isShowBetKB;
                  lastClickInput = 1;
                "
                @blur="fillEachBetAmountBlurHandler"
              />
              <div class="betInputRowAbsoluteBlock">x {{ showBetCartList.length }}</div>
              <span class="btnClearValue" v-if="fillEachBetAmount" @click="clearInput('normal')">
                <i class="el-icon-error"></i>
              </span>
            </li>
          </ul>

          <ul class="betInputRow" v-if="panelMode === PanelModeEnum.normal">
            <li class="betInputTitle"> {{ $t('Common.CanWinMoney') }} </li>
            <li>
              <input
                :class="{ gray: lastClickInput === 1 }"
                v-model="fillEachWinAmount"
                type="number"
                :readonly="isMobileMode"
                @focus="
                  onInputFocus();
                  lastClickInput = 2;
                "
                @input="fillEachWinAmountHandler"
                @click="
                  isShowBetKB = lastClickInput !== 2 || !isShowBetKB;
                  lastClickInput = 2;
                "
              />
              <div class="betInputRowAbsoluteBlock">x {{ showBetCartList.length }}</div>
              <span class="btnClearValue" v-if="fillEachWinAmount" @click="clearInput('normal')">
                <i class="el-icon-error"></i>
              </span>
            </li>
          </ul>
        </div>

        <!-- 小鍵盤 -->
        <mBetKeyboard
          v-if="isMobileMode && isShowBetKB && panelMode === PanelModeEnum.normal"
          :isShowMaxChip="isShowMaxChip"
          :theMaxChipValue="theMaxChipValue"
          @Add="keyBoardAddEvent"
          @Assign="keyBoardAssignEvent"
        ></mBetKeyboard>

        <!-- 小籌碼 -->
        <ChipsBar
          v-if="!isMobileMode && panelMode === PanelModeEnum.normal"
          :isShowMaxChip="isShowMaxChip"
          :theMaxChipValue="theMaxChipValue"
          @onChipClick="onChipClick"
        ></ChipsBar>

        <div class="amountTipRow" v-if="isShowStatusData2">
          <img src="@/assets/img/common/icon_event.svg" alt="" />

          <div style="margin-left: 10px">
            {{ $t('BetViewList.HaveChoose') }} <span style="color: #ffea01">{{ tipAmount }}</span>
            {{ $t('BetViewList.Change') }}
          </div>
        </div>

        <div class="totalRow">
          <div class="halfItem">
            {{ $t('BetViewList.TotalBet') }} : {{ toCurrency(totalBetAmount) }}
          </div>
          <div class="halfItem">
            {{ $t('Common.CanWinMoney') }} :
            <span
              :style="isWinAmountChangeColor ? winAmountColor : ''"
              :class="betDataChangeList.length !== 0 ? 'halfItemChange' : ''"
            >
              {{ toCurrency(totalWinAmount) }}
            </span>
          </div>
        </div>
        <div class="buttonRow">
          <div class="clearBtn" @click="resultLeftBtnClickHandler()">
            {{ OptionCancelBtnStr }}
          </div>
          <div
            class="acceptChange"
            v-if="isShowAcceptTip && panelMode !== PanelModeEnum.result"
            @click="submitHandler"
          >
            {{ $t('BetViewList.AcceptChange') }}
          </div>
          <div
            class="submitBtn"
            v-else-if="panelMode !== PanelModeEnum.result"
            @click="submitHandler"
          >
            {{ $t('Common.SubmitBet') }}
          </div>
          <div class="closeBtn" v-else @click="resultCancelBtnClick()">
            {{ $t('Common.Close') }}
          </div>
        </div>
      </div>

      <!-- 手機 mGamesBetInfoSingle 才有的保留下注功能 -->
      <div
        class="cardOptionBlock"
        v-if="
          isMobileMode &&
          isShowChartList &&
          panelMode === PanelModeEnum.result &&
          isControlByBetSingle
        "
      >
        <div class="buttonRow">
          <div class="clearBtn" @click="resultLeftBtnClickHandler()">
            {{ OptionCancelBtnStr }}
          </div>
          <div
            class="acceptChange"
            v-if="isShowAcceptTip && panelMode !== PanelModeEnum.result"
            @click="submitHandler"
          >
            {{ $t('BetViewList.AcceptChange') }}
          </div>
          <div
            class="submitBtn"
            v-else-if="panelMode !== PanelModeEnum.result"
            @click="submitHandler"
          >
            {{ $t('Common.SubmitBet') }}
          </div>
          <div class="closeBtn" v-else @click="resultCancelBtnClick()">
            {{ $t('Common.Close') }}
          </div>
        </div>
      </div>

      <!-- 串關投注下方面板 -->
      <div class="cardOptionBlock" v-if="isShowCharStrayList && !isControlByBetSingle">
        <div class="StrayTipBlock" v-if="isLockStray">
          <div class="bottomTextRow"> ※ {{ $t('BetViewList.LockStray') }} </div>
        </div>

        <div class="StrayTipBlock" v-else-if="EvtIdRepeatList.length !== 0">
          <div class="topTextRow"> ※ {{ $t('BetViewList.HasSameGame') }} </div>
          <div class="bottomTextRow">
            <div>{{ $t('BetViewList.HaveChoose') }}</div>
            <div class="goldTip">{{ EvtIdRepeatList.length }}</div>
            <div>{{ $t('BetViewList.CantStray') }}</div>
          </div>
        </div>

        <div class="strayErrorMessage" v-if="isShowStrayStatusData">
          {{ showBetCartList[0].statusMessage }}
        </div>

        <div class="betRows-wrap">
          <ul class="betInputRow" v-if="panelMode === PanelModeEnum.normal">
            <li class="betInputTitle">
              <div class="strayBlock">
                <div class="strayBlockTop">
                  <div class="strayTopLeft">
                    {{ `${showBetCartList.length}${$t('Common.string')}1` }}
                  </div>
                  <div class="strayTopRight">
                    <div class="num">1 </div>
                    <div class="x"> x</div>
                  </div>
                </div>
                <div class="strayBlockBottom">
                  <div v-if="!isLockStray" class="strayOdd"> @ {{ $lib.trunc(strayOdd, 2) }} </div>
                </div>
              </div>
            </li>
            <li>
              <input
                ref="strayBetAmount"
                v-model="strayBetAmount"
                type="number"
                read="true"
                :readonly="isMobileMode"
                :class="isShowMinText || isShowMaxText ? 'redErrorInput' : ''"
                @focus="onInputFocus()"
                @input="strayBetAmountInputChangeHandler"
                @click="isShowStrayKB = !isShowStrayKB"
                @blur="strayBetBlurHandler"
              />
              <span class="btnClearValue stray" v-if="strayBetAmount" @click="clearInput('stray')">
                <i class="el-icon-error"></i>
              </span>
            </li>
          </ul>
          <ul class="betInputRow" v-if="panelMode === PanelModeEnum.normal">
            <li class="betInputTitle"> {{ $t('Common.CanWinMoney') }} </li>
            <li class="betReadInput">{{ $lib.truncFloor(strayBetAmount * strayOdd) }}</li>
          </ul>
        </div>

        <!-- 小鍵盤 -->
        <mBetKeyboard
          v-if="isMobileMode && isShowStrayKB && panelMode === PanelModeEnum.normal"
          :isShowMaxChip="isShowMaxChip"
          :theMaxChipValue="theMaxChipValue"
          @Add="keyBoardAddEvent"
          @Assign="keyBoardAssignEvent"
        ></mBetKeyboard>

        <div class="strayLimitTipBlock">
          <!-- 串關限紅提示 -->
          <div class="limitTip" v-if="isShowMinText && childIndex === 1">
            {{ $t('Common.BetMinTip') }}
          </div>
          <div class="limitTip" v-if="isShowMaxText && childIndex === 1">
            {{ $t('Common.BetMaxTip') }}
          </div>
        </div>
        <!-- 小籌碼 -->
        <ChipsBar
          v-if="!isMobileMode && panelMode === PanelModeEnum.normal"
          :isShowMaxChip="isShowMaxChip"
          :theMaxChipValue="theMaxChipValue"
          @onChipClick="onChipClick"
        ></ChipsBar>

        <BetResultBlock
          style="margin-bottom: 7px; margin-left: 10px"
          v-if="panelMode === PanelModeEnum.result"
          :panelMode="panelMode"
          :cartData="showBetCartList[0]"
        >
        </BetResultBlock>

        <div
          class="strayRow"
          v-if="panelMode === PanelModeEnum.lock || panelMode === PanelModeEnum.result"
        >
          <div class="strayRowTitle"> {{ $t('BetViewList.StaryOnly1') }} </div>
          <div class="strayRowContent">
            {{
              `${showBetCartList.length}${$t('Common.string')}1 X 1 (${$t(
                'Common.EachZu'
              )}${strayBetAmount}${$t('Common.Dollar')} X 1 ${$t(
                'Common.Group'
              )}) = ${strayBetAmount}`
            }}
          </div>
        </div>

        <div class="totalRow">
          <div class="halfItem">
            {{ $t('BetViewList.TotalBet') }} : {{ toCurrency(strayBetAmount) }}
          </div>
          <div class="halfItem">
            {{ $t('Common.CanWinMoney') }} :
            <span
              :style="isWinAmountChangeColor ? winAmountColor : ''"
              :class="betDataChangeList.length !== 0 ? 'halfItemChange' : ''"
            >
              {{ toCurrency($lib.truncFloor(strayBetAmount * strayOdd)) }}
            </span>
          </div>
        </div>
        <div class="buttonRow">
          <div class="clearBtn" @click="resultLeftBtnClickHandler">
            {{ OptionCancelBtnStr }}
          </div>
          <div
            class="acceptChange"
            v-if="isShowAcceptTip && panelMode !== PanelModeEnum.result"
            @click="straySubmitHandler"
          >
            {{ $t('BetViewList.AcceptChange') }}
          </div>
          <div
            class="submitBtn"
            v-else-if="panelMode !== PanelModeEnum.result"
            @click="straySubmitHandler"
          >
            {{ $t('Common.SubmitBet') }}
          </div>
          <div class="closeBtn" v-else @click="resultCancelBtnClick()">
            {{ $t('Common.Close') }}
          </div>
        </div>
      </div>

      <div class="noData" v-if="groupIndex === 0 && showBetCartList.length === 0">
        <div class="noDataItem">
          <div class="noDataItemImgContainer">
            <img src="@/assets/img/pc/icon_onInfo.svg" alt="" />
          </div>
          <div> {{ $t('BetViewList.ClickToAdd') }} </div>
        </div>
      </div>

      <div
        class="noData"
        v-if="groupIndex === 1 && showBetHistoryList.length === 0 && isLoading === false"
      >
        <div class="noDataItem">
          <div class="noDataItemImgContainer">
            <img src="@/assets/img/pc/icon_noReceipt.svg" alt="" />
          </div>
          <div>{{ $t('BetViewList.NoBet') }}</div>
        </div>
      </div>

      <div class="noData" v-if="isShowStrayCantPlayTip">
        <div class="noDataItem">
          <div class="noDataItemImgContainer">
            {{ $t('BetViewList.StayNeed2Game') }}
          </div>
        </div>
      </div>
    </template>

    <div class="centerTipBlock" v-else>
      <div class="centerTip">
        <div class="tipItem">
          {{ $t('QuickBet.open') }}
          <span style="color: red">{{ $t('QuickBet.fastBet') }}</span>
          {{ $t('QuickBet.mode') }}
        </div>
        <div class="tipItem">
          {{ $t('QuickBet.setAmount') }}
        </div>
        <div class="tipItem">
          {{ $t('QuickBet.ClickOdd') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState, mapGetters } from 'vuex';
  import mBetKeyboard from '@/components/mBetKeyboard';
  import listCardItem from '@/components/ListCardItem';
  import BetResultBlock from '@/components/BetResultBlock';
  import HistoryCardItem from '@/components/HistoryCardItem';
  import ChipsBar from '@/components/ChipsBar';
  import { PanelModeEnum } from '@/config/BetPanelMode';
  import { toCurrency } from '@/config/converter';

  export default {
    components: {
      mBetKeyboard,
      listCardItem,
      BetResultBlock,
      HistoryCardItem,
      ChipsBar,
    },
    props: {
      // 0: 投注資訊
      // 1: 最新注單
      groupIndex: {
        type: Number,
        default: 0,
      },
      // 0: 投注資訊 => 單向投注
      // 1: 投注資訊 => 過關投注
      // 0: 最新注單 => 未結算注單
      // 1: 最新注單 => 可兌現注單
      childIndex: {
        type: Number,
        default: 0,
      },
      // 是否來自 mGamesBetInfoSingle 控制
      isControlByBetSingle: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        PanelModeEnum,

        // 一般投注相關數據
        totalBetAmount: 0,
        totalWinAmount: 0,
        fillEachBetAmount: null,
        fillEachWinAmount: null,
        // 串關相關數據
        strayOdd: null,
        strayBetAmount: null,
        EvtIdRepeatList: [],
        // 其他
        isLoading: false,
        intervalEvent: null,
        intervalEvent2: null,

        // 是否顯示下方 主要小鍵盤
        isShowBetKB: false,
        isShowStrayKB: false,

        // 單項投注時focus的input目標: 1=單注; 2=可贏金額
        lastClickInput: null,

        // 目前打開小鍵盤的 購物車item index
        currShowKeyboardIndex: -1,

        // 最後blur的input
        lastBlurInput: { name: 'fillEachBetAmount' },

        lastTraceCodeKey: null,

        isLockEnter: false,
        lockEvent: null,

        // 限紅提示
        isShowMinText: false,
        isShowMaxText: false,

        // 最大籌碼按鈕
        isShowMaxChip: false,
        // 最大籌碼數字
        theMaxChipValue: 1000,

        // 項目不開放投注
        isLockStray: false,
        isBalanceOK: false,
        isOverMaxwin: false,
        maxWinEx: 2000000,
      };
    },
    computed: {
      ...mapState(['userBalance']),
      ...mapGetters(['userMaxWin', 'themeInfo']),
      winAmountColor() {
        if (this.themeInfo.includes('light')) {
          return 'color:blue;';
        } else {
          return 'color:#a1d7ff;';
        }
      },
      betDataChangeList() {
        return this.showBetCartList.filter((it) => it.hasDataChange);
      },
      isReCalcBetCart() {
        return this.$store.state.BetCart.isReCalcBetCart;
      },
      OptionCancelBtnStr() {
        if (this.panelMode === this.PanelModeEnum.normal) {
          return this.$t('Common.AllClear');
        } else if (this.panelMode === this.PanelModeEnum.lock) {
          return this.$t('Common.AllClear');
        } else {
          return this.$t('BetViewList.KeepBetCart');
        }
      },
      settings() {
        return this.$store.state.Setting.UserSetting;
      },
      showBetCartList() {
        return this.$store.getters['BetCart/showBetCartList'];
      },
      showBetHistoryList() {
        return this.$store.getters['BetCart/showBetHistoryList'];
      },
      isAddNewToChart() {
        return this.$store.state.BetCart.isAddNewToChart;
      },
      panelMode() {
        return this.$store.state.BetCart.panelMode;
      },
      isClearMemberData() {
        return this.$store.state.BetCart.isClearMemberData;
      },
      isSubmitHandler() {
        return this.$store.state.BetCart.isSubmitHandler;
      },
      isShowChartList() {
        return this.groupIndex === 0 && this.childIndex === 0 && this.showBetCartList.length > 0;
      },
      isShowCharStrayList() {
        return this.groupIndex === 0 && this.childIndex === 1 && this.showBetCartList.length > 1;
      },
      isShowStrayCantPlayTip() {
        return this.groupIndex === 0 && this.childIndex === 1 && this.showBetCartList.length === 1;
      },
      isMobileMode() {
        return process.env.VUE_APP_UI === 'mobile';
      },
      // 是否在已結算注單下
      isSettlement() {
        return this.groupIndex === 1 && this.childIndex === 1;
      },
      isQuickBetEnable() {
        return this.$store.state.Game.isQuickBet.isEnable;
      },
      isWinAmountChangeColor() {
        return this.panelMode === PanelModeEnum.lock || this.panelMode === PanelModeEnum.result;
      },
      isChatInputFocus() {
        return this.$store.state.Chat.isChatInputFocus;
      },
      isShowService() {
        return this.$store.state.Game.isShowService;
      },
      isShowStatusData() {
        return this.$store.state.BetCart.isShowStatusData;
      },
      isShowStatusData2() {
        return this.$store.state.BetCart.isShowStatusData2;
      },
      isShowStrayStatusData() {
        return this.$store.state.BetCart.isShowStrayStatusData;
      },
      tipAmount() {
        return this.$store.state.BetCart.tipAmount;
      },
      isShowAcceptTip() {
        return this.$store.state.BetCart.isShowAcceptTip;
      },
      isShowTracing() {
        return this.$store.state.BetCart.isShowTracing;
      },
      flag() {
        return this.$store.state.BetCart.flag;
      },
      quickBetData() {
        return this.$store.state.BetCart.lastFirstBetData;
      },
    },
    watch: {
      // 有新增投注到購物車事件
      isAddNewToChart() {
        this.$nextTick(() => {
          this.$refs.BetViewList.scrollTop = 999999;
          this.$store.commit('BetCart/setPanelMode', this.PanelModeEnum.normal);
          this.$store.commit('BetCart/setIsShowStrayStatusData', false);

          this.reCalcBetChart();
          if (
            this.$store.state.Setting.UserSetting.defaultStrayAmount.type === 1 ||
            this.$store.state.Setting.UserSetting.defaultStrayAmount.type === 2
          ) {
            this.strayBetAmount = parseInt(
              this.$store.state.Setting.UserSetting.defaultStrayAmount.amount
            );
          }

          if (this.groupIndex === 0 && !this.isMobileMode) {
            if (this.childIndex === 0 && this.$refs.fillEachBetAmount) {
              this.$refs.fillEachBetAmount.focus();
            } else if (this.childIndex === 1 && this.$refs.strayBetAmount) {
              this.$refs.strayBetAmount.focus();
            }
          }

          if (
            this.groupIndex === 0 &&
            this.childIndex === 1 &&
            this.showBetCartList.length > this.$conf.maxStray
          ) {
            const errorMessage = this.$t('Common.TriggerStrayLimit');
            this.$notify.error({
              message: errorMessage,
            });
          }
        });
      },
      isReCalcBetCart() {
        this.reCalcBetChart();
      },
      groupIndex: {
        handler() {
          if (this.groupIndex === 1) {
            this.callBetHistoryAPI();
          }
        },
        immediate: true,
      },
      childIndex: {
        handler() {
          if (!this.isShowTracing) {
            this.$store.commit('BetCart/addTracing');
          }

          this.$store.commit('BetCart/setPanelMode', this.PanelModeEnum.normal);
          this.callBetHistoryAPI();
          if (this.groupIndex === 0) {
            if (this.childIndex === 0) {
              this.lastBlurInput = { name: 'fillEachBetAmount' };
            } else {
              this.lastBlurInput = { name: 'strayBetAmount' };
              this.isShowMaxChip = false;
            }
          }
          this.clearMinMaxTextState();
          this.clearSuccessModeItem();
        },
      },
      showBetCartList: {
        handler() {
          // 驅動過關賠率計算
          this.reCalcStrayBetChart();
          this.$emit('betCartListChanged', this.showBetCartList);

          if (this.showBetCartList.length === 0) {
            this.fillEachBetAmount = null;
            this.fillEachWinAmount = null;
          }
        },
      },
      'showBetCartList.length'() {
        // 關閉 x 筆投注資訊已變動 顯示
        this.$store.commit('BetCart/setIsShowStatusData', false);
        this.$store.commit('BetCart/setIsShowStatusData2', false);
      },
      strayOdd() {
        if (this.showBetCartList.length <= 1) {
          this.$store.commit('BetCart/setStrayOdd', null);
        } else {
          this.$store.commit('BetCart/setStrayOdd', this.strayOdd);
        }
      },
      isShowBetKB(isShowBetKB) {
        this.currShowKeyboardIndex = isShowBetKB ? -1 : this.currShowKeyboardIndex;
      },
      currShowKeyboardIndex(index) {
        this.isShowBetKB = index === -1;
      },
      isQuickBetEnable(val) {
        if (val) {
          // 如果快速投注開啟時 清空購物車
          this.cancelHandler();
        }
      },
      isClearMemberData() {
        this.clearMemberData();
      },
      isSubmitHandler() {
        // 來自快速下注的事件
        this.submitHandler(true);
      },
      flag() {
        if (this.groupIndex === 1) {
          this.callBetHistoryAPI();
        }
      },
    },
    methods: {
      toCurrency,
      clearMinMaxTextState() {
        this.isShowMinText = false;
        this.isShowMaxText = false;
      },
      clearAllMinMaxLimitState() {
        // 清除所有限紅提示
        this.clearMinMaxTextState();
        this.showBetCartList.forEach((cartData) => {
          cartData.isShowMinText = false;
          cartData.isShowMaxText = false;
          cartData.statusMessage = '';
        });
      },
      clearSuccessModeItem() {
        // 清除所有isSuccessMode的購物車內容
        this.$store.commit(
          'BetCart/setBetCartList',
          this.showBetCartList.filter((it) => !it.isSuccessMode)
        );
      },
      clearAllMessageState() {
        // 清除訊息狀態
        this.$store.commit('BetCart/resetStatus');
      },
      keyPress(e) {
        if (
          e.key === 'Enter' &&
          !this.isLockEnter &&
          !this.isChatInputFocus &&
          !this.isShowService
        ) {
          if (this.isQuickBetEnable && this.quickBetData.state === 'done') {
            this.$store.commit('BetCart/showQuickBetData', { isShow: false });
          }
          this.isLockEnter = true;
          clearTimeout(this.lockEvent);
          this.lockEvent = setTimeout(() => {
            this.isLockEnter = false;
          }, 300);
          if (
            this.groupIndex === 0 &&
            this.showBetCartList.length !== 0 &&
            this.panelMode !== this.PanelModeEnum.result
          ) {
            if (this.childIndex === 0) {
              this.submitHandler();
            } else if (this.childIndex === 1) {
              this.straySubmitHandler();
            }
          }
        }
      },
      resultCancelBtnClick() {
        if (!this.isShowTracing) {
          this.$store.commit('BetCart/addTracing');
        }
        this.$emit('setNewGroupIndex', 1);
        this.$emit('setNewChildIndex', 0);
        this.lastBlurInput = { name: 'fillEachBetAmount' };
        this.cancelHandler();
      },
      listCardItemClassJudge(GameID, cartData) {
        if (
          (this.childIndex === 1 && this.EvtIdRepeatList.indexOf(GameID) > -1) ||
          (this.panelMode === this.PanelModeEnum.result &&
            this.childIndex === 0 &&
            cartData.betResult !== null &&
            cartData.betResult?.code !== 200 &&
            cartData.betResult?.code !== 201)
        ) {
          return 'redErrorStyle';
        } else {
          return '';
        }
      },
      clearMemberData() {
        this.totalBetAmount = 0;
        this.totalWinAmount = 0;
        this.fillEachBetAmount = null;
        this.fillEachWinAmount = null;
        this.strayOdd = null;
        this.strayBetAmount = null;
        this.EvtIdRepeatList.length = 0;
        this.EvtIdRepeatList = [];
        this.$store.commit('BetCart/setPanelMode', this.PanelModeEnum.normal);
      },
      callBetHistoryAPI() {
        if (this.groupIndex === 1) {
          if (this.$refs.BetViewList) {
            this.$refs.BetViewList.scrollTop = 0;
          }
          this.isLoading = true;
          this.$store
            .dispatch('BetCart/getTicketApi', {
              isset: this.childIndex === 1,
              pagesize: 101,
              page: 1,
            })
            .then(() => {
              this.isLoading = false;
            });
        }
      },
      inputRowItemChangeHandler() {
        this.reCalcBetChart();
      },
      fillEachBetAmountHandler() {
        this.fillEachWinAmount = null;
        this.fillEachBetAmount = parseFloat(this.fillEachBetAmount.replace(/[^\d]/g, ''));
        if (isNaN(this.fillEachBetAmount)) {
          this.fillEachBetAmount = 0;
        }
        if (this.fillEachBetAmount > this.UserCredit) {
          this.fillEachBetAmount = this.UserCredit;
        }

        this.showBetCartList.forEach((cartData) => {
          cartData.betAmount = this.fillEachBetAmount;
        });
        this.reCalcBetChart();
      },
      fillEachWinAmountHandler() {
        this.fillEachBetAmount = null;
        this.fillEachWinAmount = parseFloat(this.fillEachWinAmount.replace(/[^\d]/g, ''));
        if (isNaN(this.fillEachWinAmount)) {
          this.fillEachWinAmount = 0;
        }

        this.showBetCartList.forEach((cartData) => {
          const displayData = this.cartDataToDisplayData(cartData);
          cartData.winAmount = this.fillEachWinAmount;
          cartData.betAmount = this.$lib.truncCeil(
            cartData.winAmount / this.$lib.trunc(parseFloat(displayData.showOdd))
          );
          if (cartData.betAmount > this.UserCredit) {
            cartData.betAmount = this.UserCredit;
          }
        });

        this.fillEachWinAmountBlurHandler();
      },
      strayBetAmountInputChangeHandler() {
        this.strayBetAmount = parseFloat(this.strayBetAmount.replace(/[^\d]/g, ''));
        if (isNaN(this.strayBetAmount)) {
          this.strayBetAmount = 0;
        }
        if (this.strayBetAmount > this.UserCredit) {
          this.strayBetAmount = this.UserCredit;
        }
        this.checkStrayMaxLimit();
      },
      minMaxJudge(cartData) {
        if (
          (cartData.BetMin && cartData.BetMin > this.userBalance) ||
          cartData.betAmount > this.userBalance
        ) {
          if (this.isQuickBetEnable) {
            this.isBalanceOK = false;
            this.$message({
              type: 'warning',
              message: this.$t('Common.NoBalanceTip'),
              duration: 5000,
            });
          }
          return cartData.betAmount;
        }

        if (cartData.betAmount < cartData.BetMin && cartData.BetMin !== null) {
          cartData.betAmount = cartData.BetMin;
          cartData.isShowMinText = true;

          if (this.isQuickBetEnable) {
            this.$message({
              type: 'warning',
              message: this.$t('Common.BetMinTip'),
              duration: 5000,
            });
          }
        }

        const judgeBetMax = cartData.BetMax; // this.$lib.BetMaxLimit(cartData.BetMax, cartData.BetMaxEvt);
        if (cartData.betAmount > judgeBetMax && judgeBetMax !== null) {
          cartData.betAmount = judgeBetMax;
          cartData.isShowMaxText = true;

          if (this.isQuickBetEnable) {
            this.$message({
              type: 'warning',
              message: this.$t('Common.BetMaxTip'),
              duration: 5000,
            });
          }
        }

        return cartData.betAmount;
      },
      // isMinMaxJudge 是否檢查限紅範圍
      reCalcBetChart(isMinMaxJudge = false) {
        const maxWin = this.maxWinEx;
        let newTotalBetAmount = 0;
        let newTotalWinAmount = 0;
        let isTriggerLimit = false;
        this.isBalanceOK = true;
        this.isOverMaxwin = false;
        this.clearMinMaxTextState();
        this.showBetCartList
          .filter((it) => !it.isSuccessMode)
          .forEach((cartData) => {
            const displayData = this.cartDataToDisplayData(cartData);
            if (cartData.betAmount !== null && !cartData.isForceLock) {
              cartData.betAmount = this.$lib.truncFloor(cartData.betAmount);
              cartData.isShowMinText = false;
              cartData.isShowMaxText = false;
              if (isMinMaxJudge) {
                cartData.betAmount = this.minMaxJudge(cartData);
                if (cartData.isShowMinText || cartData.isShowMaxText) {
                  isTriggerLimit = true;
                }
              }
              cartData.winAmount = this.$lib.truncFloor(
                cartData.betAmount * this.$lib.trunc(parseFloat(displayData.showOdd))
              );

              if (cartData.winAmount > this.userMaxWin) {
                cartData.betAmount = this.$lib.truncFloor(
                  this.userMaxWin / this.$lib.trunc(parseFloat(displayData.showOdd))
                );
                cartData.winAmount = this.$lib.truncFloor(
                  cartData.betAmount * this.$lib.trunc(parseFloat(displayData.showOdd))
                );
              }

              if ([112, 133].includes(cartData.WagerTypeID) && cartData.winAmount > maxWin) {
                this.isOverMaxwin = true;
              }

              newTotalBetAmount += cartData.betAmount;
              newTotalWinAmount += cartData.winAmount;
            }
          });

        this.totalBetAmount = newTotalBetAmount;
        this.totalWinAmount = newTotalWinAmount;

        return isTriggerLimit;
      },
      reCalcStrayBetChart() {
        const noStrayList = this.$store.state.siteInfo?.ParlayDisableAry;
        let strayOdd = null;
        this.EvtIdRepeatList.length = 0;
        this.isLockStray = false;
        this.showBetCartList
          .filter((it) => !it.isSuccessMode)
          .forEach((cartData, index, arr) => {
            const List = this.EvtIdRepeatList;
            const {
              CatID,
              GameID,
              EventID,
              TimeStr,
              LeagueID,
              LeagueNameStr,
              HomeID,
              HomeTeamStr,
              AwayID,
              AwayTeamStr,
              WagerTypeID,
            } = cartData;
            const displayData = this.cartDataToDisplayData(cartData);
            const odd = this.$lib.trunc(1 + parseFloat(displayData.showOdd2));
            if (strayOdd === null) {
              strayOdd = odd;
            } else {
              strayOdd = this.$lib.trunc(strayOdd * odd);
            }

            // 遊戲不能串關
            if (cartData.EnableParlay !== 1) {
              this.isLockStray = true;
              return;
            }
            // 走地不能串關
            if (cartData.GameTypeID === 2) {
              this.isLockStray = true;
              return;
            }
            // 球種不能串關
            if ([83].includes(CatID)) {
              this.isLockStray = true;
              return;
            }
            // 玩法不能串關
            if (![101, 102, 103, 104, 105, 106, 110, 111].includes(WagerTypeID)) {
              this.isLockStray = true;
              return;
            }
            // 玩法不能串關
            if (noStrayList && Array.isArray(noStrayList)) {
              if (noStrayList.includes(WagerTypeID)) {
                this.isLockStray = true;
                return;
              }
            }
            // 重複賽事不能串關
            arr.forEach((item, i) => {
              if (i === index) return;
              if (EventID === item.EvtID) {
                if (!List.includes(GameID)) List.push(GameID);
                return;
              }
              if (TimeStr !== item.TimeStr) {
                return;
              }
              if (LeagueID === item.LeagueID && HomeID === item.HomeID && AwayID === item.AwayID) {
                if (!List.includes(GameID)) List.push(GameID);
                return;
              }

              const ItemHomeTeamStr = item.HomeTeamStr.substr(0, 3);
              const ItemAwayTeamStr = item.AwayTeamStr.substr(0, 3);
              const ItemLeagueNameStr = item.LeagueNameStr.substr(0, 3);

              if (
                HomeTeamStr.includes(ItemHomeTeamStr) &&
                AwayTeamStr.includes(ItemAwayTeamStr) &&
                LeagueNameStr.includes(ItemLeagueNameStr)
              ) {
                if (LeagueNameStr.includes(ItemLeagueNameStr)) {
                  if (!List.includes(GameID)) List.push(GameID);
                }
              }
            });
          });

        // 扣掉本金 就可以得到串關賠率
        this.strayOdd = this.$lib.trunc(strayOdd - 1);
      },
      // 保留下注按鈕點擊事件
      resultLeftBtnClickHandler() {
        if (this.panelMode === this.PanelModeEnum.result) {
          if (!this.isShowTracing) {
            this.$store.commit('BetCart/addTracing', true);
          }
          this.$store.commit('BetCart/setPanelMode', this.PanelModeEnum.normal);
          this.$store.commit('BetCart/clearCartBetResult');
        } else {
          this.cancelHandler();
        }
      },
      cancelHandler() {
        this.clearMemberData();
        this.$store.commit('BetCart/clearCart');
        this.clearAllMessageState();
        this.reCalcBetChart();
      },
      cancelSingleHandler(gameID) {
        this.fillEachBetAmount = null;
        this.fillEachWinAmount = null;
        this.$store.commit('BetCart/removeCartByGameID', gameID);
        this.reCalcBetChart();
      },
      checkBetPlayData(betType, strayBetAmount) {
        // 串關檢查
        if (betType === 99) {
          let errorMessage = null;
          if (this.EvtIdRepeatList.length !== 0) {
            errorMessage = this.$t('BetViewList.StrayHaveSame');
          } else if (
            this.strayBetAmount === 0 ||
            this.strayBetAmount === '' ||
            this.strayBetAmount === null
          ) {
            errorMessage = this.$t('BetViewList.PlzInputStrayAmountFirst');
          } else if (this.$store.state.BetCart.betCartList.length > this.$conf.maxStray) {
            errorMessage = this.$t('Common.TriggerStrayLimit');
          } else if (this.isLockStray) {
            errorMessage = this.$t('BetViewList.LockStray');
          }

          if (errorMessage !== null) {
            this.$notify.error({
              message: errorMessage,
            });
            return null;
          }
        }

        // 蒐集投注資料
        const list = [];
        let errorMessage = null;
        this.$store.state.BetCart.betCartList.every((cartData) => {
          const CatId = cartData.CatID;
          const GameID = cartData.GameID;
          const WagerTypeID = cartData.WagerTypeID;
          const WagerGrpID = cartData.WagerGrpID;
          const WagerPos = cartData.wagerPos;
          const HdpPos = cartData.HdpPos;
          let CutLine = cartData.playData.playMethodData.betCutLineDealFunc(cartData);
          const data = this.$SportLib.cartDataToDisplayData(cartData);
          const odd = betType === 99 ? data.showOdd2 : data.showOdd;
          const OddValue = parseFloat(odd);
          if (CutLine === '') {
            CutLine = 0;
          }
          let WagerString = `${CatId},${GameID},${WagerTypeID},${WagerGrpID},${WagerPos},${HdpPos},${CutLine},${OddValue},DE`;
          if (cartData.GameTypeID === 2) {
            if (!cartData.apiUpdated) {
              let tteam = null;
              const clist = this.$store.state.Game.GameList;
              // eslint-disable-next-line no-labels
              search: for (let c = 0; c < clist.length; ++c) {
                const llist = clist[c].Items.List;
                for (let l = 0; l < llist.length; ++l) {
                  const tlist = llist[l].Team;
                  for (let t = 0; t < tlist.length; ++t) {
                    const team = tlist[t];
                    if (team.EvtID === cartData.EvtID) {
                      tteam = team;
                      // eslint-disable-next-line no-labels
                      break search;
                    }
                  }
                }
              }
              if (tteam) {
                cartData.HomeScore = tteam.HomeScore;
                cartData.AwayScore = tteam.AwayScore;
              }
            }
            WagerString += `,${cartData.HomeScore}:${cartData.AwayScore}`;
          }
          // 移除過期賽事提示
          if (cartData.Status !== 1) {
            errorMessage = this.$t('BetViewList.PlzRemoveOutOfTimeBet');
            return false;
          }
          // 一般投注
          if (betType === 1) {
            if (
              cartData.betAmount === null ||
              cartData.betAmount === '' ||
              cartData.betAmount === 0
            ) {
              errorMessage = this.$t('BetViewList.PlzInputBetAmount');
              return false;
            }

            const listItem = {
              CatId,
              WagerString,
              Amount: parseFloat(cartData.betAmount),
              AcceptBetter: this.$store.state.Setting.UserSetting.acceptBetter,
              BetType: 1,
            };
            list.push(listItem);
          } // 串關投注
          else if (betType === 99) {
            if (list.length === 0) {
              const listItem = {
                CatId,
                WagerString,
                Amount: parseFloat(strayBetAmount),
                AcceptBetter: this.$store.state.Setting.UserSetting.acceptBetter,
                BetType: 99,
              };
              list.push(listItem);
            } else {
              list[0].WagerString += '|' + WagerString;
            }
          } else {
            errorMessage = `betType ${betType} not define`;
            return false;
          }
          return true;
        });
        // have error
        if (errorMessage !== null) {
          this.$notify.error({
            message: errorMessage,
          });
          return null;
        } else {
          return list;
        }
      },
      submitHandler(isShowLoading = true) {
        this.$store.commit('BetCart/setShowTracing', false);
        const isTriggerLimit = this.reCalcBetChart(true);

        if (!this.isBalanceOK) {
          this.$store.commit('BetCart/setLastFirstBetDataState', 'init');
          return;
        }
        if (this.isOverMaxwin) {
          this.$message({
            type: 'warning',
            message: `波膽可贏金額大於最高可贏額 2,000,000`,
            duration: 5000,
          });
          return;
        }

        if (this.totalBetAmount > Number(this.userBalance)) {
          this.isBalanceOK = false;
          this.$message({
            type: 'warning',
            message: this.$t('Common.NoBalanceTip'),
            duration: 5000,
          });
          return;
        }

        if (isTriggerLimit) {
          this.$store.commit('BetCart/setLastFirstBetDataState', 'init');
          return;
        }

        const checkRes = this.checkBetPlayData(1, null);
        if (checkRes === null) {
          return;
        }

        this.clearAllMinMaxLimitState();
        this.clearAllMessageState();
        this.clearSuccessModeItem();

        if (
          this.panelMode === this.PanelModeEnum.lock ||
          this.settings.showBetConfirm === false ||
          this.isQuickBetEnable
        ) {
          if (this.$store.state.Setting.UserSetting.defaultAmount.type === 1) {
            // 多個投注時取最大的
            this.$store.state.Setting.UserSetting.defaultAmount.amount = Math.max(
              ...checkRes.map((checkRes) => checkRes.Amount)
            );
          }
          this.$store
            .dispatch('BetCart/submitBet', checkRes)
            .then((res) => {
              if (res?.data?.traceCodeKey) {
                this.$store.commit('BetCart/setPanelMode', this.PanelModeEnum.result);
                const has201 = res.data.ticket.filter((it) => it.code === 201).length > 0;
                if (has201) {
                  this.lastTraceCodeKey = res.data.traceCodeKey;
                  this.$store.commit('BetCart/setTracingCode', this.lastTraceCodeKey);
                  const item = {
                    quick: this.isQuickBetEnable,
                    type: 1,
                    index: 0,
                    list: this.$store.state.BetCart.betCartList,
                    ...res.data,
                  };
                  this.$store.commit('BetCart/backupTracing', item);
                  setTimeout(() => {
                    this.callPlayStateAPI2(res.data.traceCodeKey, false);
                  }, 600);
                  if (this.isQuickBetEnable) {
                    this.$store.commit('BetCart/setLastFirstBetDataState', 'wait');
                  } else {
                    this.$store.dispatch('BetCart/checkBetState2', {
                      data: res?.data?.ticket,
                      traceCodeKey: res?.data?.traceCodeKey,
                      isStray: false,
                    });
                  }
                } else {
                  this.$store.dispatch('BetCart/checkBetState', {
                    data: res?.data?.ticket,
                    traceCodeKey: res?.data?.traceCodeKey,
                    isStray: false,
                  });
                }
                this.$store.commit('setLoading', false);
              }
            })
            .catch((err) => {
              console.error(err);
              this.$store.commit('BetCart/setPanelMode', this.PanelModeEnum.normal);
              this.$store.commit('setLoading', false);
            });
          if (!isShowLoading) {
            this.$store.commit('setLoading', false);
          }
        } else {
          this.$store.commit('BetCart/setPanelMode', this.PanelModeEnum.lock);
        }
      },
      straySubmitHandler() {
        this.$store.commit('BetCart/setShowTracing', false);
        if (Number(this.strayBetAmount) > Number(this.userBalance)) {
          this.isBalanceOK = false;
          this.$message({
            type: 'warning',
            message: this.$t('Common.NoBalanceTip'),
            duration: 5000,
          });
          return;
        }
        const winAmount = this.$lib.truncFloor(this.strayBetAmount * this.strayOdd);
        const maxWin = this.maxWinEx;
        if (winAmount > maxWin) {
          this.$message({
            type: 'warning',
            message: `可贏金額大於最高可贏額 2,000,000`,
            duration: 5000,
          });
          return;
        }

        const checkRes = this.checkBetPlayData(99, this.strayBetAmount);
        if (checkRes === null) {
          return;
        }
        this.clearAllMinMaxLimitState();
        this.clearAllMessageState();
        if (this.panelMode === this.PanelModeEnum.lock || this.settings.showBetConfirm === false) {
          if (this.$store.state.Setting.UserSetting.defaultStrayAmount.type === 1) {
            this.$store.state.Setting.UserSetting.defaultStrayAmount.amount = this.strayBetAmount;
          }

          this.$store
            .dispatch('BetCart/submitBet', checkRes)
            .then((res) => {
              if (res?.data?.traceCodeKey) {
                this.$store.commit('BetCart/setPanelMode', this.PanelModeEnum.result);
                const has201 = res.data.ticket.filter((it) => it.code === 201).length > 0;
                if (has201) {
                  this.lastTraceCodeKey = res.data.traceCodeKey;
                  this.$store.commit('BetCart/setTracingCode', this.lastTraceCodeKey);
                  const item = {
                    type: 99,
                    index: 1,
                    list: this.$store.state.BetCart.betCartList,
                    ...res.data,
                  };
                  this.$store.commit('BetCart/backupTracing', item);
                  setTimeout(() => {
                    this.callPlayStateAPI2(res.data.traceCodeKey, true);
                  }, 600);
                  this.$store.dispatch('BetCart/checkBetState2', {
                    data: res?.data?.ticket,
                    traceCodeKey: res?.data?.traceCodeKey,
                    isStray: true,
                  });
                } else {
                  this.$store.dispatch('BetCart/checkBetState', {
                    data: res?.data?.ticket,
                    traceCodeKey: res?.data?.traceCodeKey,
                    isStray: true,
                  });
                }
                this.$store.commit('setLoading', false);
              }
            })
            .catch((err) => {
              console.error(err);
              this.$store.commit('BetCart/setPanelMode', this.PanelModeEnum.normal);
              this.$store.commit('setLoading', false);
            });
        } else {
          this.$store.commit('BetCart/setPanelMode', this.PanelModeEnum.lock);
        }
      },
      callPlayStateAPI(isStray = false) {
        this.$store
          .dispatch('BetCart/getPlayResultApi', {
            traceCodeKey: this.lastTraceCodeKey,
            isStray,
          })
          .then((res) => {})
          .finally(() => {
            this.$store.commit('setLoading', false);
          });
      },
      callPlayStateAPI2(code, isStray = false) {
        this.$store
          .dispatch('BetCart/getPlayResultApi', {
            traceCodeKey: code,
            isStray,
          })
          .then((res) => {})
          .finally(() => {
            this.$store.commit('setLoading', false);
          });
      },
      cartDataToDisplayData(cartData) {
        return this.$SportLib.cartDataToDisplayData(cartData);
      },
      isShowBlackMask(cart) {
        return cart.Status !== 1 || cart.EvtStatus !== 1;
      },
      onCartListItemKeyboardShow(index) {
        this.currShowKeyboardIndex = index;
      },
      fillEachBetAmountBlurHandler() {
        this.lastBlurInput = { name: 'fillEachBetAmount' };
        this.reCalcBetChart(true);
      },
      fillEachWinAmountBlurHandler() {
        this.lastBlurInput = { name: 'fillEachWinAmount' };
        this.reCalcBetChart(true);
      },
      strayBetBlurHandler() {
        this.lastBlurInput = { name: 'strayBetAmount' };
        this.clearMinMaxTextState();
        if (this.showBetCartList.length !== 0 && this.strayBetAmount !== null) {
          const BetMin = this.showBetCartList[0].BetMin;
          const judgeBetMax = this.$lib.BetMaxLimit(
            this.showBetCartList[0].BetMaxPL,
            this.showBetCartList[0].BetMaxEvt
          );

          if (this.strayBetAmount < BetMin && BetMin !== null) {
            this.strayBetAmount = BetMin;
            this.isShowMinText = true;
          }
          if (this.strayBetAmount > judgeBetMax && judgeBetMax !== null) {
            this.strayBetAmount = judgeBetMax;
            this.isShowMaxText = true;
          }

          this.checkStrayMaxLimit();
        }

        this.reCalcStrayBetChart();
      },
      checkStrayMaxLimit() {
        const winAmount = this.$lib.truncFloor(this.strayBetAmount * this.strayOdd);
        if (winAmount > this.userMaxWin) {
          this.strayBetAmount = this.$lib.truncFloor(
            this.userMaxWin / this.$lib.trunc(this.strayOdd)
          );
        }
      },
      inputFocusEvent({ from, BetMax }) {
        if (from === 'betAmount') {
          this.isShowMaxChip = true;
          this.theMaxChipValue = BetMax;
        } else {
          this.isShowMaxChip = false;
        }
      },
      listCardItemLastBlurInputEvent(lastBlurInputData) {
        if (this.lastBlurInput.from === lastBlurInputData.from) {
          this.isShowBetKB = !this.isShowBetKB;
        }
        this.lastBlurInput = lastBlurInputData;
        this.reCalcBetChart(true);
      },
      onChipClick(value) {
        this.processLastBlurInput(value);
        this.reCalcBetChart(true);
      },
      keyBoardAddEvent(addNum) {
        this.processLastBlurInput(addNum);
      },
      keyBoardAssignEvent(newNum) {
        this.processLastBlurInput(newNum, true);
      },
      processLastBlurInput(value, isAssignMode = false) {
        if (this.lastBlurInput.name === 'rowItem') {
          const CartListIndex = this.showBetCartList.findIndex(
            (it) => it.GameID === this.lastBlurInput.GameID
          );
          if (CartListIndex > -1) {
            let newNum = 0;
            if (this.lastBlurInput.from === 'betAmount') {
              if (this.showBetCartList[CartListIndex].betAmount === null) {
                newNum = value;
              } else {
                if (isAssignMode) {
                  if (value === -1) {
                    newNum = this.showBetCartList[CartListIndex].betAmount.toString().slice(0, -1);
                  } else {
                    newNum =
                      this.showBetCartList[CartListIndex].betAmount.toString() + value.toString();
                  }
                } else {
                  newNum = parseInt(this.showBetCartList[CartListIndex].betAmount) + value;
                }
              }
              this.showBetCartList[CartListIndex].betAmount = newNum.toString();
              this.reCalcBetChart();
            } else {
              if (this.showBetCartList[CartListIndex].winAmount === null) {
                newNum = value;
              } else {
                if (isAssignMode) {
                  if (value === -1) {
                    newNum = this.showBetCartList[CartListIndex].winAmount.toString().slice(0, -1);
                  } else {
                    newNum =
                      this.showBetCartList[CartListIndex].winAmount.toString() + value.toString();
                  }
                } else {
                  newNum = parseInt(this.showBetCartList[CartListIndex].winAmount) + value;
                }
              }
              this.showBetCartList[CartListIndex].winAmount = newNum.toString();
              const cartData = this.showBetCartList[CartListIndex];
              const displayData = this.cartDataToDisplayData(cartData);
              if (cartData.winAmount !== null) {
                this.showBetCartList[CartListIndex].betAmount = this.$lib.truncCeil(
                  cartData.winAmount / this.$lib.trunc(parseFloat(displayData.showOdd))
                );
              }
              this.reCalcBetChart();
            }
          } else {
            this.lastBlurInput.name = 'fillEachBetAmount';
          }
        }
        if (
          this.lastBlurInput.name === 'fillEachBetAmount' ||
          this.lastBlurInput.name === 'fillEachWinAmount'
        ) {
          let newNum = 0;
          if (this.lastBlurInput.name === 'fillEachBetAmount') {
            if (this.fillEachBetAmount === null) {
              newNum = value;
            } else {
              if (isAssignMode) {
                if (value === -1) {
                  newNum = this.fillEachBetAmount.toString().slice(0, -1);
                } else {
                  newNum = this.fillEachBetAmount.toString() + value.toString();
                }
              } else {
                newNum = parseInt(this.fillEachBetAmount) + value;
              }
            }
            this.fillEachBetAmount = newNum.toString();
            this.fillEachBetAmountHandler();
          } else {
            if (this.fillEachWinAmount === null) {
              newNum = value;
            } else {
              if (isAssignMode) {
                if (value === -1) {
                  newNum = this.fillEachWinAmount.toString().slice(0, -1);
                } else {
                  newNum = this.fillEachWinAmount.toString() + value.toString();
                }
              } else {
                newNum = parseInt(this.fillEachWinAmount) + value;
              }
            }
            this.fillEachWinAmount = newNum.toString();
            this.fillEachWinAmountHandler();
            this.reCalcBetChart();
          }
        } else if (this.lastBlurInput.name === 'strayBetAmount') {
          let newNum = 0;
          if (this.strayBetAmount === null || this.strayBetAmount === '') {
            if (value === -1) {
              newNum = 0;
            } else {
              newNum = value;
            }
          } else {
            if (isAssignMode) {
              if (value === -1) {
                newNum = this.strayBetAmount.toString().slice(0, -1);
              } else {
                newNum = this.strayBetAmount.toString() + value.toString();
              }
            } else {
              newNum = parseInt(this.strayBetAmount) + value;
            }
          }
          this.strayBetAmount = newNum.toString();
          this.reCalcStrayBetChart();
        }
      },
      onInputFocus() {
        if (this.isMobileMode) {
          // 強制不調用手機虛擬鍵盤
          document.activeElement.blur();
        }
        this.isShowMaxChip = false;
      },
      clearInput(type) {
        if (type === 'normal') {
          const list = this.$store.state.BetCart.betCartList;
          this.fillEachBetAmount = null;
          this.fillEachWinAmount = null;
          list.forEach((item) => {
            item.betAmount = null;
            item.winAmount = null;
          });
          this.reCalcBetChart();
        } else if (type === 'stray') {
          this.strayBetAmount = null;
          this.reCalcStrayBetChart();
        }
      },
      clearItemInputByIndex(index) {
        const list = this.$store.state.BetCart.betCartList;
        const item = list[index];
        if (item) {
          item.betAmount = null;
          item.winAmount = null;
          this.reCalcBetChart();
        }
      },
    },
    mounted() {
      this.intervalEvent = setInterval(() => {
        // result狀態也不更新注單
        if (
          this.groupIndex === 0 &&
          this.panelMode !== this.PanelModeEnum.result &&
          !this.isControlByBetSingle
        ) {
          this.$store.dispatch('BetCart/updateAllCartData');
        }
      }, 5000);

      if (this.isControlByBetSingle) {
        this.currShowKeyboardIndex = 0;
      }

      window.addEventListener('keydown', this.keyPress);
    },
    beforeDestroy() {
      clearInterval(this.intervalEvent);
      clearInterval(this.intervalEvent2);
      window.removeEventListener('keydown', this.keyPress);
    },
  };
</script>

<style lang="scss" scoped>
  @import './ListCardItem.scss';
  #app[data-theme^='light'] {
    #BetViewList {
      .cardOptionBlock,
      .noData {
        color: black;
      }
      .strayOdd {
        color: #f00;
      }
      .limitTip {
        color: red;
      }
      .strayTopRight {
        .num {
          color: #005aff;
        }
      }
    }
  }

  #app[data-theme^='dark'] {
    #BetViewList {
      .cardOptionBlock,
      .noData,
      .centerTipBlock {
        color: white;
      }
      .strayOdd {
        color: #ff8686;
      }
      .limitTip {
        color: #ff8686;
      }
      .strayTopRight {
        .num {
          color: #ffea01;
        }
        .x {
          color: #ddd;
        }
      }
    }
  }

  #BetViewList {
    position: relative;
    height: calc(100% - 35px);
    overflow: auto;
    &::-webkit-scrollbar {
      /*隱藏滾輪*/
      display: none;
    }
    .redErrorStyle {
      background-color: #deb7b7;
      .cardHeaderRow {
        background-color: #d18d8c;
        color: white;
        .playMethodName,
        .playMethodNameSupport,
        .playBetOdd {
          color: white;
        }
      }
    }

    .cardOptionBlock {
      .strayErrorMessage {
        text-align: left;
        // color: red;
        color: #ff6161;
      }
      .StrayTipBlock {
        background-color: #d66764;
        width: 100%;
        padding: 15px 0px;
        .topTextRow {
          color: white;
          text-align: center;
          margin-bottom: 12px;
        }
        .bottomTextRow {
          color: white;
          display: flex;
          justify-content: center;
          .goldTip {
            color: #ffea01;
            padding: 0 5px;
          }
        }
      }
      .buttonRow {
        display: flex;
        justify-content: space-around;
        .clearBtn,
        .submitBtn,
        .closeBtn,
        .acceptChange {
          width: 48%;
          padding: 10px;
          font-size: 14px;
          cursor: pointer;
          font-weight: bold;
          text-align: center;
        }
        .clearBtn {
          background-color: #838383;
          &:hover {
            background-color: #9a9a9af6;
          }
          color: white;
        }
        .submitBtn {
          background-color: #ffdf1b;
          color: black;
          &:hover {
            background-color: #ffeb68f9;
          }
        }
        .acceptChange {
          background-color: #3fa381;
          color: white;
          &:hover {
            background-color: #62b096;
          }
        }
        .closeBtn {
          background-color: #3fa381;
          color: white;
          &:hover {
            background-color: #62b096;
          }
        }
      }
      .betRows-wrap {
        display: table;
        table-layout: auto;
        width: 100%;
        border-collapse: separate;
        border-spacing: 0px 5px;
        flex-direction: column;
        .betInputRow {
          display: table-row;
          position: relative;

          li {
            display: table-cell;
            vertical-align: middle;
            position: relative;
          }

          .betInputTitle {
            width: 25%;
            // text-justify: distribute-all-lines; // 这行必加，兼容ie浏览器
            white-space: nowrap;
            text-align: left;
            padding: 0 8px 0 0;
            position: relative;
            &::after {
              content: ':';
              position: absolute;
              top: 50%;
              right: 5px;
              transform: translateY(-50%);
              font-weight: bold;
            }
          }
          input {
            width: 100%;
            height: 30px;
            border: 0px;
            padding: 0 5px;
            &.gray {
              background-color: #c0c0c0;
            }
          }
          .redErrorInput {
            border: 1px solid red !important;
          }
          .betReadInput {
            width: 75%;
            height: 30px;
            line-height: 30px;
            text-align: left;
            padding: 0 5px;
            background-color: #c0c0c0;
          }
          .strayBlock {
            display: flex;
            flex-wrap: wrap;
            padding: 0 5px;
            .strayBlockTop {
              width: 100%;
              display: flex;
              justify-content: space-between;
              .strayTopLeft {
              }
              .strayTopRight {
                display: flex;
                justify-content: center;
                .num {
                  padding-right: 3px;
                }
                .x {
                  color: #777;
                }
              }
            }
            .strayBlockBottom {
              width: 100%;
              display: flex;
              justify-content: flex-start;
              margin-top: 2px;
              .strayOdd {
                width: 100%;

                text-align: left;
                font-size: 12px;
              }
            }
          }
          .betInputRowAbsoluteBlock {
            position: absolute;
            width: 53px;
            height: 100%;
            border-left: 1px solid rgba(128, 128, 128, 0.653);
            top: 0;
            right: 0px;
            color: #004eff;
            line-height: 30px;
            text-align: right;
            padding-right: 10px;
          }
          .btnClearValue {
            position: absolute;
            top: 50%;
            right: 58px;
            transform: translateY(-50%);
            font-size: 16px;
            line-height: 1;
            cursor: pointer;
            color: #000;
            opacity: 0.3;
            &:hover {
              opacity: 0.6;
            }
            &.stray {
              right: 5px;
            }
          }
        }
      }

      .totalRow {
        padding: 12px 5px;
        display: flex;
        justify-content: space-around;
        .halfItem {
          width: 50%;
          text-align: left;
        }
        .halfItemChange {
          background-color: #01a2e8;
          color: black;
          padding: 2px 8px;
        }
      }
      .strayRow {
        border: 1px solid gray;
        text-align: left;
        padding: 5px;
        font-size: 14px;
        line-height: 14px;
        .strayRowTitle {
          margin-bottom: 5px;
        }
        .strayRowContent {
        }
      }
      .strayLimitTipBlock {
        margin: 7px 0;
      }
      .amountTipRow {
        background-color: #a72120;
        color: #fff;
        padding: 8px 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .noData {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      height: 100%;
      .noDataItem {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }

    .centerTipBlock {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .centerTip {
        .tipItem {
          margin-bottom: 10px;
          font-size: 16px;
        }
      }
    }
  }
</style>
