<template>
  <div class="mGameTable">
    <div
      v-if="GameList.length && gameData.Items.List.length"
      class="playTypeBar"
      :style="headerColor"
      @click="onToggleAllCollapseClick"
    >
      <div
        class="playTypeBtn"
        :class="{ hasMoreWagerTypes: hasMoreWagerTypes }"
        @click="!isFavoriteMode && openWagerTypePopup()"
      >
        <img v-if="gameData.CatID" class="icon" :src="getMenuIconByCatID(gameData.CatID)" />
        <template v-if="isFavoriteMode">
          {{ gameData.CatName }}
        </template>
        <template v-else>
          {{ gameNameWithWagerType }}
        </template>
        <span v-if="hasMoreWagerTypes" style="padding-left: 12px">▼</span>
      </div>
      <div v-if="isShowMenu" class="playTypeBtn sub" @click="openWagerTypePopup(true)">
        <span>
          {{ boldFilterType ? $t('Common.FullGame') : $t('common.halfGame') }}
        </span>
      </div>
      <img
        class="arrow"
        :class="activeCollapse.length > 0 ? 'active' : ''"
        src="@/assets/img/mobile/btn_arrow_w.svg"
      />
    </div>
    <template v-if="isShowTemplate">
      <mBoldTable
        v-for="source in leaguesData"
        :key="leagueDataToUniqueKey(source.LeagueID, source.Nu)"
        :source="source"
        :bestHead="gameData.Items.BestHead"
        :isExpanded="isExpanded(leagueDataToUniqueKey(source.LeagueID, source.Nu), source)"
        @toggleCollapse="toggleCollapse(leagueDataToUniqueKey(source.LeagueID, source.Nu))"
      />
    </template>
    <template v-else>
      <div style="display: flex">
        <div class="left-area">
          <mGameInfo
            v-for="source in leaguesData"
            :key="leagueDataToUniqueKey(source.LeagueID, source.Nu)"
            :source="source"
            :isExpanded="isExpanded(leagueDataToUniqueKey(source.LeagueID, source.Nu), source)"
            :hasMoreGame="hasMoreGame"
            :dotStatus="dotStatusHandlerAll()"
            :BestHead="gameData.Items.BestHead"
            @toggleCollapse="toggleCollapse(leagueDataToUniqueKey(source.LeagueID, source.Nu))"
          />
        </div>
        <div class="right-area" ref="scrollEl" @scroll="scrollEvent">
          <mGameBetting
            v-for="(source, index) in leaguesData"
            :key="leagueDataToUniqueKey(source.LeagueID, source.Nu)"
            :source="source"
            :bestHead="gameData.Items.BestHead"
            :isExpanded="isExpanded(leagueDataToUniqueKey(source.LeagueID, source.Nu), source)"
            :hasMoreGame="hasMoreGame"
            @toggleCollapse="toggleCollapse(leagueDataToUniqueKey(source.LeagueID, source.Nu))"
            @dotStatusChanged="(status) => dotStatusHandler(index, status)"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import mGameInfo from './mGameInfo.vue';
  import mGameBetting from './mGameBetting.vue';
  import mBoldTable from './mBoldTable.vue';
  import { favoriteCatID, hotCatID } from '@/config/index.js';

  export default {
    name: 'mGameTable',
    props: {
      gameData: {
        type: Object,
        default() {
          return {};
        },
      },
      hasMoreGame: {
        type: Boolean,
        default() {
          return false;
        },
      },
    },
    data() {
      return {
        activeCollapse: [],
        dotStatusArr: [],
        dotStatus: {
          visible: false,
          isScrollToTheEnd: false,
        },
      };
    },
    components: {
      mGameInfo,
      mGameBetting,
      mBoldTable,
    },
    mounted() {
      if (this.tableSort === 1) this.expandAllCollapse();
    },
    computed: {
      gameStore() {
        return this.$store.state.Game;
      },
      selectCatID() {
        return this.gameStore.selectCatID;
      },
      selectWagerTypeKey() {
        return this.gameStore.selectWagerTypeKey;
      },
      leaguesData() {
        return this.gameData.Items.List.map((league) => {
          if (this.isCornerGame) {
            const newData = league.Team.map((teamData) => {
              const newTeamData = JSON.parse(JSON.stringify(teamData));
              if (this.boldFilterType) {
                newTeamData.Wager = teamData.Wager;
              } else {
                newTeamData.Wager = teamData.Wager2;
              }
              return newTeamData;
            });
            return { ...league, Team: newData };
          } else {
            return league;
          }
        }).filter((league) => league.Team.length);
      },
      // 賽事排序方式 0: 熱門 1: 時間
      tableSort() {
        return this.$store.state.Setting.UserSetting.tableSort;
      },
      GameList() {
        return this.$store.getters['Game/GameListFilterBySelectLeague'];
      },
      CatMapData() {
        return this.$store.state.Game.CatMapData;
      },
      currentCatData() {
        const { selectCatID } = this.gameStore;
        const currentCatData = this.$store.getters['Game/selectMenuCatList'].find(
          (it) => it.catid === selectCatID
        );
        return currentCatData;
      },
      currentWagerType() {
        if (this.currentCatData) {
          const { selectWagerTypeKey } = this.gameStore;
          const currentWagerType = this.currentCatData.Items.find(
            (it) => it.WagerTypeKey === selectWagerTypeKey
          );
          return currentWagerType;
        }
        return null;
      },
      isFavoriteMode() {
        const { selectCatID, selectWagerTypeKey } = this;
        return (
          selectCatID === favoriteCatID ||
          (selectCatID === hotCatID && selectWagerTypeKey === hotCatID)
        );
      },
      gameNameWithWagerType() {
        const gameName = this.gameData.CatName;
        const wagerTypeName = this.currentWagerType
          ? ' - ' + this.currentWagerType.WagerTypeName
          : '';
        return gameName + wagerTypeName;
      },
      headerColor() {
        const color = this.CatMapData[this.gameData.Items.List[0].CatID]?.color || '#7d9364';
        return {
          'background-color': color,
        };
      },
      boldFilterType() {
        return this.$store.state.Game.boldFilterType;
      },
      hasMoreWagerTypes() {
        return this.currentCatData?.Items.length > 1;
      },
      isCornerGame() {
        switch (this.selectCatID) {
          case 1:
          case 31:
            switch (this.selectWagerTypeKey) {
              case 2:
                return true;
              default:
                return false;
            }
          default:
            return false;
        }
      },
      isBoldGame() {
        switch (this.selectCatID) {
          case 1:
          case 31:
            switch (this.selectWagerTypeKey) {
              case 3:
                return true;
              default:
                return false;
            }
          default:
            return false;
        }
      },
      isShowMenu() {
        switch (this.selectCatID) {
          case 1:
          case 31:
            switch (this.selectWagerTypeKey) {
              case 2:
              case 4:
                return true;
              default:
                return false;
            }
          case 102:
            switch (this.selectWagerTypeKey) {
              case 6:
                return true;
              default:
                return false;
            }
          default:
            return false;
        }
      },
      isShowTemplate() {
        switch (this.selectCatID) {
          case 1:
          case 31:
            switch (this.selectWagerTypeKey) {
              case 3:
              case 4:
              case 5:
                return true;
              default:
                return false;
            }
          default:
            return false;
        }
      },
    },
    methods: {
      onToggleAllCollapseClick(e) {
        if (e.target !== e.currentTarget) return;
        this.toggleAllCollapse();
      },
      isExpanded(uKey, source) {
        if (source && source.CatID === 31) {
          return true;
        }
        return this.activeCollapse.includes(uKey);
      },
      toggleCollapse(uKey) {
        if (this.activeCollapse.includes(uKey)) {
          this.activeCollapse = this.activeCollapse.filter((it) => it !== uKey);
        } else {
          this.activeCollapse.push(uKey);
        }
        this.updateDotVisible();
      },
      toggleAllCollapse() {
        this.activeCollapse = this.activeCollapse.length > 0 ? [] : this.expandAllCollapse();
      },
      expandAllCollapse() {
        // 展開全部
        return (this.activeCollapse = this.gameData.Items.List.map((it) =>
          this.leagueDataToUniqueKey(it.LeagueID, it.Nu)
        ));
      },
      openWagerTypePopup(isSub = false) {
        if (this.currentCatData.Items.length > 1 && !this.isFavoriteMode) {
          this.$emit('openWagerTypePopup', isSub);
        }
      },
      getMenuIconByCatID(catId) {
        const icon = this.CatMapData[catId].icon;
        return require('@/assets/img/common/menuIcon/' + icon);
      },
      dotStatusHandler(index, status) {
        this.dotStatusArr[index] = status;
      },
      dotStatusHandlerAll() {
        return this.dotStatus;
      },
      scrollEvent(event) {
        const element = event.target;
        if (Math.floor(element.scrollWidth - element.scrollLeft) <= element.clientWidth) {
          // 滑到最右邊
          this.dotStatus.isScrollToTheEnd = true;
        } else if (element.scrollLeft === 0) {
          // 滑到最左邊
          this.dotStatus.isScrollToTheEnd = false;
        }
      },
      updateDotVisible() {
        this.$nextTick(() => {
          // 如果沒有卷軸,則不顯示小球
          const element = this.$refs.scrollEl;
          if (element) {
            if (element.scrollWidth <= element.clientWidth) {
              this.dotStatus.visible = false;
            } else {
              this.dotStatus.visible = true;
            }
          }
        });
      },
      leagueDataToUniqueKey(LeagueID, Nu) {
        return this.$lib.leagueDataToUniqueKey(LeagueID, Nu);
      },
    },
    watch: {
      activeCollapse() {
        this.updateDotVisible();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .mGameTable {
    overflow-x: hidden;
    overflow-y: auto;
    .left-area {
      position: relative;
      z-index: 2;
      width: 40%;
      transition: width 500ms ease;
      @media screen and (max-width: 480px) {
        width: calc(160px);
      }
    }
    .right-area {
      z-index: 1;
      position: relative;
      flex: 1;
      overflow-x: auto;
      overflow-y: hidden;
      // box-shadow: inset 0px 0px 15px rgba(0, 0, 0, 0.1);
    }
    .playTypeBar {
      display: flex;
      align-items: center;
      font-size: 0.8em;
      line-height: 1em;
      min-height: 30px;
      color: #fff;
      padding: 3px 6px;
      background-color: #7d9364;
      position: sticky;
      width: 100%;
      z-index: 10;
      top: 0;
      left: 0;

      .playTypeBtn {
        display: flex;
        align-items: center;
        position: relative;
        align-self: stretch;

        padding: 3px 10px 3px 30px;
        margin-right: 6px;
        min-height: 22px;
        line-height: 1;
        font-size: 1.2rem;

        &.hasMoreWagerTypes {
          border: 1px solid #fff;
          border-radius: 45px;
          background-color: rgba(255, 255, 255, 0.15);
          &:active {
            background-color: rgba(0, 0, 0, 0.15);
          }
        }

        img.icon {
          position: absolute;
          left: 5px;
          top: 50%;
          height: 18px;
          width: 18px;
          transform: translateY(-50%);
          filter: grayscale(1) brightness(3);
        }

        &.sub {
          padding: 3px 10px 3px 10px;
          min-width: 50px;
          border: 1px solid #fff;
          border-radius: 45px;
          background-color: rgba(255, 255, 255, 0.15);
        }
      }

      img.arrow {
        width: 1.1rem;
        margin-left: auto;
        margin-right: 6px;
        transition: 200ms ease;
        &.active {
          transform: rotate(-90deg);
        }
      }
    }
  }
</style>
