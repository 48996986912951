// 外接
// - DG 真人: { GVID: 'DG', GTID: 'Live' }

import request from './config/request';

// 取得外接餘額
export function getThirdBalanceApi({ GVID, GTID }) {
  return request({
    url: '/ThirdPartyGame/Balance',
    method: 'post',
    auth: true,
    data: { GVID, GTID },
  });
}

// 取得外接登入連結
export function getThirdLoginApi({ GVID, GTID }) {
  return request({
    url: '/ThirdPartyGame/Login',
    method: 'post',
    auth: true,
    data: { GVID, GTID },
  });
}

// DG Live
export function getDGLiveLoginApi() {
  return getThirdLoginApi({ GVID: 'DG', GTID: 'Live' });
}
