<template>
  <div id="mGamesBetInfoSingle" :class="isShowMoreGame ? 'no-footer' : ''">
    <div class="popup">
      <div class="header">
        <ul class="tab-list">
          <li class="tab-item" :class="tabIndex === 0 ? 'active' : ''" @click="tabIndex = 0"
            >{{ $t('Common.SingleItem') }}<span class="number">{{ betCartList.length }}</span></li
          >
          <!-- <li class="tab-item" :class="tabIndex === 1 ? 'active' : ''" @click="tabIndex = 1"
            >過關<span class="number">{{ betCartList.length }}</span></li
          > -->
        </ul>

        <div class="userCredit">${{ userBalance }}</div>

        <img
          src="@/assets/img/mobile/btn_arrow_w.svg"
          class="btn-close"
          @click.stop="onHideClick"
        />
      </div>
      <div class="body">
        <BetViewList :isControlByBetSingle="true" :groupIndex="0"></BetViewList>
      </div>
    </div>
    <div class="bg" />
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import BetViewList from '@/components/BetViewList';
  export default {
    name: 'mGamesBetInfoSingle',
    components: {
      BetViewList,
    },
    props: {
      isShowMoreGame: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        selectGroupIndex: 0,
        selectChildIndex: 0,
        tabIndex: 0, // 0: 單項投注 1: 過關投注
      };
    },
    computed: {
      ...mapState(['userBalance']),
      betCartList() {
        return this.$store.state.BetCart.betCartList;
      },
    },
    methods: {
      onHideClick(e) {
        if (e.target !== e.currentTarget) return;
        this.$emit('onHide');
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/sass/theme/mixin.scss';

  #mGamesBetInfoSingle {
    position: fixed;
    top: 135px !important;
    bottom: 4.8rem;
    width: 100%;
    height: auto !important;
    padding: 5px;
    background: #eaeaea;
    box-shadow: rgb(0 0 0 / 25%) 0px -3px 8px;
    transition: height 2s;
    z-index: 18;

    .bg {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.2);
      z-index: 19;
    }

    &.no-footer {
      bottom: 0;
    }

    .popup {
      position: fixed;
      width: calc(100% - 10px);
      z-index: 20;

      .header {
        display: flex;
        @include base-background();
        // background-color: #3fa381;
        color: #fff;
        border-radius: 4px 4px 0 0;
        font-size: 0.95em;
        min-height: 30px;

        ul.tab-list {
          display: flex;
          max-width: 60%;
          overflow: auto;
          li.tab-item {
            min-height: 26px;
            padding: 0 8px;
            min-width: 60px;
            text-align: center;
            line-height: 26px;
            border-radius: 3px;
            margin: 7px 5px;
            cursor: pointer;
            background-color: transparent;
            font-size: 1.25rem;

            &.active,
            &:active {
              background-color: #fff;
              color: #6da9e5;
            }

            span.number {
              margin-left: 5px;
              color: #ffbc00;
            }
          }
        }

        .userCredit {
          font-size: 1rem;
          font-weight: bold;
          margin-left: auto;
          align-self: center;
          margin-right: 15px;
        }

        .btn-close {
          opacity: 0.7;
          width: 16px;
          margin-right: 15px;
        }
      }
      .body {
        max-height: 70%;
        background-color: #d4d4d4;
        padding: 5px;
        padding-bottom: 10px;
        border-radius: 0 0 4px 4px;
        overflow: auto;
      }
    }
  }
</style>
