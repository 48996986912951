<template>
  <div v-if="isShow" id="promotion">
    <div class="content">
      <img class="image" :src="image" />
      <div class="close" @click="close" />
    </div>
  </div>
</template>

<script>
  import { PROMOTION } from '@/config/storage';

  export default {
    data() {
      return {
        isClose: true,
      };
    },
    computed: {
      isShow() {
        if (this.isClose) return false;
        const date = localStorage.getItem(PROMOTION);
        if (date && new Date(date).toDateString() === new Date().toDateString()) return false;
        return true;
      },
      image() {
        const index = Math.floor(Math.random() * 2) + 1;
        return `/promotion/lottery${index}.jpg`;
      },
    },
    methods: {
      close() {
        localStorage.setItem(PROMOTION, new Date().toDateString());
        this.isClose = true;
      },
    },
  };
</script>

<style lang="scss" scoped>
  #promotion {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
  }

  .content {
    position: relative;
    width: 80%;
    max-width: 320px;
  }

  .image {
    width: 100%;
    height: auto;
  }

  .close {
    position: absolute;
    width: 32px;
    height: 32px;
    right: 8px;
    top: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 2px;
    border-radius: 16px;
    background-color: #ccc;
    color: #eee;
    font-size: 32px;
    cursor: pointer;

    &:after {
      content: 'x';
    }

    &:hover {
      background-color: #bbb;
      color: #fff;
    }
  }
</style>
