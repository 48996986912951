<template>
  <!-- 遊戲類型 -->
  <ul class="gameCatNav">
    <li
      v-if="hasFavorite"
      class="item fav"
      :class="gameStore.selectCatID == favoriteCatID ? 'active' : ''"
      @click="goFav"
    >
      <img :src="getMenuIconByCatID(favoriteCatID)" class="menu-icon" @click="goFav()" />
      {{ $t('Common.Collect') }}
    </li>
    <li
      v-for="(catData, index) in dynamicMenuList"
      :key="index"
      class="item"
      :class="gameStore.selectCatID == catData.catid ? 'active' : ''"
      @click.stop="$emit('onCatTypeClick', catData, null, index)"
    >
      <img :src="getMenuIconByCatID(catData.catid)" class="menu-icon" />
      {{ catData.catName }}
    </li>
  </ul>
</template>

<script>
  import { getMenuIconByCatID } from '@/utils/SportLib';

  export default {
    name: 'mGameCatNav',
    data() {
      return {
        cloneNum: 3,
        isOverflow: false,
        isPauseScrollEvent: false,
      };
    },
    computed: {
      favoriteCatID() {
        return this.$conf.favoriteCatID;
      },
      gameStore() {
        return this.$store.state.Game;
      },
      selectGameType() {
        return this.gameStore.selectGameType;
      },
      selectCatID() {
        return this.gameStore.selectCatID;
      },
      selectWagerTypeKey() {
        return this.gameStore.selectWagerTypeKey;
      },
      CatMapData() {
        return this.$store.state.Game.CatMapData;
      },
      hasFavorite() {
        return this.$store.state.Setting.UserSetting.favorites.length > 0;
      },
      MenuList() {
        return this.$store.getters['Game/selectMenuCatList'];
      },
      dynamicMenuList() {
        let list = this.MenuList;
        if (this.isOverflow) {
          const clone = [...list];
          for (let i = 0; i < this.cloneNum; i++) {
            list = [...list, ...clone];
          }
        }
        return list;
      },
      orgChildrenWidth() {
        const children = Array.from(this.$el.children);
        children.length = this.MenuList.length;
        return children.reduce((acc, it) => (acc += it.clientWidth), 0);
      },
    },
    methods: {
      getMenuIconByCatID(catId) {
        const icon = getMenuIconByCatID(catId);
        if (icon) {
          return require('@/assets/img/common/menuIcon/' + icon);
        } else {
          return '';
        }
      },
      goFav() {
        const { selectGameType } = this;

        this.$store.commit('Game/setCatIDAndGameTypeAndWagerType', {
          selectGameType: selectGameType,
          selectCatID: this.favoriteCatID,
          selectWagerTypeKey: null,
        });
        this.$store.commit('MoreGame/closeMoreGameList');
        window.OddData.clear();
        this.updateGameDetail({ loading: true, clear: true });
      },
      updateGameDetail({ loading = false, clear = false }) {
        if (loading) {
          this.$store.commit('setLoading', true);
        }
        return this.$store.dispatch('Game/updateGameDetail', { clear }).finally(() => {
          if (loading) {
            this.$store.commit('setLoading', false);
          }
        });
      },
      detectIsOverflow() {
        this.isOverflow = this.orgChildrenWidth > this.$el.clientWidth;
      },
      onScroll() {
        // 觸發溢出偵測＆目前位置，並計算捲動位置
        this.detectIsOverflow();
        if (this.isOverflow && !this.isPauseScrollEvent) {
          const { clientWidth, scrollWidth, scrollLeft } = this.$el;
          if (scrollLeft <= 0) {
            this.$el.scrollLeft = this.orgChildrenWidth * 2;
          } else if (scrollLeft >= scrollWidth - clientWidth) {
            this.$el.scrollLeft = this.orgChildrenWidth * 2 - clientWidth;
          }
        }
      },
      setPositionToCurrentCat() {
        // 定位到當前選擇中的球種
        const target = this.$el.getElementsByClassName('active')[0];
        if (target) {
          this.isPauseScrollEvent = true;
          this.$el.scrollLeft = target.offsetLeft;
          this.isPauseScrollEvent = false;
        }
      },
    },
    mounted() {
      this.$el.addEventListener('scroll', this.onScroll);
      window.addEventListener('resize', this.detectIsOverflow);
    },
    beforeDestroy() {
      this.$el.removeEventListener('scroll', this.onScroll);
      window.removeEventListener('resize', this.detectIsOverflow);
    },
    watch: {
      'MenuList.length': {
        // 監聽當球種數量發生變化時, 偵測容器(this.$el)是否溢出
        handler() {
          // 更新 isOverflow 狀態 並更新視圖
          this.$nextTick(() => {
            this.onScroll();
            this.setPositionToCurrentCat(); // 定位修正
          });
        },
        immediate: false,
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/sass/theme/mixin.scss';
  ul.gameCatNav {
    @include base-background();
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    height: 55px;
    padding: 0;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;

    &::-webkit-scrollbar {
      display: none; // Safari and Chrome
    }

    li.item {
      display: flex;
      flex: none;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 6px;
      padding: 0.4rem 0;
      min-width: 5.35rem;
      text-align: center;
      font-size: 1.2rem;
      white-space: nowrap;
      color: rgba(255, 255, 255, 0.6);

      &.active {
        color: #fff;
        img.menu-icon {
          filter: brightness(1.1);
        }
      }

      img.menu-icon {
        max-width: 20px;
        height: auto;
        padding-top: 4px;
        filter: grayscale(1) brightness(3);
        opacity: 0.7;
      }

      &.fav {
        position: sticky;
        left: 0;
        z-index: 1;
        background-color: #6da9e5;
        box-shadow: 1px 0px 6px rgb(0 0 0 / 10%);
      }
    }
  }
</style>
