<template>
  <div id="mobileFooter">
    <ul class="footer-container">
      <li class="footer-item" @click="openLive()">
        <img class="icon" src="@/assets/img/mobile/icon_footer_palysvg.svg" alt="" />
        <span>{{ $t('Common.Live') }}</span>
      </li>

      <li class="footer-item" @click="openLeaguesPanel()">
        <img
          class="icon league"
          :class="{ active: test }"
          :src="require(`@/assets/img/mobile/icon_footer_league${test ? '_on' : ''}.svg`)"
          alt=""
        />
        <span>{{ $t('Common.OtherLeague') }}</span>
      </li>
      <li class="footer-item" @click="onBetViewClick" v-show="page !== PageEnum.gameResult">
        <div class="icon circle-bets" :class="hasBetItem ? 'hasBetItem' : ''">
          <span class="num">{{ betCartLength }}</span>
        </div>
        <span :style="hasStrayStyle"> {{ $lib.trunc(strayOdd, 2) || $t('Common.ClickBet') }}</span>
      </li>
      <li class="footer-item" @click="onBetRecordViewClick">
        <img class="icon" src="@/assets/img/mobile/icon_footer_record.svg" alt="" />
        <span>{{ $t('HistoryRecord.BetHistoryRecord') }}</span>
        <span v-if="unsettledBetCount" class="unsettledBetCount">{{ unsettledBetCount }}</span>
      </li>
      <li class="footer-item" @click="goNewsPage()">
        <img class="icon" src="@/assets/img/mobile/icon_news.svg" alt="" />
        <span> {{ $t('GamesHeader.news') }}</span>
      </li>
      <!-- <li class="footer-item" @click="openMenuPanel()">
        <img class="icon" src="@/assets/img/mobile/icon_footer_more.svg" alt="" />
        <span>{{ $t('Common.MoreSpace') }}</span>
      </li> -->
    </ul>
  </div>
</template>

<script>
  import { PageEnum } from '@/config/mobile/enum';
  import { mapState, mapGetters } from 'vuex';

  export default {
    name: 'mobileFooter',
    props: {
      hasLeagueFiltered: {
        type: Boolean,
        default: false,
      },
      page: {
        type: Number,
        default: PageEnum.game,
      },
    },
    data() {
      return {
        PageEnum,
      };
    },
    computed: {
      ...mapGetters(['themeInfo']),
      betCartStore() {
        return this.$store.state.BetCart;
      },
      betCartLength() {
        return this.betCartStore.betCartList.length;
      },
      hasBetItem() {
        return this.betCartLength > 0;
      },
      strayOdd() {
        return this.betCartStore.strayOdd;
      },
      hasStrayStyle() {
        const onColor = this.themeInfo === 'light' ? '#ff9600' : '#ffde00';
        const offColor = this.themeInfo === 'light' ? '#000000' : '#bbbbbb';
        return {
          color: this.strayOdd ? onColor : offColor,
        };
      },
      unsettledBetCount() {
        return this.$store.state.BetCart.unsettledBetCount;
      },
      test() {
        return this.$store.state.Game.selectLeagueIDs2.length > 0;
      },
    },
    methods: {
      onBetViewClick() {
        this.hasBetItem && this.$emit('openBetInfoPopup');
      },
      onBetRecordViewClick() {
        this.$emit('openBetRecordView');
      },
      openMenuPanel() {
        this.$emit('openMenuPanel');
      },
      openLive() {
        const safariWindow = window.open();
        safariWindow.opener = null;
        this.$store.commit('setLoading', true);
        this.$store
          .dispatch('Game/GetLiveURL')
          .then((res) => {
            safariWindow.location.href = res.data;
          })
          .finally(() => {
            this.$store.commit('setLoading', false);
          });
      },
      openLeaguesPanel() {
        this.$emit('openLeaguesPanel');
      },
      goNewsPage() {
        // this.$emit('goPage', PageEnum.news);
        this.$router.push('/News');
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/sass/theme/mixin.scss';
  #mobileFooter {
    ul.footer-container {
      width: 100%;
      height: 4.8rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background-color: #eee;
      border-top: 1px solid #ccc;
      box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.1);

      li.footer-item {
        position: relative;
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: flex-end;
        align-items: center;
        height: 100%;
        font-size: 0.923rem;
        padding: 3px;
        color: #5f5f5f;

        &:active {
          background-color: #ddd;
        }
        .icon {
          margin-bottom: 6px;
          width: 1.825rem;
          height: auto;
        }
        .circle-bets {
          width: 2.2rem;
          height: 2.2rem;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 2px solid #5f5f5f;
          border-radius: 50%;
          text-align: center;
          span.num {
            color: #5f5f5f;
            font-size: 1.45rem;
            font-weight: bold;
            line-height: normal;
          }
          &.hasBetItem {
            border: 2px solid #ff9600;
            span.num {
              color: #ff9600;
            }
          }
        }

        .unsettledBetCount {
          position: absolute;
          top: 5px;
          left: 50%;
          transform: translateX(calc(-50% + 20px));
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 100em;
          padding: 2px 3px;
          min-width: 20px;
          min-height: 20px;
          color: #ffffff;
          font-size: 0.923rem;
          letter-spacing: 1px;
          box-shadow: 1px 1px 6px rgb(0 0 0 / 30%);
          background-color: rgba(255, 53, 53, 0.9);
        }
      }
    }
  }

  #app[data-theme^='dark'] {
    #mobileFooter {
      ul.footer-container {
        background-color: #474747;
        border-color: #585858;
        li.footer-item {
          color: #bbb;

          .icon {
            filter: grayscale(1) brightness(3);
            &.circle-bets {
              &.hasBetItem {
                border: 2px solid #ffde00;
                span.num {
                  color: #ffde00;
                }
                filter: unset !important;
              }
            }
            &.league.active {
              filter: unset !important;
            }
          }
          &:active {
            background-color: #333;
          }
        }
      }
    }
  }
</style>
