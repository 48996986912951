<template>
  <div class="listCardItem">
    <!-- 一般 -->
    <div
      v-if="historyItem.BetType === 1"
      :set="(item = historyItem.dataBet[0])"
      :class="isDelete ? 'DeleteBG' : ''"
      style="cursor: pointer"
      @click="normalHeaderClick"
    >
      <div class="cardHeaderRow">
        <div
          class="ScoreColor"
          v-if="
            item.GameType === 2 && [1, 31].includes(item.CatID) && [101].includes(item.WagerTypeID)
          "
        >
          {{ `(${item.CurHomeScore}:${item.CurAwayScore})` }}
        </div>
        <div class="playMethodName"> {{ item.WagerPosName }} </div>
        <div v-if="!championWagerIDs.includes(item.WagerTypeID)" class="playMethodNameSupport">
          {{ item.CutLine }}
        </div>
        <div class="at"> @ </div>
        <div class="playBetOdd">
          {{ showOddValue(item.PayoutOddsStr) }}
        </div>
        <div class="rightArrowBlock">
          <i :class="arrowIconJudge(historyItem.isCollapse)" />
        </div>
      </div>
      <div class="cardContentBlock" :class="isDelete ? 'fatherTextDecoration' : ''">
        <div class="cardContentBlockRow">
          {{ item.CatName }}
          <template v-if="item.GameType === 2">
            {{ item.GameTypeName }}
          </template>
          <template v-if="item.ItemName"> - [{{ item.ItemName }}] </template>
          <template v-else> - [{{ item.LeagueName }}]</template>
        </div>
        <!-- 賽馬賽狗 -->
        <div v-if="[72].includes(item.CatID)" class="cardContentBlockRow">
          <div class="cardContentBlockRowText">
            <span v-if="isSettlement" class="ScoreColor"> [{{ item.HomeScore }}] </span>
            {{ item.HomeTeam }}
          </div>
        </div>
        <!-- 彩球 -->
        <div v-else-if="[83].includes(item.CatID)" class="cardContentBlockRow">
          <div class="cardContentBlockRowText">
            <template v-if="item.HomeTeam">
              <span v-if="isSettlement" class="ScoreColor"> [{{ item.HomeScore }}] </span>
              {{ item.HomeTeam }}
            </template>
            <template v-else-if="item.AwayTeam">
              <span v-if="isSettlement" class="ScoreColor"> [{{ item.HomeScore }}] </span>
              {{ item.AwayTeam }}
            </template>
          </div>
        </div>
        <!-- 指數 -->
        <div v-else-if="[84].includes(item.CatID)" class="cardContentBlockRow">
          <div class="cardContentBlockRowText">
            <span v-if="isSettlement" class="ScoreColor"> [{{ item.HomeScore }}] </span>
            {{ item.AwayTeam }}
          </div>
        </div>
        <!-- 冠軍 -->
        <div v-else-if="championWagerIDs.includes(item.WagerTypeID)" class="cardContentBlockRow">
          <div class="cardContentBlockRowText">
            {{ item.HomeTeam }}
          </div>
        </div>
        <!-- 單隊總分-->
        <template v-else-if="[131, 132].includes(item.WagerTypeID)">
          <div v-if="item.HomeTeam" class="cardContentBlockRow">
            <div v-if="isSettlement" class="ScoreColor"> [{{ item.HomeScore }}] </div>
            <div class="cardContentBlockRowText">
              {{ item.HomeTeam }}
            </div>
            <div class="cardContentBlockRowText HomeTeamSign"> ({{ $t('Common.Home') }}) </div>
          </div>
          <div v-else-if="item.AwayTeam" class="cardContentBlockRow">
            <div v-if="isSettlement" class="ScoreColor"> [{{ item.AwayScore }}] </div>
            <div class="cardContentBlockRowText">
              {{ item.AwayTeam }}
            </div>
          </div>
        </template>
        <div v-else class="cardContentBlockRow">
          <div v-if="isSettlement" class="ScoreColor"> [{{ item.HomeScore }}] </div>
          <div class="cardContentBlockRowText">
            {{ item.HomeTeam }}
          </div>
          <div class="cardContentBlockRowText HomeTeamSign"> ({{ $t('Common.Home') }}) </div>
          <div class="vs"> vs </div>
          <div v-if="isSettlement" class="ScoreColor"> [{{ item.AwayScore }}] </div>
          <div class="cardContentBlockRowText">
            {{ item.AwayTeam }}
          </div>
        </div>
        <template v-if="historyItem.isCollapse">
          <div class="cardContentBlockRow">
            {{ $t('HistoryRecord.League') }} : {{ item.LeagueName }}
          </div>
          <div class="cardContentBlockRow">
            {{ $t('HistoryRecord.Bet') }} : {{ historyItem.BetTimeStr }}
          </div>
          <div class="cardContentBlockRow">
            {{ $t('Common.Game') }} : {{ item.ScheduleTimeStr }}
          </div>
          <div class="cardContentBlockRow">
            {{ $t('Common.BetID') }} : {{ historyItem.TicketID }}
          </div>
          <div class="cardContentBlockRow">
            {{ $t('Common.Odd') }} : {{ $t('GamesSetup.NotIncludePrincipal') }}
          </div>
        </template>
        <div v-if="item.ItemStatusContent" class="cardContentBlockRow">
          <span style="color: red"> ({{ item.ItemStatusContent }}) </span>
        </div>
        <div class="cardContentBlockRow">
          <div class="cardContentBlockWithHalfRow">
            {{ $t('Common.Bet') }} : {{ toCurrency(historyItem.Amount) }}
          </div>
          <div class="cardContentBlockWithHalfRow" v-if="isSettlement">
            {{ $t('Common.Result') }} :
            <span class="resultText" :style="[resultTextColorJudge(historyItem.ResultAmount)]">
              {{ toCurrency(historyItem.ResultAmount) }}
            </span>
          </div>
          <div class="cardContentBlockWithHalfRow" v-else>
            {{ $t('BetViewList.HighBack') }} :
            {{ toCurrency(historyItem.ToWin) }}
          </div>
        </div>
        <div class="Remarks" v-if="historyItem.Remarks !== ''">
          {{ historyItem.Remarks }}
        </div>
      </div>
    </div>
    <!-- 過關 -->
    <div
      v-else-if="historyItem.BetType === 99"
      :class="isDelete ? 'DeleteBG' : ''"
      style="cursor: pointer"
      @click="strayClick"
    >
      <div class="cardHeaderRow">
        <div class="strayContentBlockRow">
          <div> {{ $t('Common.Stray') }} </div>
          <div class="strayTitleInfoText"> {{ historyItem.dataBet.length }}串1 x 1 </div>
        </div>
        <div class="rightArrowBlock">
          <i :class="arrowIconJudge(historyItem.isCollapse)" />
        </div>
      </div>
      <template v-if="historyItem.isCollapse">
        <div
          v-for="(item, index) in historyItem.dataBet"
          :key="index"
          class="strayDetailBlock"
          :class="isDelete ? 'strayDetailBlockDelete' : ''"
        >
          <div class="strayDetailTitle">
            <span
              v-if="
                item.GameType === 2 &&
                [1, 31].includes(item.CatID) &&
                [101].includes(item.WagerTypeID)
              "
              class="ScoreColor"
              style="display: inline"
            >
              {{ `(${item.CurHomeScore}:${item.CurAwayScore})` }}
            </span>
            <span class="betPosName">
              {{ item.WagerPosName }}
            </span>
            <span class="cutLine">
              {{ item.CutLine }}
            </span>
            @
            <span class="cutLine">
              {{ item.PayoutOddsStr }}
            </span>
          </div>
          <div class="strayDetailLine" />
          <div class="strayDetailContentBlock">
            <div class="strayDetailContentBlockRow">
              {{ item.CatName }}
              <template v-if="item.ItemName"> - [ {{ item.ItemName }} ] </template>
            </div>
            <div class="strayDetailContentBlockRow">
              {{ item.LeagueName }}
            </div>
            <!-- 賽馬賽狗-->
            <div v-if="[72].includes(item.CatID)" class="strayDetailContentBlockRow">
              <div v-if="isSettlement" class="ScoreColor"> [{{ item.HomeScore }}] </div>
              <div class=""> {{ item.HomeTeam }} </div>
            </div>
            <!-- 彩球 -->
            <div v-else-if="[83].includes(item.CatID)" class="strayDetailContentBlockRow">
              <template v-if="item.HomeTeam">
                <div v-if="isSettlement" class="ScoreColor"> [{{ item.HomeScore }}] </div>
                <div class=""> {{ item.HomeTeam }} </div>
              </template>
              <template v-else-if="item.AwayTeam">
                <div v-if="isSettlement" class="ScoreColor"> [{{ item.HomeScore }}] </div>
                <div class=""> {{ item.AwayTeam }} </div>
              </template>
            </div>
            <!-- 指數 -->
            <div v-else-if="[84].includes(item.CatID)" class="strayDetailContentBlockRow">
              <div v-if="isSettlement" class="ScoreColor"> [{{ item.HomeScore }}] </div>
              <div class="">
                {{ item.AwayTeam }}
              </div>
            </div>
            <div v-else class="strayDetailContentBlockRow">
              <div v-if="isSettlement" class="ScoreColor"> [{{ item.HomeScore }}] </div>
              <div class=""> {{ item.HomeTeam }} </div>
              <div class="HomeTeamSign"> ({{ $t('Common.Home') }}) </div>
              <div class="vs"> vs </div>
              <div v-if="isSettlement" class="ScoreColor"> [{{ item.AwayScore }}] </div>
              <div class="strayDetailContentBlockRowTeam">
                {{ item.AwayTeam }}
              </div>
            </div>
            <div class="strayDetailContentBlockRow">
              {{ $t('Common.Game') }} :
              <span class="startGameTime"> {{ item.ScheduleTimeStr }} </span>
            </div>
            <div v-if="item.ItemStatusContent" class="strayDetailContentBlockRow">
              <span style="color: red"> ({{ item.ItemStatusContent }}) </span>
            </div>
          </div>
        </div>
      </template>
      <div class="cardContentBlock" :class="isDelete ? 'fatherTextDecoration' : ''">
        <template v-if="historyItem.isCollapse">
          <div class="cardContentBlockRow">
            {{ $t('HistoryRecord.Bet') }} : {{ historyItem.BetTimeStr }}
          </div>
          <div class="cardContentBlockRow">
            {{ $t('Common.BetID') }} : {{ historyItem.TicketID }}
          </div>
          <div class="cardContentBlockRow">
            {{ $t('Common.Odd') }} : {{ $t('GamesSetup.NotIncludePrincipal') }}
          </div>
          <div class="cardContentBlockRow">
            {{ historyItem.dataBet.length }}串1 x 1
            {{
              `(${$t('Common.EachZu')}${historyItem.Amount}${$t('Common.Dollar')} x 1${$t(
                'Common.Group'
              )}) = ${historyItem.Amount}`
            }}
          </div>
        </template>
        <div class="cardContentBlockRow">
          <div class="cardContentBlockWithHalfRow">
            {{ $t('Common.Bet') }} : {{ toCurrency(historyItem.Amount) }}
          </div>
          <div class="cardContentBlockWithHalfRow" v-if="isSettlement">
            {{ $t('Common.Result') }} :
            <span class="resultText" :style="[resultTextColorJudge(historyItem.ResultAmount)]">
              {{ toCurrency(historyItem.ResultAmount) }}
            </span>
          </div>
          <div class="cardContentBlockWithHalfRow" v-else>
            {{ $t('BetViewList.HighBack') }} :
            {{ toCurrency(historyItem.ToWin) }}
          </div>
        </div>
        <div class="Remarks" v-if="historyItem.Remarks !== ''">
          {{ historyItem.Remarks }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { championWagerIDs } from '@/config';
  import { toCurrency } from '@/config/converter';

  export default {
    components: {},
    props: {
      historyItem: {
        type: Object,
        default: () => {},
      },
      isSettlement: {
        type: Boolean,
      },
    },
    data() {
      return {
        championWagerIDs,
      };
    },
    computed: {
      isDelete() {
        return this.historyItem?.StatusType === 'D' || this.historyItem?.StatusType === 'V';
      },
    },
    methods: {
      toCurrency,
      isShowScore(catID, GameType) {
        return (catID === 1 || catID === 31) && GameType === 2;
      },
      strayClick() {
        this.historyItem.isCollapse = !this.historyItem.isCollapse;
        this.$forceUpdate();
      },
      normalHeaderClick() {
        this.historyItem.isCollapse = !this.historyItem.isCollapse;
        this.$forceUpdate();
      },
      showOddValue(oddValue) {
        if (this.includePrincipal) {
          return this.$lib.trunc(parseFloat(oddValue) + 1);
        } else {
          return oddValue;
        }
      },
      arrowIconJudge(isCollapse) {
        if (isCollapse) {
          return 'el-icon-arrow-up';
        } else {
          return 'el-icon-arrow-down';
        }
      },
      resultTextColorJudge(value) {
        const color = value > 0 ? 'green' : value < 0 ? 'red' : 'black';
        return { color };
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import './ListCardItem.scss';
  .strayDetailContentBlockRow {
    .HomeTeamSign {
      margin-left: 5px;
    }
    .vs {
      margin-right: 5px;
      margin-left: 5px;
    }
  }
  .vs {
    margin-right: 5px;
  }
</style>
