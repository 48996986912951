<template>
  <div id="mBetRecordHistory">
    <!-- 近2週總覽 -->
    <div v-show="page === 0">
      <table class="historyTable">
        <thead>
          <tr>
            <th class="date"> {{ $t('GameDate') }} </th>
            <th> {{ $t('HistoryRecord.BetAmount') }} </th>
            <th class="result"> {{ $t('Common.Result') }} </th>
          </tr>
        </thead>
      </table>

      <div class="date-group" v-for="(week, index) in weekDataFilter" :key="index">
        <div
          class="date-type-bar"
          :class="index === openFlag ? 'on' : ''"
          @click="openFlag = index === openFlag ? -1 : index"
        >
          {{ index === 0 ? '本週' : index === 1 ? '上週' : '' }}
        </div>
        <table class="historyTable" v-show="index === openFlag">
          <tbody>
            <tr v-for="(item, i) in week" :key="i">
              <!-- 日期 -->
              <td class="date">
                <template v-if="item.week !== 128">
                  {{ item.accdate.substr(5) }}
                  <br />
                  {{ item.weekLang }}
                </template>
                <template v-else>
                  {{ item.weekLang }}
                </template>
              </td>

              <!-- 投注額 -->
              <td> {{ item.amount }} </td>

              <!-- 結果 -->
              <template v-if="item.week !== 128">
                <td
                  class="result"
                  style="text-decoration: underline"
                  @click="goDateDetails(item.accdate)"
                >
                  <el-link type="primary" :class="sumBlueClassColorJudge(item.ResultAmount)">
                    {{ Number(item.ResultAmount).toFixed(2) }}
                  </el-link>
                </td>
              </template>
              <template v-else>
                <td class="result" :class="sumClassColorJudge(item.ResultAmount)">
                  {{ Number(item.ResultAmount).toFixed(2) }}
                </td>
              </template>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- 當日詳細 -->
    <div v-show="page === 1">
      <table class="historyTable">
        <thead>
          <tr>
            <th class="date"> {{ currentDateStr }} </th>
            <th> {{ $t('HistoryRecord.BetAmount') }} </th>
            <th class="result"> {{ $t('Common.Result') }} </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(it, index) in todayDetails" :key="index">
            <td class="date"> {{ it.CatName }} </td>
            <td> {{ it.Amounts }} </td>
            <td class="result" style="text-decoration: underline" @click="goFinalDetails(it.data)">
              <el-link type="primary" :class="sumBlueClassColorJudge(it.ResultAmounts)">
                {{ it.ResultAmounts }}
              </el-link>
            </td>
          </tr>
          <tr>
            <td class="date"> {{ $t('Common.Total') }} </td>
            <td> {{ getTotal.Amounts }} </td>
            <td class="result" :class="sumClassColorJudge(getTotal.ResultAmounts)">
              {{ getTotal.ResultAmounts }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- 詳細注單內容 -->
    <div v-show="page === 2" style="padding: 10px">
      <HistoryCardItem
        v-for="(historyItem, historyIndex) in betHistoryList"
        :key="historyIndex"
        :historyItem="historyItem"
        :isSettlement="true"
      >
      </HistoryCardItem>
    </div>
  </div>
</template>

<script>
  import HistoryCardItem from '@/components/HistoryCardItem';
  export default {
    name: 'mBetRecordHistory',
    components: {
      HistoryCardItem,
    },
    data() {
      return {
        /*
        0: 近2週總覽,
        1: 當日詳細,
        2: 詳細注單內容
        */
        page: 0,
        openFlag: 0, // 0: 本週, 1: 上週
        betHistoryData: [],
        weekData: [],
        todayDetails: [],
        betHistoryList: [],
        currentDateStr: '',
        lastDateTime: '',
      };
    },
    computed: {
      weekDataFilter() {
        let thisWeek = []; // 本週
        let lastWeek = []; // 上週
        if (this.weekData && this.weekData.length > 0) {
          const thisWeekIndex = this.weekData.findIndex((it) => it.week === 128);
          if (thisWeekIndex !== -1) {
            thisWeek = this.weekData.slice(0, thisWeekIndex + 1);
            lastWeek = this.weekData.slice(thisWeekIndex + 1);
          }
        }
        return [thisWeek, lastWeek];
      },
      getTotal() {
        const total = { Amounts: 0, ResultAmounts: 0, canwins: 0 };

        this.todayDetails.forEach((item) => {
          total.Amounts = this.$lib.trunc(total.Amounts + item.Amounts);
          total.ResultAmounts = this.$lib.trunc(total.ResultAmounts + item.ResultAmounts);
          total.canwins = this.$lib.trunc(total.canwins + item.canwins);
        });
        return total;
      },
    },
    methods: {
      sumClassColorJudge(num) {
        if (parseInt(num) < 0) {
          return 'ScoreColor';
        } else if (parseInt(num) === 0) {
          return 'blackColor';
        } else {
          return 'resultScore';
        }
      },
      sumBlueClassColorJudge(num) {
        if (parseInt(num) < 0) {
          return 'ScoreColor';
        } else if (parseInt(num) === 0) {
          return 'linkColor';
        } else {
          return 'resultScore';
        }
      },
      async getTicketApi() {
        this.$store.commit('setLoading', true);
        let postData = {};
        postData = {};
        await this.$store
          .dispatch('History/getTicketApi', {
            isset: true,
            ...postData,
          })
          .then((res) => {
            this.betHistoryData = res.data.list;
          })
          .finally(() => {
            this.$store.commit('setLoading', false);
          });
      },
      async getTicketStatsApi() {
        this.$store.commit('setLoading', true);
        await this.$store
          .dispatch('History/getTicketStatsApi', {
            isset: true,
          })
          .then((res) => {
            this.weekData = res.data;
            this.$forceUpdate();
          })
          .finally(() => this.$store.commit('setLoading', false));
      },
      async getBetHistoryDetail(startTime, endTime) {
        this.$store.commit('setLoading', true);

        const postData = {
          starttime: startTime,
          endtime: endTime,
          isset: true,
          normal: false,
        };
        this.todayDetails.length = 0;
        this.todayDetails = [];

        await this.$store
          .dispatch('History/getTicketStatsApi', postData)
          .then((res) => {
            this.todayDetails = res.data
              .map((it) => {
                return {
                  Amounts: it.amount,
                  ResultAmounts: it.ResultAmount,
                  active: false,
                  canwins: it.ToWin,
                  CatName: it.CatName,
                  data: it.Items.map((itemData) => {
                    itemData.dataBet = itemData.dataBet.map((dataBet) => {
                      if (dataBet.WagerPosName === this.$t('Common.HomeTeam')) {
                        dataBet.betname = dataBet.HomeTeam;
                      } else if (dataBet.WagerPosName === this.$t('Common.AwayTeam')) {
                        dataBet.betname = dataBet.AwayTeam;
                      } else {
                        dataBet.betname = dataBet.WagerPosName;
                      }
                      return dataBet;
                    });
                    return itemData;
                  }),
                };
              })
              .filter((it, index) => index !== res.data.length - 1);
            this.$forceUpdate();
          })
          .finally(() => {
            this.$store.commit('setLoading', false);
          });
      },
      // 前往 當日詳細
      goDateDetails(time) {
        this.page = 1;
        this.currentDateStr = time.substr(5);
        this.getBetHistoryDetail(time + ' 00:00:00', time + ' 23:59:59');
        this.lastDateTime = time;
      },
      // 前往 詳細注單內容
      goFinalDetails(historyItems) {
        console.log('historyItem:', historyItems);
        this.betHistoryList = historyItems;
        this.page = 2;
      },
      goBackPage() {
        this.page > 0 && this.page--;
      },
      async refresh() {
        if (this.page === 0) {
          // 近兩週
          await this.getTicketApi();
          await this.getTicketStatsApi();
        } else if (this.page === 1) {
          // 當日
          await this.getBetHistoryDetail(
            this.lastDateTime + ' 00:00:00',
            this.lastDateTime + ' 23:59:59'
          );
        } else if (this.page === 2) {
          // 詳細
          await this.getTicketApi();
          await this.getBetHistoryDetail(
            this.lastDateTime + ' 00:00:00',
            this.lastDateTime + ' 23:59:59'
          );
        }
      },
    },
    async created() {
      this.getTicketApi();
      this.getTicketStatsApi();
    },
    watch: {
      page() {
        this.$emit('showGoBackBtn', this.page > 0);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .blackColor {
    color: #1a2b3c !important;
  }
  .ScoreColor {
    color: red !important;
    margin-right: 1px;
  }
  .linkColor {
    color: #409eff !important;
  }
  .resultScore {
    color: #0a9c00 !important;
  }
  #mBetRecordHistory {
    height: 100%;
    overflow-y: auto;
    * {
      user-select: text !important;
    }

    .historyTable {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0;
      table-layout: fixed;

      tr {
        display: table;
        width: 100%;
        table-layout: fixed;
      }

      thead {
        position: sticky;
        top: 0;
        z-index: 1;
      }

      th {
        position: relative;
        height: 40px;
        font-size: 1.25em;
        font-weight: bold;
        letter-spacing: normal;
        background-color: #e4e4e4;
        border-bottom: 1px solid #c7c7c7;
        &::after {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          width: 1px;
          height: 52%;
          margin: auto 0;
          background-color: #c7c7c7;
          display: block;
        }
      }

      td {
        height: 40px;
        font-size: 1.15rem;
        letter-spacing: normal;
        background-color: #fff;
        text-align: right;
        border-left: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        padding: 0 2%;
        line-height: 1.15em;
      }

      th.date,
      td.date {
        text-align: center;
        line-height: 1.3;
      }
      td.date {
        color: #666;
      }

      th.result,
      td.result {
        width: 40%;
      }
    }

    .date-type-bar {
      height: 40px;
      line-height: 40px;
      font-size: 1.25rem;
      background-color: #e4e4e4;
      position: relative;
      box-sizing: border-box;
      border-bottom: 1px solid #c7c7c7;
      text-align: center;
      padding-right: 70%;
      cursor: pointer;

      &.on {
        &::after {
          transform: scaleY(-1);
        }
      }

      &:active {
        &::after {
          filter: invert(100%);
        }
      }

      &::after {
        content: '';
        position: absolute;
        width: 15%;
        height: 17px;
        background: url('~@/assets/img/mobile/btn_arrow_w.svg') no-repeat center;
        background-size: auto 17px;
        opacity: 1;
        filter: invert(50%);
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto 0;
      }
    }
  }
</style>
