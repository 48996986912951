<template>
  <div id="LiveVideo">
    <div id="videoWrap"></div>
    <div id="channels">
      <ul>
        <li
          v-for="(channel, index) in channels"
          :key="index"
          class="channel"
          :class="{ active: index === playingChannelIndex }"
          :title="getChannelMessage(index)"
          @click="changePlayingChannel(index)"
        >
          <span class="text">{{ index + 1 }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import videojs from 'video.js';

  export default {
    name: 'LiveVideo',
    components: {},
    data() {
      return {
        player: null,
        playingChannelIndex: -1,
      };
    },
    computed: {
      channels() {
        const channels = this.$store.state.MoreGame.moreGameData?.LiveLinkAry;
        return channels || [];
      },
    },
    watch: {},
    methods: {
      playVideo() {
        if (this.player) {
          this.player.pause();
          this.player.dispose();
          this.player = null;
        }

        const videoWrap = document.getElementById('videoWrap');
        videoWrap.textContent = '';

        const video = document.createElement('video');
        video.style.width = 100 + '%';
        video.style.height = 260 + 'px';

        videoWrap.appendChild(video);

        this.player = videojs(
          video,
          {
            liveui: true,
            autoplay: true,
            sources: [
              {
                type: 'application/x-mpegURL',
                src: this.channels[this.playingChannelIndex],
              },
            ],
          },
          function () {
            this.playsinline(true);
          }
        );

        this.player.on('error', () => {
          this.player.dispose();
          this.player = null;
          const message = document.createElement('div');
          message.classList.add('message');
          message.textContent = '直播訊號繁忙，努力加載中，請稍等或稍後再試';
          const videoWrap = document.getElementById('videoWrap');
          videoWrap.textContent = '';
          videoWrap.appendChild(message);
        });
      },

      getChannelMessage(index) {
        if (index === this.playingChannelIndex) {
          return '播放中訊源';
        } else {
          return '切換訊源';
        }
      },
      changePlayingChannel(index) {
        if (index === this.playingChannelIndex) return;
        this.playingChannelIndex = index;
        this.playVideo();
      },
    },
    mounted() {
      if (this.channels.length) {
        this.changePlayingChannel(0);
      }
    },
    beforeDestroy() {
      if (this.player) {
        this.player.pause();
        this.player.dispose();
        this.player = null;
      }
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/sass/theme/mixin.scss';

  $videoHeight: 260px;
  $channelsHeight: 20px;

  #LiveVideo {
    ::v-deep .vjs-loading-spinner .vjs-control-text {
      color: #fff;
      display: none;
    }
    ::v-deep .vjs-loading-spinner::after {
      content: none;
    }
    ::v-deep .vjs-error-display {
      display: none;
    }
  }

  #videoWrap {
    position: relative;
    height: $videoHeight;
    text-align: center;
    background-color: #000;

    ::v-deep .message {
      display: inline-block;
      text-align: center;
      width: 80%;
      margin-top: 100px;
      color: #fff;
    }
  }

  #channels {
    @include base-color();
    height: $channelsHeight;
    padding: 4px 4px 2px;
    text-align: center;
    font-size: 16px;

    .channel {
      display: inline-block;
      width: 60px;
      text-align: center;

      & > .text {
        cursor: pointer;
      }

      &.active > .text {
        color: #fff;
        cursor: default;
      }
    }
  }

  #app[data-theme^='light'] {
    #channels {
      .channel {
        &.active > .text {
          color: #000;
          cursor: default;
        }
      }
    }
  }
</style>
