<template>
  <div v-loading="loading" id="MoreGame" :class="MoreGameClass">
    <!-- MoreGameHeader -->
    <div class="MoreGameHeader">
      <div
        v-if="isMobileMode"
        class="btn-mobile-back"
        @click="$store.commit('MoreGame/closeMoreGameList')"
      >
        <img src="@/assets/img/mobile/btn_arrow_w.svg" />
      </div>
      <div class="teamName home" :title="getteamData.home">{{ getteamData.home }}</div>
      <template v-if="selectGameType === 2">
        <div class="teamVSLive">
          <div class="topBlock"> {{ teamData.TimeAct }} </div>
          <div v-if="!teamData.SetFlag" class="bottomBlock">
            {{ `${teamData.AwayScore} : ${teamData.HomeScore}` }}
          </div>
          <div v-else class="bottomBlock">
            {{ `${teamData.HomeScore} : ${teamData.AwayScore}` }}
          </div>
        </div>
      </template>
      <template v-else>
        <div class="teamVS">vs</div>
      </template>
      <div class="teamName away" :title="getteamData.away">{{ getteamData.away }}</div>
      <img
        class="closeBtn"
        src="@/assets/img/pc/btn_close_w.svg"
        @click="$store.commit('MoreGame/closeMoreGameList')"
      />
    </div>
    <!-- GameInfoBlock -->
    <div v-if="selectGameType === 2" class="GameInfoBlock">
      <ul class="navList">
        <!-- fix -->
        <div
          v-if="isMobileMode"
          class="star"
          :class="starCSSJudge(teamData.EvtID)"
          @click="$store.commit('Setting/addFavorites', teamData.EvtID)"
        ></div>
        <li
          class="item"
          :class="{ active: gameType2Page == 0 }"
          :title="$t('Common.LiveBoard')"
          @click="gameType2Page = 0"
        >
          <img src="@/assets/img/common/btn_VM_scoreBoard.svg" />
        </li>
        <li
          v-if="hasVideos"
          class="item"
          :class="{ active: gameType2Page == 2 }"
          :title="$t('Common.Live')"
          @click="gameType2Page = 2"
        >
          <img src="@/assets/img/common/btn_GDV_live.svg" />
        </li>
      </ul>
      <div v-if="gameType2Page === 0" class="gameType2Page">
        <components
          v-if="isShowLiveScore"
          :is="LiveBoardComponentName"
          :gameScoreData="gameScoreData"
          :teamData="teamData"
        />
      </div>
      <div v-else-if="gameType2Page === 2">
        <LiveVideo v-if="hasVideos" />
        <div
          v-else
          class="coming-soon interactive"
          src="@/assets/img/common/coming_soon.jpg"
          @click="onComingSoonClick()"
        ></div>
      </div>
    </div>
    <div v-else class="GameInfoBlock">
      <ul class="navList">
        <!-- fix -->
        <div
          v-if="isMobileMode"
          class="star"
          :class="starCSSJudge(teamData.EvtID)"
          @click="$store.commit('Setting/addFavorites', teamData.EvtID)"
        ></div>
        <li
          class="item"
          :class="{ active: gameType1Page == 1 }"
          :title="$t('Common.RollBalData')"
          @click="gameType1Page = 1"
        >
          <img src="@/assets/img/common/btn_VM_runData.svg" />
        </li>
        <li
          class="item"
          :class="{ active: gameType1Page == 3 }"
          :title="$t('Common.GameData')"
          @click="gameType1Page = 3"
        >
          <img src="@/assets/img/common/btn_VM_gameData.svg" />
        </li>
      </ul>
      <div
        class="coming-soon"
        src="@/assets/img/common/coming_soon.jpg"
        @click="onComingSoonClick()"
      ></div>
    </div>
    <!-- MoreGameBlock -->
    <div class="MoreGameBlock" :class="{ MoreGameBlockWithOutGameInfo: selectGameType !== 2 }">
      <template v-if="teamData.EvtStatus === 1">
        <div class="MoreGameFilterBlock">
          <div class="leftArrowBlock">
            <i :class="collapseAllArrowIconJudge" @click="collapseAllIconClick"></i>
          </div>
          <div class="menuTab" v-for="(menuData, menuIndex) in menuTabs" :key="menuIndex">
            <div @click="selectItemKey = menuData.ItemKey">
              {{ menuData.ItemName }}
            </div>
            <div v-if="menuData.ItemKey === selectItemKey" class="active"></div>
          </div>
          <img
            v-if="isMobileMode"
            class="btn-record"
            src="@/assets/img/common/btn_record.svg"
            @click="$emit('openBetRecordView')"
          />
        </div>
        <div class="MoreGameList" :class="MoreGameListClassJudge">
          <div
            v-for="(gameData, gameIndex) in FinalGameList"
            :key="gameIndex"
            class="MoreGameListOutRow"
          >
            <div class="MoreGameListRowTitle" @click="titleClickHandler(gameData.ItemName)">
              <div class="leftArrowBlock">
                <i :class="titleArrowIconJudge(!collapseItemNames.includes(gameData.ItemName))" />
              </div>
              <div class="MoreGameListRowTitleText">
                {{ gameData.ItemName }}
              </div>
            </div>
            <template v-for="(leagueData, leagueIndex) in gameData.List">
              <template v-if="!collapseItemNames.includes(gameData.ItemName)">
                <div
                  v-for="(renderHeadData, renderHeadIndex) in leagueData.RenderHead"
                  :key="'MoreGameListInRow' + gameIndex + leagueIndex + renderHeadIndex"
                  class="MoreGameListInRow"
                >
                  <template v-if="renderHeadData.WagerTypeIDs.includes(119)">
                    <div class="wagerPlayList fix">
                      <div class="wagerPlayRow">
                        <div
                          v-for="(oddsData, oddsIndex) in renderHeadData.Odds"
                          :key="`${oddsIndex}`"
                          class="betBlock"
                          :class="isFixOddsSelected(oddsData)"
                          @click="goFixBet(oddsData, renderHeadData, teamData, leagueData, $event)"
                        >
                          <div class="betBlockTop">
                            {{ oddsData.OULineName }}
                          </div>
                          <div class="betBlockBottom">
                            {{ showFixOdds(oddsData) }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-else-if="renderHeadData.WagerTypeIDs.includes(116)">
                    <div class="wagerPlayList fix">
                      <div class="wagerPlayRow t116">
                        <div
                          v-for="(oddsData, oddsIndex) in renderHeadData.Odds"
                          :key="`${oddsIndex}`"
                          class="betBlock"
                          :class="isFixOddsSelected(oddsData)"
                          @click="goFixBet(oddsData, renderHeadData, teamData, leagueData, $event)"
                        >
                          <div class="betBlockTop">
                            {{ oddsData.OULineName }}
                          </div>
                          <div class="betBlockBottom">
                            {{ showFixOdds(oddsData) }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div class="wagerLabelBlock">
                      <!-- 單隊總分 -->
                      <template
                        v-if="
                          renderHeadData.WagerTypeIDs.includes(131) ||
                          renderHeadData.WagerTypeIDs.includes(132)
                        "
                      >
                        <template v-if="renderHeadData.HdpPos === 1">主</template>
                        <template v-else-if="renderHeadData.HdpPos === 2">客</template>
                      </template>
                      <template v-else>
                        {{ renderHeadData.HeadShowName }}
                      </template>
                    </div>
                    <div class="wagerPlayList">
                      <!-- 波膽 -->
                      <div
                        class="wagerPlayRow"
                        v-if="renderHeadData.WagerTypeIDs.indexOf(112) !== -1"
                      >
                        <template
                          v-for="(boldTemplate, boldKey) in [
                            boldTemplate1,
                            boldTemplate2,
                            boldTemplate3,
                          ]"
                        >
                          <div class="columnContainer" :key="boldKey">
                            <div class="boldBetBlockTitle" v-if="boldKey === 0">
                              {{ $t('Common.Home') }}
                            </div>
                            <div class="boldBetBlockTitle" v-if="boldKey === 1">
                              {{ $t('Common.Drew') }}
                            </div>
                            <div class="boldBetBlockTitle" v-if="boldKey === 2">
                              {{ $t('Common.Away') }}
                            </div>

                            <div
                              class="boldBetBlock"
                              v-for="(OULine, index) in boldTemplate"
                              :class="
                                boldTableBetBlockIsSelect(
                                  boldOddToMapData(renderHeadData.Odds)[OULine],
                                  OULine
                                )
                              "
                              :key="`${boldKey}-${index}`"
                              @click="
                                goBoldBet(
                                  boldOddToMapData(renderHeadData.Odds)[OULine],
                                  teamData,
                                  leagueData,
                                  $event
                                )
                              "
                            >
                              <div class="boldLeft">{{ OULine }}</div>
                              <div class="boldRight">
                                {{ showDrewOdd(boldOddToMapData(renderHeadData.Odds)[OULine]) }}
                              </div>
                            </div>
                          </div>
                        </template>
                      </div>
                      <div
                        v-else
                        class="wagerPlayRow"
                        v-for="(oddData, oddIndex) in renderHeadData.Odds"
                        :key="'wagerPlayRow' + gameIndex + leagueIndex + renderHeadIndex + oddIndex"
                      >
                        <div
                          class="betBlock"
                          v-for="(betData, betIndex) in $SportLib.oddDataToMorePlayData(
                            teamData.SetFlag,
                            oddData.WagerTypeID,
                            oddData
                          )"
                          :class="betBlockSelectCSS(betData.wagerPos, oddData)"
                          :key="
                            'betBlock' +
                            gameIndex +
                            leagueIndex +
                            renderHeadIndex +
                            oddIndex +
                            betIndex
                          "
                          :blockUniqueID="`MoreBet-${oddData.GameID}-${betIndex}`"
                          @click="goBet(gameData, betData, oddData, leagueData, $event)"
                        >
                          <div class="betBlockTop">
                            {{ betData.showMethod }}
                          </div>
                          <div class="betBlockBottom">
                            <Odd
                              :OddValue="betData.showOdd"
                              :UniqueID="`MoreBet-${oddData.GameID}-${betIndex}`"
                              :enableAni="false"
                              @oddChanged="
                                (state) =>
                                  oddChanged(state, `MoreBet-${oddData.GameID}-${betIndex}`)
                              "
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </template>
            </template>
          </div>
        </div>
      </template>
      <template v-else> </template>
      <!-- GameChatBlock -->
      <div v-if="selectGameType === 2" class="GameChatBlock">
        <GameChat ref="chat" @openPersonalPanel="openPersonalPanel()" />
      </div>
    </div>
  </div>
</template>

<script>
  import LiveBoards from '@/components/LiveBoard/LiveBoardIndex';
  import LiveVideo from '@/components/LiveVideo';
  import Odd from '@/components/Odd';
  import GameChat from '@/components/GameChat';
  import { NotCheckWagerGrpIDs } from '@/config/index.js';

  export default {
    name: 'MoreGame',
    components: {
      ...LiveBoards,
      LiveVideo,
      Odd,
      GameChat,
    },
    data() {
      return {
        gameType1Page: 1,
        gameType2Page: 0,
        selectItemKey: null,
        collapseItemNames: [],
        intervalEvent: null,
        intervalEvent2: null,
        chat: false,
        ComponentMapList: {
          1: 'Soccer',
          21: 'PingPong',
          22: 'Badminton',
          23: 'Volleyball',
          55: 'Tennis',
          82: 'Hockey',
          85: 'PCGame',
          101: 'BaseBall',
          102: 'BasketBall',
          31: 'Soccer',
        },
        boldTemplate1: ['1-0', '2-0', '2-1', '3-0', '3-1', '3-2', '4-0', '4-1', '4-2', '4-3'],
        boldTemplate2: ['0-0', '1-1', '2-2', '3-3', '4-4', 'other'],
        boldTemplate3: ['0-1', '0-2', '1-2', '0-3', '1-3', '2-3', '0-4', '1-4', '2-4', '3-4'],
      };
    },
    computed: {
      gameStore() {
        return this.$store.state.Game;
      },
      isMobileMode() {
        return process.env.VUE_APP_UI === 'mobile';
      },
      selectGameType() {
        return this.$store.state.Game.selectGameType;
      },
      selectCatID() {
        return this.$store.state.Game.selectCatID;
      },
      CatList() {
        return this.$store.state.Game.CatList;
      },
      CatMapData() {
        return this.$store.state.Game.CatMapData;
      },
      isGameScoreRefresh() {
        const catData = this.CatMapData[this.selectCatID];
        return catData && catData.GameScoreRefresh && this.selectGameType === 2;
      },
      isQuickBetEnable() {
        return this.$store.state.Game.isQuickBet.isEnable;
      },
      betCartList() {
        return this.$store.state.BetCart.betCartList;
      },
      loading() {
        return this.$store.state.MoreGame.loading;
      },
      moreGameData() {
        if (Object.keys(this.$store.state.MoreGame.moreGameData).length === 0) {
          return null;
        } else {
          return this.$store.state.MoreGame.moreGameData;
        }
      },
      hasVideos() {
        const more = this.moreGameData;
        return more && more.LiveLinkAry;
      },
      GameList() {
        if (this.moreGameData === null) {
          return [];
        }
        return this.moreGameData.List;
      },
      FinalGameList() {
        if (this.GameList.length === 0) {
          return [];
        }
        return this.GameList.filter((gameData) => {
          return gameData.ItemKeys.indexOf(this.selectItemKey) > -1;
        }).map((gameData) => {
          const newList = gameData.List.map((LeagueData) => {
            // 排列出Wager底下所有Odd
            const Odds = LeagueData.Team[0].Wager.reduce((sum, WagerData) => {
              const wagerOdds = WagerData.Odds.map((oddData) => {
                const WagerGrpID = WagerData.WagerGrpID;
                const WagerTypeID = WagerData.WagerTypeID;

                const headWagerData = gameData.BestHead.find((headData) => {
                  // 如果 WagerGrpID 不是128,需要同時檢查WagerGrpIDs和WagerTypeIDs
                  if (NotCheckWagerGrpIDs.indexOf(headData.WagerGrpIDs[0]) === -1) {
                    const WagerGrpIDIndex = headData.WagerGrpIDs.indexOf(WagerGrpID);
                    const WagerTypeIDIndex = headData.WagerTypeIDs.indexOf(WagerTypeID);

                    if (WagerTypeIDIndex !== -1 && WagerGrpIDIndex !== -1) {
                      return true;
                    } else {
                      return false;
                    }
                  } else {
                    // 如果 WagerGrpID 是128, 只要檢查 WagerTypeIDs 就好
                    const findIndex = headData.WagerTypeIDs.findIndex(
                      (typeID) => typeID === WagerTypeID
                    );
                    if (findIndex > -1) {
                      return true;
                    } else {
                      return false;
                    }
                  }
                });

                if (headWagerData) {
                  return {
                    ...oddData,
                    WagerGrpID: WagerData.WagerGrpID,
                    WagerTypeID,
                    ShowName: gameData.ItemName + headWagerData.WagerTypeName,
                  };
                } else {
                  return {
                    empty: true,
                  };
                }
              });
              return [...sum, ...wagerOdds];
            }, []).filter((it) => it?.empty === undefined);

            // 組成渲染head
            let RenderHead = JSON.parse(JSON.stringify(gameData.BestHead)).map((it) => {
              return {
                ...it,
                Odds: [],
                HeadShowName: it.WagerTypeName,
              };
            });

            // 將Odd資料組進渲染head
            Odds.forEach((OddData) => {
              const oddWagerTypeID = OddData.WagerTypeID;
              RenderHead.every((renderHeadData) => {
                if (renderHeadData.HdpPos === 128) {
                  const isFind = renderHeadData.WagerTypeIDs.find((ID) => ID === oddWagerTypeID);
                  if (isFind && OddData.Status !== -1) {
                    renderHeadData.Odds.push(OddData);
                    return false;
                  }
                } else {
                  if (OddData.HdpPos === renderHeadData.HdpPos) {
                    renderHeadData.Odds.push(OddData);
                    return false;
                  }
                }
                return true;
              });
            });

            RenderHead = RenderHead.filter((renderHeadData) => renderHeadData.Odds.length !== 0);

            return {
              ...LeagueData,
              Team: LeagueData.Team[0],
              RenderHead,
            };
          });

          return {
            ...gameData,
            List: newList,
          };
        });
      },
      LiveBoardComponentName() {
        const findCatData = this.CatList.find((catData) => {
          return catData.CatID === this.selectCatID;
        });
        if (findCatData) {
          const componentName = this.ComponentMapList[this.selectCatID];
          if (componentName) {
            return componentName;
          } else {
            console.error(`${this.selectCatID} 的球種 還沒製作`);
            return null;
          }
        } else {
          console.error(`${this.selectCatID} 的球種 不在彩種列表內`);
          return null;
        }
      },
      gameScoreData() {
        if (this.isGameScoreRefresh) {
          return this.moreGameData.GameScore;
        } else {
          return this.moreGameData.GameScoreHead;
        }
      },
      isShowLiveScore() {
        return this.moreGameData?.GameScore !== undefined;
      },
      teamData() {
        return this.$store.state.MoreGame.teamData;
      },
      menuTabs() {
        if (this.moreGameData === null) {
          return [];
        }
        return this.moreGameData.Menu;
      },
      MoreGameStoreUpdateFlag() {
        return this.$store.state.MoreGame.MoreGameStoreUpdateFlag;
      },
      getteamData() {
        let home = '';
        let away = '';
        if (!this.teamData.SetFlag) {
          home = this.teamData?.AwayTeamStr;
          away = this.teamData?.HomeTeamStr;
        } else {
          home = this.teamData?.HomeTeamStr;
          away = this.teamData?.AwayTeamStr;
        }
        return {
          home,
          away,
        };
      },
      MoreGameListClassJudge() {
        if (this.selectGameType === 2) {
          if (this.chat) return 'MoreGameListWithLive chat';
          else return 'MoreGameListWithLive';
        } else {
          return '';
        }
      },
      MoreGameClass() {
        if (process.env.VUE_APP_UI === 'mobile') {
          if (this.gameType2Page === 2) {
            return 'mobile mlive';
          }
          return 'mobile';
        } else if (this.selectGameType === 2 && this.gameType2Page === 2) {
          return 'live';
        } else if (this.selectGameType === 2 && this.gameType2Page === 0 && this.hasVideos) {
          return 'playing';
        }
        return '';
      },
      collapseAllArrowIconJudge() {
        if (this.FinalGameList.length === this.collapseItemNames.length) {
          return 'el-icon-arrow-down';
        } else {
          return 'el-icon-arrow-up';
        }
      },
      includePrincipal() {
        return this.$store.state.Setting.UserSetting.includePrincipal;
      },
    },
    watch: {
      MoreGameStoreUpdateFlag() {
        this.resetData();
      },
      menuTabs() {
        if (this.menuTabs.length !== 0) {
          this.selectItemKey = this.menuTabs[0].ItemKey;
        }
      },
      selectItemKey() {
        this.collapseItemNames.length = 0;
        this.collapseItemNames = [];
      },
      'teamData.EvtID': {
        handler() {
          if (this.selectGameType === 2) {
            window.chat.initWebsocket(this.teamData.EvtID);
          }
        },
        immediate: true,
      },
    },
    methods: {
      boldOddToMapData(boldOdd) {
        const res = boldOdd.reduce((sum, it, index) => {
          return {
            ...sum,
            ...{
              [it.OULine]: it,
            },
          };
        }, {});
        return res;
      },
      showDrewOdd(mapData) {
        if (!mapData) return '';
        const odds = mapData.DrewOdds;
        if (odds === '') return '';
        if (odds === undefined) return '';
        if (odds === null) return '';
        if (this.includePrincipal) {
          const value = this.$lib.trunc(parseFloat(odds) + 1);
          var arrayNum = value.toString().split('.');
          if (arrayNum.length === 1) {
            return value.toString() + '.00';
          }
          if (arrayNum.length > 1) {
            const v = arrayNum[1];
            if (v.length === 1) {
              return value.toString() + '00';
            } else if (v.length === 2) {
              return value.toString() + '0';
            }
            return value;
          }
          return value;
        } else {
          return odds;
        }
      },
      judgeGameLiveScore(templateCatID, selectCatID) {
        const findCatData = this.CatList.find((catData) => {
          return catData.CatID === templateCatID;
        });
        return findCatData.GroupCatIDs.findIndex((it) => it === selectCatID) !== -1;
      },
      resetData() {
        this.collapseItemNames.length = 0;
        this.collapseItemNames = [];
        this.selectItemKey = null;
      },
      titleArrowIconJudge(isCollapse) {
        if (!isCollapse) {
          return 'el-icon-arrow-down';
        } else {
          return 'el-icon-arrow-up';
        }
      },
      titleClickHandler(WagerGrpID) {
        const collapseIndex = this.collapseItemNames.indexOf(WagerGrpID);
        if (collapseIndex > -1) {
          this.collapseItemNames.splice(collapseIndex, 1);
        } else {
          this.collapseItemNames.push(WagerGrpID);
        }
      },
      betBlockSelectCSS(wagerPos, { GameID }) {
        const compareData = this.betCartList.find((cartData) => {
          return cartData.GameID === GameID;
        });

        if (compareData && compareData.wagerPos === wagerPos) {
          return 'betBlockSelect';
        } else {
          return '';
        }
      },
      collapseAllIconClick() {
        if (this.FinalGameList.length === this.collapseItemNames.length) {
          this.collapseItemNames.length = 0;
          this.collapseItemNames = [];
        } else {
          this.collapseItemNames.length = 0;
          this.collapseItemNames = this.FinalGameList.map((it) => it.ItemName);
        }
      },
      goBet(gameData, betData, oddData, leagueData, event) {
        this.$emit('AddToCart');

        const selectGameTypeID = this.$store.state.Game.selectGameType;
        const GameTypeLabel = this.$store.state.Game.GameTypeList.find(
          (it) => it.key === selectGameTypeID
        )?.value;

        const { WagerTypeID, WagerGrpID } = oddData;
        let ItemName = gameData.ItemName;
        // 全隊總分
        if ([131, 132].includes(WagerTypeID)) {
          if ([1, 11, 21].includes(WagerGrpID)) {
            ItemName = '上半 - ' + ItemName.split('-')[1]?.trim();
          }
        }
        // 首尾分
        else if ([117, 118].includes(WagerTypeID)) {
          const head = gameData.BestHead.find(
            (head) =>
              head.WagerGrpIDs.includes(WagerGrpID) && head.WagerTypeIDs.includes(WagerTypeID)
          );
          if (head) {
            ItemName = head.WagerTypeName;
          }
        }

        const data = gameData.List[0].Team;
        const betInfoData = {
          LeagueID: leagueData.LeagueID,
          HomeID: data.HomeID,
          AwayID: data.AwayID,
          TimeStr: data.ScheduleTimeStr,
          OriginShowOdd: parseFloat(betData.showOdd),
          wagerPos: betData.wagerPos,
          GameTypeID: selectGameTypeID,
          GameTypeLabel: GameTypeLabel,
          GameID: oddData.GameID,
          CatID: leagueData.CatID,
          CatNameStr: this.$store.state.Game.CatMapData[leagueData.CatID]?.Name,
          LeagueNameStr: leagueData.LeagueNameStr,
          HomeTeamStr: this.getteamData.home,
          AwayTeamStr: this.getteamData.away,
          WagerTypeID,
          WagerGrpID,
          EvtID: this.teamData.EvtID,
          EvtStatus: this.teamData.EvtStatus,
          SetFlag: this.teamData.SetFlag,
          ItemName,

          ...oddData,
        };

        this.$store.dispatch('BetCart/addToCart', betInfoData);
        this.checkQuickBet(event);
      },
      starCSSJudge(EvtID) {
        if (this.$store.state.Setting.UserSetting.favorites.indexOf(EvtID) > -1) {
          return 'active';
        } else {
          return '';
        }
      },
      openLive() {
        const safariWindow = window.open();
        safariWindow.opener = null;
        this.$store.commit('setLoading', true);
        this.$store
          .dispatch('Game/GetLiveURL')
          .then((res) => {
            safariWindow.location.href = res.data;
          })
          .finally(() => {
            this.$store.commit('setLoading', false);
          });
      },
      onComingSoonClick() {
        if (this.selectGameType === 2) {
          // 滾球
          if (this.gameType2Page === 2) {
            // 直播
            this.openLive();
          }
        }
      },
      boldTableBetBlockIsSelect(oddData, OULine) {
        if (oddData === undefined) {
          return '';
        }
        const compareData = this.betCartList.find((cartData) => cartData.GameID === oddData.GameID);
        if (compareData && compareData.OULine === OULine) {
          return 'boldTableBetBlockSelect';
        } else {
          return '';
        }
      },
      checkQuickBet(event) {
        if (this.isQuickBetEnable) {
          this.$nextTick(() => {
            const clickTarget = event.target.getBoundingClientRect();
            this.$store.commit('BetCart/showQuickBetData', {
              isShow: true,
              x: clickTarget.left + clickTarget.width / 2,
              y: clickTarget.top + 22,
            });
          });
        }
      },
      goBoldBet(boldData, teamData, leagueData, event) {
        if (boldData === undefined) {
          return;
        }

        this.$emit('AddToCart');

        const selectGameTypeID = this.$store.state.Game.selectGameType;
        const GameTypeLabel = this.$store.state.Game.GameTypeList.find(
          (it) => it.key === selectGameTypeID
        )?.value;

        const betInfoData = {
          OriginShowOdd: parseFloat(boldData.DrewOdds),
          wagerPos: 3,
          GameTypeID: selectGameTypeID,
          GameTypeLabel: GameTypeLabel,
          GameID: boldData.GameID,
          CatID: leagueData.CatID,
          CatNameStr: this.$store.state.Game.CatMapData[leagueData.CatID]?.Name,
          LeagueNameStr: leagueData.LeagueNameStr,
          HomeTeamStr: teamData?.HomeTeamStr,
          AwayTeamStr: teamData?.AwayTeamStr,
          WagerGrpID: 10,
          WagerTypeID: 112,
          EvtID: teamData.EvtID,
          EvtStatus: teamData.EvtStatus,
          SetFlag: teamData.SetFlag,
          ...boldData,
        };

        this.$store.dispatch('BetCart/addToCart', betInfoData);

        this.checkQuickBet(event);
      },
      isFixOddsSelected(oddsData) {
        const { GameID } = oddsData;
        const betData = this.betCartList.find((betData) => betData.GameID === GameID);
        if (betData) {
          return 'boldTableBetBlockSelect';
        } else {
          return '';
        }
      },
      showFixOdds(oddsData) {
        const { DrewOdds: odds } = oddsData;
        if (!odds || odds <= this.limit) return '';
        if (this.includePrincipal) {
          const value = this.$lib.trunc(parseFloat(odds) + 1);
          var arrayNum = value.toString().split('.');
          if (arrayNum.length === 1) {
            return value.toString() + '.00';
          }
          if (arrayNum.length > 1) {
            const v = arrayNum[1];
            if (v.length === 1) {
              return value.toString() + '00';
            } else if (v.length === 2) {
              return value.toString() + '0';
            }
            return value;
          }
          return value;
        } else {
          return odds;
        }
      },
      goFixBet(oddsData, headData, teamData, leagueData, event) {
        const { GameID, DrewOdds: odds, WagerGrpID, WagerTypeID, OULineName, ShowName } = oddsData;

        if (!odds || odds <= this.limit) {
          return;
        }

        this.$emit('AddToCart');

        const GameTypeID = this.selectGameType;
        const GameTypeLabel = this.gameStore.GameTypeList.find(
          (item) => item.key === GameTypeID
        )?.value;

        const { WagerTypeName } = headData;
        let ItemName = '';
        if ([119].includes(WagerTypeID) && [1, 11, 21].includes(WagerGrpID)) {
          const [WagerGrpName] = ShowName.split(' ');
          ItemName = `${WagerGrpName} ${WagerTypeName}`;
        } else {
          ItemName = `${WagerTypeName}`;
        }

        const { CatID, LeagueID, LeagueNameStr } = leagueData;
        const CatNameStr = this.gameStore.CatMapData[CatID]?.Name;
        const {
          HomeID,
          HomeTeamStr,
          AwayID,
          AwayTeamStr,
          SetFlag,
          ScheduleTimeStr: TimeStr,
          EvtID,
          EvtStatus,
        } = teamData;

        const betData = {
          GameTypeID,
          GameTypeLabel,
          CatID,
          CatNameStr,
          LeagueID,
          LeagueNameStr,
          HomeID,
          HomeTeamStr,
          AwayID,
          AwayTeamStr,
          SetFlag,
          TimeStr,
          EvtID,
          EvtStatus,
          WagerGrpID,
          WagerTypeID,
          GameID,
          OriginShowOdd: parseFloat(odds),
          wagerPos: 3,
          ItemName,
          OULineName,
          ...oddsData,
        };

        this.$store.dispatch('BetCart/addToCart', betData);
        this.checkQuickBet(event);
      },

      oddChanged(state, UniqueID) {
        const el = document.querySelector(`.betBlock[blockUniqueID=${UniqueID}]`);
        if (el) {
          if (state === 1) {
            el.classList.remove('ani-value-up');
            el.classList.remove('ani-value-down');
            el.classList.add('ani-value-up');
          } else if (state === 2) {
            el.classList.remove('ani-value-up');
            el.classList.remove('ani-value-down');
            el.classList.add('ani-value-down');
          } else {
            el.classList.remove('ani-value-up');
            el.classList.remove('ani-value-down');
          }
        }
      },
      openPersonalPanel() {
        this.$emit('openPersonalPanel');
      },
    },
    created() {
      this.intervalEvent = setInterval(() => {
        this.$store.dispatch('MoreGame/GetMoreGameDetailSmall', this.teamData.EvtID);
      }, 6000);
      if (this.isGameScoreRefresh) {
        this.intervalEvent2 = setInterval(() => {
          this.$store.dispatch('MoreGame/GetGameLiveResult', this.teamData.EvtID);
        }, 5000);
      }
    },
    mounted() {
      if (this.selectGameType === 2) {
        this.$watch(
          () => {
            return this.$refs.chat.isChatEnable;
          },
          (val) => {
            this.chat = val;
          }
        );
      }
    },
    beforeDestroy() {
      clearInterval(this.intervalEvent);
      clearInterval(this.intervalEvent2);
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/sass/theme/mixin.scss';

  .MoreGameHeader {
  }
  #app[data-theme^='light'] {
    #MoreGame {
      background-color: #eeeeee;
      border-right: 2px solid #bbb;
      ul.navList {
        background: #666;
      }
      .MoreGameFilterBlock {
        background-color: #666;
      }
      .MoreGameListRowTitle {
        background-color: #eee;
      }
      .MoreGameListInRow {
        background-color: white;
        border-bottom: 1px solid #eeeeee;
        .MoreGameListRowTitle {
          border-bottom: 1px solid #d6d6d6;
        }
        .wagerPlayRow {
          border-bottom: 1px solid #eeeeee;
          border-left: 1px solid #eeeeee;
        }
        .betBlockTop {
          border-left: 1px solid #eeeeee;
        }
        .betBlockBottom {
          border-left: 1px solid #eeeeee;
        }
      }
      .betBlock {
        &:hover {
          background-color: #ffe1ae;
        }
      }
      .betBlockSelect {
        background-color: #ffd5d5;
      }
      .boldTableBetBlockSelect {
        background-color: #ffd5d5;
      }
      .menuTab {
        .active {
          background-color: #caffed;
        }
      }
    }
  }

  #app[data-theme^='dark'] {
    #MoreGame {
      background-color: #585858;
      border-right: 2px solid #222;
      ul.navList {
        background: #222;
      }
      .MoreGameFilterBlock {
        background-color: #404040;
      }
      .MoreGameHeader {
        background-color: #333;
        border-bottom-color: #333;
      }
      .MoreGameListRowTitle {
        background-color: #666;
        color: white;
      }
      .MoreGameListInRow {
        background-color: #585858;
        border-bottom: 1px solid #5f5f5f;
        color: white;
        .MoreGameListRowTitle {
          border-bottom: 1px solid #5f5f5f;
        }
        .wagerPlayRow {
          border-bottom: 1px solid #5f5f5f;
          border-left: 1px solid #5f5f5f;
        }
        .betBlockTop {
          border-left: 1px solid #5f5f5f;
        }
        .betBlockBottom {
          border-left: 1px solid #5f5f5f;
        }
      }
      .betBlock {
        &:hover {
          background-color: #777;
        }
      }
      .betBlockSelect {
        background-color: white;
        color: black;
      }
      .boldTableBetBlockSelect {
        background-color: white;
      }
      .menuTab {
        .active {
          background-color: #00aeff;
        }
      }
    }
  }

  #MoreGame {
    $gameWidth: 370px;
    $gameHeaderHeight: 35px;
    $gameInfoHeight: 180px;
    $gameChatHeight: 60px;
    $liveWidth: 480px;
    $liveHeight: 320px;

    position: relative;
    width: $gameWidth;
    height: 100%;

    &.playing,
    &.live {
      width: $liveWidth;

      .GameInfoBlock {
        height: $liveHeight;
      }

      .coming-soon {
        height: $liveHeight;
      }

      .MoreGameBlock {
        height: calc(100% - $liveHeight - $gameHeaderHeight);
      }
    }

    &.mlive {
      .GameInfoBlock {
        height: $liveHeight;
      }

      .coming-soon {
        height: $liveHeight;
      }

      .MoreGameBlock {
        height: calc(100% - $liveHeight - $gameHeaderHeight);
      }
    }

    .MoreGameHeader {
      @include nav-headrtBgcolor();

      position: relative;
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: 1fr 110px 1fr;
      width: 100%;
      height: $gameHeaderHeight;

      .teamName {
        line-height: 35px;
        color: #fff;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 0 1rem;
        &.home {
          text-align: right;
        }
        &.away {
          text-align: left;
          padding-right: 2rem;
        }
      }
      .teamVS {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0 8px;
        overflow: hidden;
        color: #ffdf1b;
        font-size: 15px;
      }
      .teamVSLive {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 8px;
        overflow: hidden;
        .topBlock {
          color: #c5f0c5;
          text-align: center;
        }
        .bottomBlock {
          color: white;
          text-align: center;
        }
      }
      .closeBtn {
        width: 13px;
        height: 13px;
        position: absolute;
        right: 10px;
        opacity: 0.5;
        top: 10px;
        cursor: pointer;
        &:hover {
          opacity: 1;
        }
      }

      .btn-mobile-back {
        position: absolute;
        left: 10px;
        top: 10px;
        img {
          width: 15px;
          height: auto;
          transform: rotate(90deg);
          opacity: 0.7;
          margin-right: 1rem;
          cursor: pointer;
          &:active {
            opacity: 1;
          }
        }
      }
    }

    .GameInfoBlock {
      height: $gameInfoHeight;

      .gameType2Page {
        height: calc(100% - 35px);
      }
    }

    .MoreGameBlock {
      $MoreGameFilterBlockHeight: 35px;

      position: relative;
      width: 100%;
      height: calc(100% - 215px);
      overflow-y: auto;

      .leftArrowBlock {
        width: 50px;
        margin-right: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        cursor: pointer;
        i {
          font-size: 15px;
          margin-top: -2px;
        }
      }

      .MoreGameFilterBlock {
        height: $MoreGameFilterBlockHeight;
        width: 100%;

        color: white;
        display: flex;
        align-items: center;
        .menuTab {
          display: flex;
          align-items: center;
          cursor: pointer;
          margin-right: 20px;
          width: fit-content;
          flex-wrap: wrap;

          .active {
            width: 100%;
            height: 3px;

            margin-top: 2px;
          }
        }
      }

      .MoreGameList {
        overflow-y: auto;

        .MoreGameListOutRow {
          width: 100%;
          height: fit-content;
          .MoreGameListRowTitle {
            width: 100%;

            color: #000;
            height: 35px;
            cursor: pointer;

            display: flex;
            align-items: center;
          }
          .MoreGameListInRow {
            height: fit-content;
            display: flex;
            align-items: center;
            width: 100%;
            min-height: 50px;

            .wagerLabelBlock {
              width: 78px;
              height: 100%;
              display: flex;
              justify-content: center;
            }
            .wagerPlayList {
              width: calc(100% - 78px);
              &.fix {
                width: 100%;
              }

              .wagerPlayRow {
                display: flex;
                width: 100%;

                &.t116 {
                  flex-wrap: wrap;

                  .betBlock {
                    width: 33.33%;
                    border-bottom: 1px solid #eeeeee;
                  }
                }

                &:last-child {
                  border-bottom: 0px;
                }
                .betBlock {
                  width: 100%;
                  display: flex;
                  flex-wrap: wrap;
                  justify-content: center;
                  position: relative;
                  overflow: hidden;
                  cursor: pointer;

                  .betBlockTop {
                    width: 100%;
                    height: 25px;
                    padding-bottom: 2px;

                    display: flex;
                    align-items: flex-end;
                    justify-content: center;
                    cursor: pointer;
                    pointer-events: none;
                  }
                  .betBlockBottom {
                    padding-top: 2px;
                    width: 100%;
                    height: 25px;
                    text-align: center;
                    color: #3fa381;

                    cursor: pointer;
                    pointer-events: none;
                    font-weight: bold;
                  }
                }

                .columnContainer {
                  width: 33.33%;
                  border-left: 1px solid #eeeeee;
                  // border-right: 1px solid #eeeeee;
                  %boldBetBLock {
                    width: 100%;
                    height: 45px;
                    line-height: 45px;
                  }

                  .boldBetBlockTitle {
                    @extend %boldBetBLock;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-bottom: 1px solid #eeeeee;
                    // border-right: 1px solid #eeeeee;
                  }
                  .boldBetBlock {
                    @extend %boldBetBLock;
                    padding: 0 5px;
                    display: flex;
                    justify-content: space-between;
                    border-bottom: 1px solid #eeeeee;
                    // border-right: 1px solid #eeeeee;
                    cursor: pointer;
                    .boldLeft {
                      width: 40%;
                      text-align: right;
                      padding: 0 2.5px;
                      pointer-events: none;
                    }
                    .boldRight {
                      width: 60%;
                      padding: 0 2.5px;
                      text-align: center;
                      color: #3fa381;
                      pointer-events: none;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .MoreGameListWithLive {
        height: calc(100% - $MoreGameFilterBlockHeight - 60px);
      }

      .MoreGameListWithLive.chat {
        height: 35px;
      }
    }

    .GameChatBlock {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
    }

    ul.navList {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: stretch;
      height: 35px;
      li.item {
        text-align: center;
        padding: 4px 8px;
        display: flex;
        align-items: center;

        cursor: pointer;
        img {
          opacity: 0.4;
          height: 100%;
          max-height: 30px;
        }
        &:hover,
        &.active {
          img {
            opacity: 1;
          }
        }
      }
    }

    div.coming-soon {
      height: 150px;
      text-align: center;
      background: #333;
      background-image: url('~@/assets/img/common/coming_soon.jpg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      &.interactive {
        cursor: pointer;
      }
    }

    .star {
      position: absolute;
      top: 50%;
      left: 8px;
      transform: translateY(-56%);
      width: 25px;
      height: 25px;
      background: url('~@/assets/img/common/icon_star.svg') no-repeat center bottom;
      background-size: 100% auto;
      background-position: center bottom;
      margin: auto 15px auto auto;
      display: block;
      cursor: pointer;

      &:active,
      &.active {
        background-position: center top;
      }
    }
    .btn-record {
      width: 25px;
      height: 25px;
      margin-left: auto;
      margin-right: 15px;
      cursor: pointer;
    }

    // 賠率動畫
    .ani-value-up {
      &::after {
        content: '';
        position: absolute;
        right: 0;
        width: 3px;
        background: linear-gradient(transparent, red 20%, red 80%, transparent);
        height: 100%;
        animation: oddChanged 1s ease-in-out infinite;
      }
    }

    .ani-value-down {
      &::after {
        content: '';
        position: absolute;
        right: 0;
        width: 3px;
        height: 100%;
        background: linear-gradient(transparent, green 20%, green 80%, transparent);
        animation: oddChanged 1s ease-in-out infinite reverse;
      }
    }

    @keyframes oddChanged {
      0% {
        transform: translateY(100%);
      }
      100% {
        transform: translateY(-100%);
      }
    }
  }
</style>
