<template>
  <div class="listCardItem" :class="listCardItemClassJudge">
    <div class="cardHeaderRow">
      <div v-if="isShowScore" class="ScoreColor" :class="scoreChangeJudge">
        {{ liveScoreText }}
      </div>
      <div class="playMethodName"> {{ showBetTitle(displayData.showBetTitle) }} </div>
      <div class="playMethodNameSupport" :class="playMethodNameSupportChangeJudge">
        {{ displayData.showCutLine }}
      </div>
      <div class="at"> @ </div>
      <div
        v-if="childIndex === 0"
        class="playBetOdd"
        :class="playBetOddClassJudge(cartData.OriginShowOdd, displayData.showOdd)"
      >
        {{ showOddValue(displayData.showOdd) }}
      </div>
      <div
        v-if="childIndex === 1"
        class="playBetOdd"
        :class="playBetOddClassJudge(cartData.OriginShowOdd, displayData.showOdd)"
      >
        {{ showOddValue(displayData.showOdd2) }}
      </div>
      <i
        class="el-icon-close"
        v-if="panelMode !== PanelModeEnum.result"
        :style="isShowBlackMask ? 'color:white;' : ''"
        @click="cancelSingleHandler(cartData.GameID)"
      ></i>
    </div>

    <div class="cardContentBlock">
      <template v-if="displayData.wagerGrpLabel !== ''">
        <div class="cardContentBlockRow">
          {{ displayData.showGameTypeLabel }}
        </div>
        <div class="cardContentBlockRow"> {{ cartData.LeagueNameStr }} </div>
      </template>
      <template v-else>
        <div class="cardContentBlockRow">
          {{ `${displayData.showGameTypeLabel} - [${cartData.LeagueNameStr}]` }}
        </div>
      </template>

      <div class="cardContentBlockRow">
        <!-- 只需要顯示一個隊伍 -->
        <template
          v-if="
            cartData.hasOwnProperty('HomeTeamStr') &&
            cartData.hasOwnProperty('AwayTeamStr') &&
            cartData.AwayTeamStr === '.'
          "
        >
          <div class="teamRow">{{ cartData.HomeTeamStr }}</div>
        </template>
        <!-- 只需要顯示一個隊伍 -->
        <template
          v-else-if="
            cartData.hasOwnProperty('HomeTeamStr') &&
            cartData.hasOwnProperty('AwayTeamStr') &&
            cartData.HomeTeamStr === '.'
          "
        >
          <div class="teamRow">{{ cartData.AwayTeamStr }}</div>
        </template>
        <!-- 彩球 -->
        <template v-else-if="[83].includes(cartData.CatID)">
          <template v-if="cartData.HdpPos === 0">
            <div class="cardContentBlockRowText">
              {{ cartData.HomeTeamStr }}
            </div>
          </template>
          <template>
            <div v-if="cartData.HdpPos === 1" class="cardContentBlockRowText">
              {{ cartData.HomeTeamStr }}
            </div>
            <div v-if="cartData.HdpPos === 2" class="cardContentBlockRowText">
              {{ cartData.AwayTeamStr }}
            </div>
          </template>
        </template>
        <!-- 單隊總分 -->
        <template v-else-if="[131, 132].includes(cartData.WagerTypeID)">
          <div v-if="cartData.HdpPos === 1" class="cardContentBlockRowText">
            {{ cartData.HomeTeamStr }}
          </div>
          <div v-if="cartData.HdpPos === 2" class="cardContentBlockRowText">
            {{ cartData.AwayTeamStr }}
          </div>
        </template>
        <template v-else>
          <div class="cardContentBlockRowText" v-if="cartData.hasOwnProperty('HomeTeamStr')">
            {{ cartData.HomeTeamStr }}
          </div>

          <div class="cardContentBlockRowText" v-if="cartData.hasOwnProperty('AwayTeamStr')">
            <template v-if="cartData.HomeTeamStr && cartData.AwayTeamStr"> v </template>
            {{ cartData.AwayTeamStr }}
          </div>
        </template>
      </div>
      <!-- 一般投注每一個item的各自金額 -->
      <div class="cardContentBlockRow" v-if="childIndex === 0">
        <div
          class="lockRowInfo"
          v-if="
            panelMode === PanelModeEnum.lock ||
            panelMode === PanelModeEnum.result ||
            cartData.isSuccessMode
          "
        >
          <div class="infoItem">
            <div class="infoItemTitle">{{ $t('Common.Bet') }} :</div>
            <div class="infoItemVal"> {{ toCurrency(cartData.betAmount) }}</div>
          </div>
          <div class="infoItem">
            <div class="infoItemTitle">{{ $t('Common.CanWin') }} :</div>
            <div class="infoItemVal"> {{ toCurrency(cartData.winAmount) }}</div>
          </div>
        </div>
        <div class="inputRow" v-else>
          <div class="input-wrap">
            <input
              ref="BetAmountInput"
              class="input"
              :class="{
                gray: focusTarget === 2,
                redInputOutLine: (isShowMinText || isShowMaxText) && childIndex === 0,
              }"
              type="number"
              v-model="cartData.betAmount"
              :max="cartData.BetMax"
              :min="cartData.BetMin"
              :placeholder="
                cartData.BetMin !== null && cartData.BetMax !== null
                  ? cartData.BetMin + '-' + cartData.BetMax
                  : ''
              "
              :readonly="isMobileMode"
              @click="onCardInputClick()"
              @focus="onInputFocus('betAmount')"
              @blur="betAmountBlur(cartData.GameID)"
              @input="inputRowItemChangeHandler(cartData)"
            />
            <span
              class="btnClearValue"
              v-if="showBetCartList[cartIndex].betAmount"
              @click="clearItemInputByIndex(cartIndex)"
            >
              <i class="el-icon-error"></i>
            </span>
          </div>
          <div class="input-wrap">
            <input
              class="input"
              :class="{ gray: focusTarget === 1 }"
              type="number"
              v-model="cartData.winAmount"
              :placeholder="$t('Common.CanWinMoney')"
              :readonly="isMobileMode"
              @click="onCardInputClick()"
              @focus="onInputFocus('winAmount')"
              @blur="winAmountBlur(cartData.GameID)"
              @input="inputRowItemWinAmountChangeHandler(cartData, cartIndex)"
            />
            <span
              class="btnClearValue"
              v-if="showBetCartList[cartIndex].winAmount"
              @click="clearItemInputByIndex(cartIndex)"
            >
              <i class="el-icon-error"></i>
            </span>
          </div>
          <div
            class="submitBtn"
            v-if="isMobileMode && isControlByBetSingle"
            @click="$emit('MobileListItemSubmitBet')"
          >
            {{ $t('Common.SubmitBet') }}
          </div>
        </div>
      </div>

      <div
        class="cardContentBlockRow limitText"
        v-if="panelMode === PanelModeEnum.normal && childIndex === 0"
      >
        {{ $t('ListCardItem.BetLimit') }} : {{ toCurrency(cartData.BetMaxEvt) }}
      </div>

      <div
        class="cardContentBlockRow overText"
        v-if="isShowStatusData && childIndex === 0 && !cartData.isSuccessMode"
      >
        {{ cartData.statusMessage }}
      </div>

      <div
        class="cardContentBlockRow"
        style="color: green"
        v-if="cartData.isSuccessMode && childIndex === 0 && panelMode === PanelModeEnum.normal"
      >
        <img class="betResultImgIcon" src="@/assets/img/common/betView/ok.png" alt="" />
        {{ $t('common.tradeSuccess') }}
      </div>

      <div class="cardContentBlockRow overText" v-if="isShowMinText && childIndex === 0">
        {{ $t('Common.BetMinTip') }}
      </div>
      <div class="cardContentBlockRow overText" v-else-if="isShowMaxText && childIndex === 0">
        {{ $t('Common.BetMaxTip') }}
      </div>

      <BetResultBlock v-if="childIndex === 0" :panelMode="panelMode" :cartData="cartData">
      </BetResultBlock>

      <!-- 小鍵盤 -->
      <mBetKeyboard
        v-if="isMobileMode && isShowKeyboard && panelMode === PanelModeEnum.normal"
        :isShowMaxChip="isShowMaxChip"
        :theMaxChipValue="theMaxChipValue"
        @Add="(data) => $emit('Add', data)"
        @Assign="(data) => $emit('Assign', data)"
      ></mBetKeyboard>
    </div>

    <div class="blackMaskErrorBlock" v-if="isShowBlackMask">
      <div class="blackMaskText"> {{ $t('ListCardItem.ItemIsClosePlzRemove') }} </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import BetResultBlock from '@/components/BetResultBlock';
  import mBetKeyboard from '@/components/mBetKeyboard';
  import { PanelModeEnum } from '@/config/BetPanelMode';
  import { toCurrency } from '@/config/converter';

  export default {
    name: 'ListCardItem',
    components: {
      mBetKeyboard,
      BetResultBlock,
    },
    props: {
      // 是否顯示最大的籌碼
      isShowMaxChip: {
        type: Boolean,
        default: false,
      },
      // 最大籌碼面額
      theMaxChipValue: {
        type: Number,
        default: 0,
      },
      cartData: {
        type: Object,
        default: null,
      },
      cartIndex: {
        type: Number,
        default: null,
      },
      childIndex: {
        type: Number,
        default: null,
      },
      currShowKeyboardIndex: {
        type: Number,
        default: -1,
      },
      listCardItemClassJudge: {
        type: String,
        default: '',
      },
      // 是否來自 mGamesBetInfoSingle 控制
      isControlByBetSingle: {
        type: Boolean,
        default: false,
      },
      isShowMinText: {
        type: Boolean,
        default: false,
      },
      isShowMaxText: {
        type: Boolean,
        default: false,
      },
      // 外部下方總控制, 單項投注時focus的input目標: 1=單注; 2=可贏金額
      wholeFocusTarget: {
        type: Number,
        default: null,
      },
    },
    data() {
      return {
        PanelModeEnum,
        initShowCutLine: null,
        initLiveScore: null,
        focusTarget: null, // 目前focus的input:  1=下注金額; 2=可贏金額
      };
    },
    created() {
      this.initShowCutLine = this.displayData.showCutLine;

      if (this.liveScoreText !== '') {
        this.initLiveScore = this.liveScoreText;
      }
    },
    mounted() {
      // 手機預設要focus
      if (this.isMobileMode && (this.isControlByBetSingle || this.$refs.BetAmountInput)) {
        this.$nextTick(() => {
          this.onInputFocus('betAmount');
        });
      }
    },
    watch: {
      triggerListCardItemClear() {
        this.initShowCutLine = this.displayData.showCutLine;
        this.initLiveScore = this.liveScoreText;
        this.$store.commit('BetCart/updateBetCartListOriginShowOddByGameID', {
          gameID: this.cartData.GameID,
          newShowOdd: parseFloat(this.displayData.showOdd),
        });
      },
      liveScoreText() {
        if (this.initLiveScore === null && this.liveScoreText !== '') {
          this.initLiveScore = this.liveScoreText;
        }
      },
      // 監聽外部總控制
      wholeFocusTarget: {
        handler(target) {
          this.focusTarget = target;
        },
        immediate: true,
      },
    },
    computed: {
      ...mapState(['userBalance']),
      isShowScore() {
        const { GameTypeID, HomeScore, WagerTypeID } = this.cartData;
        if (GameTypeID === 2 && HomeScore && [101].includes(WagerTypeID)) return true;
        return false;
      },
      isShowStatusData() {
        return this.$store.state.BetCart.isShowStatusData;
      },
      triggerListCardItemClear() {
        return this.$store.state.BetCart.triggerListCardItemClear;
      },
      playMethodNameSupportChangeJudge() {
        const cutline = this.displayData.showCutLine;
        if (this.initShowCutLine !== cutline) {
          this.$store.commit('BetCart/updateBetCartListDataChangeByGameID', this.cartData.GameID);
          if (this.initShowCutLine.substring(1) === cutline.substring(1)) return '';
          return 'playMethodNameSupportChange';
        } else {
          return '';
        }
      },
      scoreChangeJudge() {
        if (this.initLiveScore !== this.liveScoreText) {
          return 'ScoreColorChange';
        } else {
          return '';
        }
      },
      liveScoreText() {
        if (this.liveScore('home') === '' || this.liveScore('away') === '') {
          return '';
        } else {
          return `(${this.liveScore('home')}:${this.liveScore('away')})`;
        }
      },
      selectGameType() {
        return this.$store.state.Game.selectGameType;
      },
      displayData() {
        return this.cartDataToDisplayData(this.cartData);
      },
      isMobileMode() {
        return process.env.VUE_APP_UI === 'mobile';
      },
      isShowBlackMask() {
        return (
          this.cartData.Status !== 1 || this.cartData.EvtStatus !== 1 || this.cartData.isForceLock
        );
      },
      includePrincipal() {
        return this.$store.state.Setting.UserSetting.includePrincipal;
      },
      showBetCartList() {
        return this.$store.getters['BetCart/showBetCartList'];
      },
      isShowKeyboard() {
        // 單項投注 & 點擊了此item的input
        return this.childIndex === 0 && this.currShowKeyboardIndex === this.cartIndex;
      },
      panelMode() {
        return this.$store.state.BetCart.panelMode;
      },

      isAddNewToChart() {
        return this.$store.state.BetCart.isAddNewToChart;
      },
      betInfo() {
        return this.$store.state.Game.betInfo;
      },
      realBetMax() {
        return this.$lib.BetMaxLimit(this.cartData.BetMax, this.cartData.BetMaxEvt);
      },
    },
    methods: {
      toCurrency,
      liveScore(type) {
        if (this.cartData.CatID === 1 || this.cartData.CatID === 31) {
          if (type === 'home') {
            if (this.cartData?.HomeScore !== undefined) {
              return this.cartData.HomeScore;
            } else {
              return '';
            }
          } else {
            if (this.cartData?.AwayScore !== undefined) {
              return this.cartData.AwayScore;
            } else {
              return '';
            }
          }
        } else {
          return '';
        }
      },
      showBetTitle(showBetTitle) {
        const { WagerTypeID, OULineName } = this.cartData;
        if (WagerTypeID === 116) {
          return OULineName;
        }

        if (showBetTitle === this.$conf.BoldOtherKeyName) {
          return this.$t('Bold.Other');
        } else {
          return showBetTitle;
        }
      },
      betAmountBlur(GameID) {
        this.$emit('lastBlurInputEvent', {
          name: 'rowItem',
          from: 'betAmount',
          GameID,
        });
      },
      winAmountBlur(GameID) {
        this.$emit('inputRowItemChangeHandler');
        this.$emit('lastBlurInputEvent', {
          name: 'rowItem',
          from: 'winAmount',
          GameID,
        });
      },
      onCardInputClick() {
        this.$emit('onCartListItemKeyboardShow', this.cartIndex);
      },
      cancelSingleHandler(gameId) {
        this.$emit('cancelSingleHandler', gameId);
      },
      cartDataToDisplayData(cartData) {
        return this.$SportLib.cartDataToDisplayData(cartData);
      },
      inputRowItemChangeHandler(cartData) {
        cartData.betAmount = parseFloat(cartData.betAmount.replace(/[^\d]/g, ''));
        if (isNaN(cartData.betAmount)) {
          cartData.betAmount = 0;
        }
        if (cartData.betAmount > this.userBalance) {
          cartData.betAmount = this.userBalance;
        }
        this.$emit('inputRowItemChangeHandler');
      },
      inputRowItemWinAmountChangeHandler(cartData, winAmountIndex) {
        cartData.winAmount = parseFloat(cartData.winAmount.replace(/[^\d]/g, ''));
        if (isNaN(cartData.winAmount)) {
          cartData.winAmount = 0;
        }
        this.showBetCartList.forEach((cartData, cartIndex) => {
          if (cartIndex === winAmountIndex) {
            const displayData = this.cartDataToDisplayData(cartData);
            if (cartData.winAmount !== null) {
              cartData.betAmount = this.$lib.truncCeil(
                cartData.winAmount / this.$lib.trunc(parseFloat(displayData.showOdd))
              );
              if (cartData.betAmount > this.userBalance) {
                cartData.betAmount = this.userBalance;
              }
            }
          }
        });
      },
      playBetOddClassJudge(OriginShowOdd, NowShowOdd) {
        if (parseFloat(OriginShowOdd) < parseFloat(NowShowOdd)) {
          this.$store.commit('BetCart/updateBetCartListDataChangeByGameID', this.cartData.GameID);
          return 'highOddChangeStyle';
        } else if (parseFloat(OriginShowOdd) > parseFloat(NowShowOdd)) {
          this.$store.commit('BetCart/updateBetCartListDataChangeByGameID', this.cartData.GameID);
          return 'lowOddChangeStyle';
        } else {
          return '';
        }
      },
      showOddValue(oddValue) {
        if (this.includePrincipal) {
          return this.$lib.trunc(parseFloat(oddValue) + 1);
        } else {
          return oddValue;
        }
      },
      onInputFocus(typeFrom) {
        const { GameTypeID, CatID, WagerTypeID, BetMax } = this.cartData;
        let emitBetMax = BetMax;
        if (!emitBetMax) {
          const findBetInfoData = this.betInfo.find((it) => {
            return (
              it.GameType === GameTypeID && it.CatID === CatID && it.WagerTypeID === WagerTypeID
            );
          });
          if (findBetInfoData) {
            emitBetMax = findBetInfoData.BetMax;
          }
        }

        if (emitBetMax) {
          this.$emit('inputFocusEvent', {
            from: typeFrom,
            BetMax: emitBetMax,
          });
        }

        if (this.isMobileMode) {
          // 強制不調用手機虛擬鍵盤
          document.activeElement.blur();
        }

        // 設定 focusTarget
        if (typeFrom === 'betAmount') {
          this.focusTarget = 1;
        } else if (typeFrom === 'winAmount') {
          this.focusTarget = 2;
        }
      },
      clearItemInputByIndex(index) {
        this.$emit('clearItemInputByIndex', index);
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import './ListCardItem.scss';

  .ScoreColor {
    font-weight: 700;
  }
</style>
