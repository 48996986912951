<template>
  <div id="MobileGames">
    <div class="main-layout">
      <div class="main-header">
        <MobileHeader
          :unreadQACount="unreadQACount"
          :page="page"
          @openService="openService()"
          @gameTypeClickHandler="onSelectType"
          @goPage="(v) => (page = v)"
          @openMenuPanel="openMenuPanel()"
        />
      </div>
      <div class="main-body">
        <template v-if="page === PageEnum.game">
          <mGameCatNav
            v-if="ok && page === PageEnum.game"
            @onCatTypeClick="onSelectCat"
            @callGetFavoriteGameDetail="callGetFavoriteGameDetail()"
          />
          <template v-if="GameList.length">
            <mGameTable
              v-for="(gameData, index) in GameList"
              :key="index"
              :gameData="gameData"
              :hasMoreGame="gameData.Items.hasMoreCount && selectCatID !== championWagerKey"
              @openWagerTypePopup="openWagerTypePopup"
            />
          </template>
          <template v-else>
            <div class="noData" v-if="!$store.state.isLoading"> {{ $t('Common.NoGame') }} </div>
          </template>
        </template>
        <template v-else-if="page === PageEnum.gameResult">
          <mGameResultCatNav
            v-if="page === PageEnum.gameResult"
            :date="gameResultDate"
            @onCatTypeClick="onSelectWager"
            @changeGameResultCatId="(id) => (gameResultCatId = id)"
            @openDatePicker="$refs.gameResult.isShowDatePicker = true"
          />
          <template v-if="true">
            <mGameResultTable
              ref="gameResult"
              :selectedCatId="gameResultCatId"
              :gameResultSelectedLeagueIDs="gameResultSelectedLeagueIDs"
              @date="(date) => (gameResultDate = date)"
            />
          </template>
          <template v-else>
            <div class="noData" v-if="!$store.state.isLoading">
              {{ $t('Common.NoGameResult') }}
            </div>
          </template>
        </template>
        <template v-else-if="page === PageEnum.announcement">
          <mAnnouncement />
        </template>
        <template v-else-if="page === PageEnum.liveScore">
          <mLiveScorePage />
        </template>
        <template v-else-if="page === PageEnum.rules">
          <mRulesPage />
        </template>
        <template v-else-if="page === PageEnum.strayCounter">
          <mStrayCounter ref="StrayCountDialog" />
        </template>
        <div id="tracing" v-if="tracingList.length">
          <div
            v-for="(item, index) in tracingList"
            :key="index"
            class="item"
            :class="getTracingClass(item)"
            @click="showTracingResult(item)"
          >
            <div v-if="item.running" v-loading="true" />
            {{ item.title }}
          </div>
        </div>
      </div>
      <div class="main-footer">
        <MobileFooter
          :hasLeagueFiltered="hasLeagueFiltered"
          :page="page"
          @openBetInfoPopup="isShowBetInfo ? (isShowBetInfo = false) : openBetInfoPopup()"
          @openBetRecordView="openBetRecordView()"
          @openLeaguesPanel="openLeaguesPanel()"
          @openMenuPanel="openMenuPanel()"
          @goPage="(v) => (page = v)"
        />
      </div>
    </div>
    <div v-if="ok" class="fixed-container">
      <MoreGame
        v-if="isShowMoreGame"
        @openBetRecordView="openBetRecordView()"
        @openPersonalPanel="isOpenPersonalPanel = true"
      />
      <mGamesBetInfoSingle
        v-show="isShowBetInfo && betCartList.length === 1"
        ref="singleBet"
        :isShowMoreGame="isShowMoreGame"
        @onHide="isShowBetInfo = false"
      />
      <mGamesBetInfoAll
        ref="betInfoAll"
        v-show="isShowBetInfo && betCartList.length > 1"
        @onCloseBetInfo="isShowBetInfo = false"
      />
      <mBetRecordView
        v-if="isShowBetRecordView"
        @onCloseBetRecordView="isShowBetRecordView = false"
      />
      <mWagerTypePopup
        v-if="isShowWagerTypePopup"
        :isSub="isSubWagerType"
        @closeWagerTypePopup="closeWagerTypePopup"
      />
      <mMenuPanel
        v-if="ok"
        :isOpen="isOpenMenuPanel"
        @closeMe="isOpenMenuPanel = false"
        @updateGameDetail="reCallGameDetailAPI()"
        @openPersonalPanel="openPersonalPanel"
        @goPage="(v) => (page = v)"
      />
      <mLeaguesPanel
        v-if="ok"
        ref="leaguesPanel"
        :isOpen="isOpenLeaguesPanel"
        :page="page"
        :gameResultCatId="gameResultCatId"
        @closeMe="isOpenLeaguesPanel = false"
        @onLeaguesListChanged="reCallGameDetailAPI()"
        @hasLeagueFiltered="(val) => (hasLeagueFiltered = val)"
        @updateGameResultSelectedLeagueIDs="(IDs) => (gameResultSelectedLeagueIDs = IDs)"
      />
      <ServiceChat
        :isOpen="isOpenServiceChat"
        @closeMe="isOpenServiceChat = false"
        @updateUnreadCount="updateUnreadCount"
      />
      <PersonalPanel
        v-if="isOpenPersonalPanel"
        :type="personalPanelType"
        @closeMe="isOpenPersonalPanel = false"
      />
      <mFloatingBetCart v-show="isShowFloatingBall" @openBetInfoPopup="openBetInfoPopup()" />
    </div>
    <ChatSocket v-if="isSiteOK" />
    <!--
    <Welcome v-if="!$store.state.siteLabels.includes('s1') && isShowWelcome" />
    -->
    <Promotion />
  </div>
</template>

<script>
  import MobileHeader from './components/MobileHeader.vue';
  import MobileFooter from './components/MobileFooter.vue';
  import mGameCatNav from './components/mGameCatNav.vue';
  import mGameResultCatNav from './components/mGameResultCatNav.vue';
  import mGameTable from './components/GameTable/mGameTable.vue';
  import mGameResultTable from './components/GameResultTable/mGameResultTable.vue';
  import mGamesBetInfoAll from './components/mGamesBetInfoAll.vue';
  import mGamesBetInfoSingle from './components/mGamesBetInfoSingle.vue';
  import mBetRecordView from './components/mBetRecordView.vue';
  import mWagerTypePopup from './components/mWagerTypePopup.vue';
  import mMenuPanel from './components/MenuPanel/mMenuPanel.vue';
  import mLeaguesPanel from './components/mLeaguesPanel.vue';
  import MoreGame from '@/components/MoreGame.vue';
  import ServiceChat from '@/components/ServiceChat.vue';
  import mStrayCounter from './components/mStrayCounter';
  import PersonalPanel from '@/components/PersonalPanel';
  import mAnnouncement from './components/mAnnouncement';
  import mLiveScorePage from './components/mLiveScorePage.vue';
  import mRulesPage from './components/mRulesPage';
  import mFloatingBetCart from './components/mFloatingBetCart.vue';
  import ChatSocket from '@/components/ChatSocket';
  import Promotion from '@/components/Promotion.vue';
  import { PageEnum } from '@/config/mobile/enum';
  import { mapGetters } from 'vuex';
  import { favoriteCatID, championWagerKey } from '@/config/index.js';

  export default {
    components: {
      MobileHeader,
      MobileFooter,
      mGameCatNav,
      mGameResultCatNav,
      mGameTable,
      mGameResultTable,
      mGamesBetInfoAll,
      mGamesBetInfoSingle,
      mBetRecordView,
      mWagerTypePopup,
      mMenuPanel,
      mLeaguesPanel,
      MoreGame,
      ServiceChat,
      PersonalPanel,
      mLiveScorePage,
      mRulesPage,
      mFloatingBetCart,
      mAnnouncement,
      mStrayCounter,
      ChatSocket,
      Promotion,
    },
    data() {
      return {
        // 當前頁面
        PageEnum,
        page: PageEnum.game,

        // 組件顯示開關
        isShowBetInfo: false,
        isShowBetRecordView: false,
        isShowWagerTypePopup: false,
        isOpenMenuPanel: false,
        isOpenLeaguesPanel: false,
        isOpenServiceChat: false,
        isOpenPersonalPanel: false,
        latestSelectCatId: null,
        latestSelectWagerTypeKey: null,
        hasLeagueFiltered: false,
        personalPanelType: 1, // 1: 基本資料, 2: 修改密碼

        // 賽果變數
        gameResultCatId: null,
        gameResultDate: null,
        gameResultSelectedLeagueIDs: [],

        // QA未讀數量
        unreadQACount: 0,

        // interval IDs
        intervalEvent: null,
        intervalEvent2: null,
        ok: false,
        championWagerKey,
      };
    },
    computed: {
      ...mapGetters(['isSiteOK']),
      GameList() {
        return this.$store.getters['Game/GameListFilterBySelectLeague'];
      },
      CatList() {
        return this.$store.state.Game.CatList.filter(
          (cat) => cat.CatID !== this.$conf.favoriteCatID
        );
      },
      gameStore() {
        return this.$store.state.Game;
      },
      selectGameType() {
        return this.gameStore.selectGameType;
      },
      selectCatID() {
        return this.gameStore.selectCatID;
      },
      selectWagerTypeKey() {
        return this.gameStore.selectWagerTypeKey;
      },
      gameTypeID() {
        return this.$store.state.Game.selectGameType;
      },
      isFavoriteMode() {
        return this.gameStore.selectCatID === this.$conf.favoriteCatID;
      },
      isShowMoreGame() {
        return this.$store.state.MoreGame.isShowMoreGame;
      },
      betCartList() {
        return this.$store.state.BetCart.betCartList;
      },
      isCallGameDetailAPI() {
        return this.$store.state.Game.isCallGameDetailAPI;
      },
      favorites() {
        return this.$store.state.Setting.UserSetting.favorites;
      },
      isShowFloatingBall() {
        return this.isShowMoreGame && this.betCartList.length > 0 && !this.isShowBetInfo;
      },
      autoSwitchToStrayMode() {
        return this.$store.state.Setting.UserSetting.autoSwitchToStrayMode;
      },
      tracingList() {
        return this.$store.state.BetCart.tracingList;
      },
    },
    watch: {
      isCallGameDetailAPI: {
        handler() {
          this.updateGameDetail({ loading: false, clear: false });
          // if (this.isFavoriteMode) {
          //   this.callGetFavoriteGameDetail(true);
          // } else {
          //   this.callGetGameDetail(
          //     this.gameStore.selectCatID,
          //     this.gameStore.selectWagerTypeKey,
          //     true
          //   );
          // }
        },
      },
      favorites(val) {
        if (val.length === 0 && this.isFavoriteMode) {
          this.reCallGameDetailAPI();
        }
      },
    },
    methods: {
      refesh() {
        const gamesType = this.gameTypeID;
        const gamesCat = this.selectCatID;
        const gamesWager = this.selectWagerTypeKey;
        sessionStorage.setItem('gamesType', gamesType);
        sessionStorage.setItem('gamesCat', gamesCat);
        sessionStorage.setItem('gamesWager', gamesWager);
      },
      initSessionMenu() {
        const gamesType = +sessionStorage.getItem('gamesType');
        const gamesCat = +sessionStorage.getItem('gamesCat');
        const gamesWager = +sessionStorage.getItem('gamesWager');
        this.$store.commit('Game/setGameType', gamesType);
        if (gamesCat === this.$conf.favoriteCatID) {
          this.menuActiveString = '0';
          this.$store.commit('Game/setCatIDAndGameTypeAndWagerType', {
            selectGameType: gamesType,
            selectCatID: this.$conf.favoriteCatID,
            selectWagerTypeKey: null,
          });
          // this.callGetFavoriteGameDetail();
        } else {
          this.$store.commit('Game/changeCatReset', gamesCat);
          // this.callGetGameDetail(gamesCat, gamesWager);
          this.callMainBetInfo(gamesCat, gamesWager);
        }
        this.$store.dispatch('Game/updateGameMenuList', true);
      },
      openWagerTypePopup(isSub = false) {
        this.isShowWagerTypePopup = true;
        this.isSubWagerType = isSub;
      },
      closeWagerTypePopup() {
        this.isShowWagerTypePopup = false;
        this.callMainBetInfo(this.gameStore.selectCatID, this.gameStore.selectWagerTypeKey);
      },
      onSelectCat(catItem) {
        const { catid: catID, Items: wagerList } = catItem;
        const { selectGameType, selectCatID, selectWagerTypeKey } = this;
        const wagerKey = wagerList?.length ? wagerList[0].WagerTypeKey : null;
        if (catID === selectCatID && wagerKey === selectWagerTypeKey) {
          return;
        }
        this.$store.commit('Game/setCatIDAndGameTypeAndWagerType', {
          selectGameType,
          selectCatID: catID,
          selectWagerTypeKey: wagerKey,
        });
        this.$store.commit('MoreGame/closeMoreGameList');
        window.OddData.clear();
        this.$emit('ChangeCat');
        this.updateGameDetail({ loading: true, clear: true });
      },
      onSelectWager(catItem, wagerKey) {
        const { selectGameType, selectCatID, selectWagerTypeKey } = this;
        const { catid: catID } = catItem;
        if (catID === selectCatID && wagerKey === selectWagerTypeKey) {
          return;
        }
        this.latestSelectCatId = catID;
        this.latestSelectWagerTypeKey = selectWagerTypeKey;

        this.$store.commit('Game/setCatIDAndGameTypeAndWagerType', {
          selectGameType,
          selectCatID: catID,
          selectWagerTypeKey: wagerKey,
        });
        this.$store.commit('MoreGame/closeMoreGameList');
        this.updateGameDetail({ loading: true, clear: true });
      },
      callMainBetInfo(catid, WagerTypeKeys) {
        let postWagerTypeKey = WagerTypeKeys;
        if (WagerTypeKeys === null || isNaN(WagerTypeKeys)) {
          postWagerTypeKey = 1;
        }

        this.$store.dispatch('Game/GetMainBetInfo', {
          CatIDs: catid,
          GameTypes: this.gameTypeID.toString(),
          WagerTypeKeys: postWagerTypeKey.toString(),
        });
      },
      callGetGameDetail(CatID, WagerTypeKey = null, updateBehind = false) {
        if (!updateBehind) {
          this.$store.commit('setLoading', true);
        }
        let postData = null;
        postData = {
          GameType: this.gameTypeID,
          CatID,
          WagerTypeKey,
        };

        this.$store.dispatch('Game/GetGameDetail', { postData, updateBehind }).then((res) => {
          this.$store.commit('setLoading', false);
          const gamesType = this.gameTypeID;
          const gamesCat = CatID;
          const gamesWager = WagerTypeKey;
          sessionStorage.setItem('gamesType', gamesType);
          sessionStorage.setItem('gamesCat', gamesCat);
          sessionStorage.setItem('gamesWager', gamesWager);
        });

        if (this.$refs.leaguesPanel) {
          if (this.$refs.leaguesPanel.LeagueListData.length === 0) {
            this.$refs.leaguesPanel.getGameLeagueApi();
          }
        }
      },
      reCallGameDetailAPI() {
        this.updateGameDetail({ loading: true, clear: true }).then((res) => {
          this.$store.dispatch('Game/updateGameMenuList').then((res) => {});
        });
        const catId = this.latestSelectCatId || this.gameStore.selectCatID;
        const WagerTypeKey = this.latestSelectWagerTypeKey || this.gameStore.selectWagerTypeKey;
        // this.callGetGameDetail(catId, WagerTypeKey);
      },
      onSelectType(gameType) {
        const typeKey = gameType;
        const { selectGameType, selectCatID, selectWagerTypeKey } = this;
        if (typeKey === selectGameType) {
          return;
        }

        let catID = null;
        let wagerKey = null;
        if ([favoriteCatID].includes(selectCatID)) {
          catID = selectCatID;
        } else {
          const menuData = this.gameStore.FullMenuList.find(
            (menuData) => menuData.GameType === typeKey
          );
          if (menuData) {
            const { item: catList } = menuData.LeftMenu;
            if (catList?.length) {
              const catItem = catList.find((catItem) => catItem.catid === selectCatID);
              if (catItem) {
                catID = selectCatID;
                const { Items: wagerList } = catItem;
                if (wagerList?.length) {
                  const wagerItem = wagerList.find(
                    (wagerItem) => wagerItem.WagerTypeKey === selectWagerTypeKey
                  );
                  if (wagerItem) {
                    wagerKey = wagerItem.WagerTypeKey;
                  } else {
                    wagerKey = wagerList[0].WagerTypeKey;
                  }
                }
              } else {
                const catData0 = catList[0];
                catID = catData0.catid;
                const { Items: wagerList } = catData0;
                if (wagerList?.length) {
                  wagerKey = wagerList[0].WagerTypeKey;
                }
              }
            }
          }
        }

        if (catID === null) {
          catID = 1;
          wagerKey = 1;
        }

        this.$store.commit('Game/setCatIDAndGameTypeAndWagerType', {
          selectGameType: typeKey,
          selectCatID: catID,
          selectWagerTypeKey: wagerKey,
        });
        this.$store.commit('MoreGame/closeMoreGameList');
        window.OddData.clear();
        this.updateGameDetail({ loading: true, clear: true }).then((res) => {
          this.$store.dispatch('Game/updateGameMenuList').then((res) => {});
          this.$store.commit('Game/changeCatReset', selectCatID);
        });
      },
      // 最愛
      callGetFavoriteGameDetail(isUpdateBehind = false) {
        if (!isUpdateBehind) {
          this.$store.commit('setLoading', true);
        }
        this.$store
          .dispatch('Game/GetFavoriteGameDetail')
          .then((res) => {})
          .finally(() => {
            if (!isUpdateBehind) {
              this.$store.commit('setLoading', false);
            }
          });
      },
      openBetInfoPopup() {
        this.isShowBetInfo = true;
        if (this.betCartList.length > 1) {
          if (this.$refs.betInfoAll) {
            if (this.autoSwitchToStrayMode) {
              this.$refs.betInfoAll.tabIndex = 1;
            } else {
              this.$refs.betInfoAll.tabIndex = 0;
            }
          }
        }
      },
      openBetRecordView() {
        this.isShowBetRecordView = true;
      },
      openMenuPanel() {
        this.isOpenMenuPanel = true;
        this.$store.dispatch('Game/GetAnnouncement');
      },
      openLeaguesPanel() {
        this.isOpenLeaguesPanel = true;
      },
      openService() {
        this.isOpenServiceChat = true;
      },
      updateUnreadCount(count) {
        this.unreadQACount = count;
      },
      clearLeagueList(cat) {
        if (this.$refs.leaguesPanel) this.$refs.leaguesPanel.clearLeagueList();
        this.$store.commit('Game/changeCatReset', cat);
      },
      openPersonalPanel(type) {
        this.personalPanelType = type;
        this.isOpenPersonalPanel = true;
      },
      showTracingResult(item) {
        if (item.status <= 1) return;
        this.$store.commit('BetCart/addTracing');
        this.$store.commit('BetCart/showTracingItem', item);
        const data = {
          data: item.data,
          traceCodeKey: item.code,
          isStray: item.type === 99,
        };
        this.$store.dispatch('BetCart/checkBetState3', data);
        this.$nextTick(() => {
          this.$store.commit('BetCart/removeTracing', item.code);
        });
      },
      getTracingClass(item) {
        switch (item.status) {
          case 0:
            return '';
          case 1:
            return 'ok';
          case 2:
            return 'error';
          default:
            return '';
        }
      },
      updateGameDetail({ loading = false, clear = false }) {
        if (loading) {
          this.$store.commit('setLoading', true);
        }
        return this.$store.dispatch('Game/updateGameDetail', { clear }).finally(() => {
          if (loading) {
            this.$store.commit('setLoading', false);
          }
        });
      },
      updateGameDetailSmall() {
        return this.$store.dispatch('Game/updateGameDetailSmall');
      },
    },
    beforeMount() {
      const { $store } = this;
      if ($store.getters.isUserOk) {
        $store.commit('setLoading', true);
        $store.commit('Setting/init');
        Promise.all([
          $store.dispatch('Game/updateGameCatList'),
          $store.dispatch('Game/updateGameTypeList'),
          $store.dispatch('Game/updateGameMenuList'),
          $store.dispatch('updateUserBalance'),
        ]).then(() => {
          $store.commit('setLoading', false);
          this.ok = true;

          const gamesType = sessionStorage.getItem('gamesType');
          const gamesCat = sessionStorage.getItem('gamesCat');

          if (gamesType !== null && gamesCat !== null) {
            this.initSessionMenu();
          }
          this.updateGameDetail({ loading: true, clear: true });

          // 賽果預設選取第一個彩種
          if (this.CatList[0]) {
            this.gameResultCatId = this.CatList[0].CatID;
          }

          // 更新 賠率: 每10秒
          this.intervalEvent = setInterval(() => {
            this.updateGameDetailSmall();
            this.$store.dispatch('updateUserBalance');
          }, 10000);

          // 更新 MENU: 每20秒
          this.intervalEvent2 = setInterval(() => {
            this.$store.dispatch('Game/updateGameMenuList', false);
          }, 20000);
        });
      } else {
        this.$router.replace({ name: 'Login' });
      }
    },
    mounted() {
      // 強制修正 viewport高度
      function syncHeight() {
        document.documentElement.style.setProperty(
          '--window-inner-height',
          `${window.innerHeight}px`
        );
      }
      window.addEventListener('resize', syncHeight);

      // 強制禁用 ios 兩指縮放 (舊)
      document.documentElement.addEventListener(
        'touchstart',
        function (event) {
          if (event.touches.length > 1) {
            event.preventDefault();
          }
        },
        false
      );

      // 強制禁用 ios 兩指縮放 (新)
      document.documentElement.addEventListener(
        'gesturestart',
        (event) => event.preventDefault(),
        false
      );

      this.$watch(
        () => {
          return this.betCartList.length;
        },
        (to, from) => {
          if (from === 0 && to === 1) {
            setTimeout(() => {
              this.isShowBetInfo = true;
            }, 0);
          }
        }
      );
    },
    beforeDestroy() {
      clearInterval(this.intervalEvent);
      clearInterval(this.intervalEvent2);
    },
  };
</script>

<style lang="scss" scoped>
  :root {
    --window-inner-height: 100%;
  }

  * {
    user-select: none;
  }
  html {
    font-size: 14px;
    @media screen and (max-width: 1000px) {
      font-size: 14px;
    }
    @media screen and (max-width: 768px) {
      font-size: 12px;
    }
    @media screen and (max-width: 350px) {
      font-size: 10px;
    }
  }

  html,
  body {
    width: 100%;
    height: 100%;
    height: var(--window-inner-height);
    margin-top: 0.01px;
    overflow-x: hidden;
    overflow-y: hidden;
    touch-action: manipulation;
    margin-top: 0.01px;
  }

  body {
    margin-bottom: 0px;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    padding-bottom: 0px;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    touch-action: manipulation;
    text-size-adjust: 100%;
    -webkit-overflow-scrolling: touch;
  }

  #MobileGames {
    width: 100%;
    height: 100%;

    .main-layout {
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;

      .main-header {
        flex: 0 0;
      }

      .main-body {
        flex: 1 0;
        display: flex;
        flex-flow: column;
        overflow: hidden;
        background-color: #eee;
      }

      .main-footer {
        flex: 0 0;
        z-index: 10;
      }

      .noData {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2rem;
      }
    }
    .fixed-container {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;

      & > div {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
      }
    }
  }
  #tracing {
    position: absolute;
    bottom: 60px;
    right: 0;
    margin: 4px;
    z-index: 100;

    .item {
      display: inline-block;
      background-color: #ccc;
      border-radius: 4px;
      height: 32px;
      line-height: 32px;
      width: 64px;
      text-align: center;
      margin-left: 4px;
      &.error {
        background-color: #c03b35;
        color: #fff;
        cursor: pointer;
      }
    }
  }
  #app[data-theme^='dark'] {
    #MobileGames {
      .main-body {
        background-color: #404040;
      }
    }
  }
</style>
