<template>
  <div id="mMenuPanel" :class="isOpen ? 'open' : ''">
    <div class="overlay" @click="close()" />
    <div class="panel">
      <div class="header-container">
        <img class="level" src="@/assets/img/mobile/icon_level1.svg" />
        <div class="player-id">
          {{ userAccount }}
          <span class="more-id"> {{ userName }} </span>
        </div>
        <div class="btn-announcement" @click="goAnnouncementPage()">
          <span v-show="hasUnreadAnnouncements">
            <div
              style="
                width: 10px;
                height: 10px;
                background-color: red;
                border-radius: 50%;
                position: relative;
                top: 4px;
                left: 8px;
              "
            />
          </span>
        </div>
      </div>
      <div class="main-container">
        <ul class="feature-list">
          <li class="feature-item">
            <div class="item-wrap" @click="openLive()">
              <img class="icon" src="@/assets/img/mobile/icon_live.svg" />
              <div class="text">
                {{ $t('Common.LivePlay') }}
              </div>
            </div>
          </li>
          <li class="feature-item">
            <div class="item-wrap" @click="openMovie()">
              <img class="icon" src="@/assets/img/mobile/movie.svg" />
              <div class="text">
                {{ $t('Common.Movie') }}
              </div>
            </div>
          </li>
          <li class="feature-item">
            <div class="item-wrap" @click="goLiveScorePage()">
              <img class="icon" src="@/assets/img/mobile/icon_score.svg" />
              <div class="text">
                {{ $t('GamesHeader.LiveScore') }}
              </div>
            </div>
          </li>
          <li class="feature-item">
            <div class="item-wrap" @click="goStrayCounterPage()">
              <img class="icon" src="@/assets/img/mobile/icon_count.svg" />
              <div class="text">
                {{ $t('GamesBetInfo.StrayCount') }}
              </div>
            </div>
          </li>
          <li class="feature-item">
            <div class="item-wrap" @click="goGameResultPage()">
              <img class="icon" src="@/assets/img/mobile/icon_result.svg" />
              <div class="text">
                {{ $t('GamesHeader.GameResult') }}
              </div>
            </div>
          </li>
          <li class="feature-item">
            <div class="item-wrap" @click="goRulesPage()">
              <img class="icon" src="@/assets/img/mobile/icon_rule.svg" />
              <div class="text">
                {{ $t('GamesHeader.GameRule') }}
              </div>
            </div>
          </li>
          <li class="feature-item">
            <div class="item-wrap" @click="openSecondaryPanel('功能設定')">
              <img class="icon" src="@/assets/img/mobile/icon_funSet.svg" />
              <div class="text">
                {{ $t('GamesBetInfo.Setting') }}
              </div>
            </div>
          </li>
          <li class="feature-item">
            <div class="item-wrap" @click="openPersonalPanel(1)">
              <img class="icon" src="@/assets/img/mobile/icon_header_user.svg" />
              <div class="text">
                {{ $t('GamesHeader.PersonalSetting') }}
              </div>
            </div>
            <ul class="sub-items">
              <li @click="openPersonalPanel(1)">
                {{ $t('Common.Profile') }}
              </li>
              <li v-if="isCreditSite" @click="openPersonalPanel(2)">
                {{ $t('Common.ChangePassword') }}
              </li>
              <li @click="openPlayLimits"> 查看限紅 </li>
            </ul>
          </li>
          <li class="feature-item">
            <div class="item-wrap" @click="goToDesktopVersion()">
              <img class="icon" src="@/assets/img/mobile/icon_screen.svg" />
              <div class="text">
                {{ $t('Login.SwitchToPCVersion') }}
              </div>
            </div>
          </li>
        </ul>
        <div class="theme-bar">
          <img class="icon" src="@/assets/img/mobile/icon_exterior.svg" />
          <div class="text">
            {{ $t('Common.ThemeColor') }}
          </div>
        </div>
        <ul class="theme-list">
          <li>
            <div style="display: flex">
              <div style="flex: 0 0 60px">
                {{ $t('Common.ThemeMode') }}
              </div>
              <div style="display: flex; flex-wrap: wrap">
                <div v-for="t in themeModes" :key="t" class="theme1">
                  <img
                    :src="require(`@/assets/theme/${t}.svg`)"
                    height="24px"
                    @click="setThemeMode(t)"
                  />
                  <div
                    v-if="t === themeMode"
                    class="theme1-current"
                    style="
                      position: absolute;
                      bottom: 6px;
                      right: 2px;
                      color: green;
                      pointer-events: none;
                    "
                  >
                    V
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div style="display: flex">
              <div style="flex: 0 0 60px">
                {{ $t('Common.ThemeModeColor') }}
              </div>
              <div style="display: flex; flex: 0 0 100px; flex-wrap: wrap">
                <div v-for="t in themeMap" :key="t.key" class="theme2-wrap">
                  <div
                    class="theme2"
                    :class="{ 'theme2-current': t.key === themeKey }"
                    :style="'flex: 0 0 25px; background-color:' + t[themeMode]"
                    @click="setThemeKey(t.key)"
                  >
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <!--<ul class="feature-list" style="margin-top: 32px">
          <li class="feature-item"></li>
          <li class="feature-item">
            <div class="item-wrap" @click="openDGLive()">
              <img class="icon" src="@/assets/img/mobile/icon_screen.svg" />
              <div class="text"> DG 真人 </div>
            </div>
          </li>
        </ul>-->
        <ul v-if="isCreditSite" class="feature-list" style="margin-top: 32px">
          <li class="feature-item">
            <div class="item-wrap" @click="logout()">
              <img class="icon" src="@/assets/img/mobile/logout.svg" />
              <div class="text">
                {{ $t('GamesHeader.Logout') }}
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="secondaryPanel" :class="isSecondaryPanelOpened ? 'open' : ''">
        <div class="secondaryPanel-header">
          <img
            src="@/assets/img/mobile/btn_arrow_w.svg"
            class="btn-back"
            @click="closeSecondaryPanel"
          />
          <div class="title">
            {{ secondaryPanelTitle }}
          </div>
        </div>
        <div class="secondaryPanel-body">
          <mAdvancedSettings
            v-if="isShowAdvancedSettings"
            @updateGameDetail="$emit('updateGameDetail')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState, mapGetters, mapMutations } from 'vuex';
  import { themeModes, themeMap } from '@/config/theme';
  import mAdvancedSettings from './mAdvancedSettings.vue';
  import { PageEnum } from '@/config/mobile/enum';
  import { getSportLiveApi, getMovieTheaterApi } from '@/api/sport';
  import { getDGLiveLoginApi } from '@/api/third';
  import { gotoPcHost } from '@/config/host';

  export default {
    components: {
      mAdvancedSettings,
    },
    props: {
      isOpen: {
        type: Boolean,
      },
    },
    data() {
      return {
        isSecondaryPanelOpened: false,
        isShowAdvancedSettings: true,
        secondaryPanelTitle: 'title',
        PageEnum,
        themeModes,
        themeMap,
      };
    },
    computed: {
      ...mapState(['userBalance', 'sessionToken', 'sessionAccount', 'themeMode', 'themeKey']),
      ...mapGetters(['isCreditSite', 'userAccount', 'userName', 'themeInfo']),
      hasUnreadAnnouncements() {
        return this.$store.getters['Game/hasUnreadAnnouncements'];
      },
    },
    methods: {
      ...mapMutations(['setThemeMode', 'setThemeKey']),
      openLive() {
        this.$store.commit('setLoading', true);
        getSportLiveApi()
          .then((res) => {
            window.open(res.data, 'live');
          })
          .finally(() => {
            this.$store.commit('setLoading', false);
          });
      },
      openMovie() {
        this.$store.commit('setLoading', true);
        getMovieTheaterApi()
          .then((res) => {
            window.open(res.data, 'live');
          })
          .finally(() => {
            this.$store.commit('setLoading', false);
          });
      },
      openDGLive() {
        this.$store.commit('setLoading', true);
        getDGLiveLoginApi()
          .then((response) => {
            window.open(response.data.GameUrl, 'dglive');
          })
          .finally(() => {
            this.$store.commit('setLoading', false);
          });
      },
      goToDesktopVersion() {
        gotoPcHost();
      },
      openOld() {
        let host = location.hostname.split('.');
        if (host[0].toLowerCase() === 'www') {
          host = host.slice(1);
        }
        if (host[0].toLowerCase() === 'm') {
          host = host.slice(1);
        }
        host.unshift('old');
        const path = host.join('.');
        window.open(document.location.protocol + '//' + path);
      },
      openPlayLimits() {
        const limits = this.$router.resolve({
          path: 'Limits',
        });
        window.open(limits.href, 'limits');
      },
      logout() {
        this.$store.commit('setLoading', true);
        this.$store.dispatch('logout').finally(() => {
          this.$store.commit('setLoading', false);
        });
      },
      close() {
        this.$emit('closeMe');
        this.isSecondaryPanelOpened = false;
      },
      openSecondaryPanel(title) {
        this.isSecondaryPanelOpened = true;
        this.secondaryPanelTitle = title;
      },
      closeSecondaryPanel() {
        this.isSecondaryPanelOpened = false;
      },
      goLiveScorePage() {
        this.$emit('goPage', PageEnum.liveScore);
        this.close();
      },
      goRulesPage() {
        this.$emit('goPage', PageEnum.rules);
        this.close();
      },
      goGameResultPage() {
        this.$emit('goPage', PageEnum.gameResult);
        this.close();
      },
      goAnnouncementPage() {
        this.$emit('goPage', PageEnum.announcement);
        this.close();
      },
      goStrayCounterPage() {
        this.$emit('goPage', PageEnum.strayCounter);
        this.close();
      },
      openPersonalPanel(type) {
        this.$emit('openPersonalPanel', type);
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/sass/theme/mixin.scss';

  #mMenuPanel {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 20;
    pointer-events: none;
    overflow: hidden;

    &.open {
      pointer-events: auto;
      .overlay {
        opacity: 1;
      }
      .panel {
        transform: translateX(0);
        opacity: 1;
      }
    }

    .overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.7);
      opacity: 0;
      transition: 350ms ease;
    }

    .panel {
      position: relative;
      width: 70%;
      height: 100%;
      max-width: 300px;
      min-width: 180px;
      float: right;
      display: flex;
      flex-direction: column;
      background-color: #e4e4e4;
      overflow: hidden;
      transform: translateX(100%);
      opacity: 0;
      transition: 350ms ease;

      .header-container {
        height: 3.5rem;
        width: 100%;
        display: flex;
        flex-shrink: 0;
        justify-content: space-between;
        align-items: center;
        border-bottom: 3px solid #ccc;
        background-color: #e4e4e4;

        .level {
          width: 50px;
          height: 22px;
          flex-shrink: 0;
        }
        .player-id {
          color: #000;
          font-size: 1.25rem;
          text-align: left;
          flex-grow: 1;
          padding: 0 5px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          .more-id {
            padding-left: 5px;
            color: #0169ff;
          }
        }

        .btn-announcement {
          position: relative;
          flex-shrink: 0;
          width: 50px;
          height: 100%;
          background-image: url('~@/assets/img/mobile/btn_announcement.svg');
          background-size: 24px;
          background-position: center center;
          background-repeat: no-repeat;
          cursor: pointer;
          &:active {
            background-color: #fff;
          }

          &.point {
            &:after {
              content: '';
              background-color: #f00;
              border-radius: 50%;
              position: absolute;
              width: 7px;
              height: 7px;
              top: 0;
              bottom: 50%;
              left: 18px;
              right: 0;
              margin: auto;
            }
          }
        }
      }

      .main-container {
        flex: auto;
        overflow: auto;
        padding: 0.714rem 0;

        ul.feature-list {
          margin-bottom: 10px;
          font-size: 1.2rem;
          line-height: 1;
          & > li {
            position: relative;
            &:after {
              content: '';
              position: absolute;
              right: 0;
              width: calc(100% - 50px);
              height: 1px;
              background-color: #ccc;
            }
            .item-wrap {
              display: flex;
              flex-direction: row;
              background-color: transparent;
              cursor: pointer;
              width: 100%;
              min-height: 40px;

              img.icon {
                width: 21px;
                margin: 0 15px;
                align-self: center;
              }

              .text {
                width: calc(100% - 50px);
                flex: 1;
                line-height: 40px;
                text-align: left;
                color: #000;
                padding: 0 5px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              &:active {
                background-color: #f1f2f3;
              }
            }
            ul.sub-items {
              margin: 8px 0;
              margin-left: 65px;
              li {
                margin-bottom: 15px;
                &:last-child {
                  margin: 0;
                }
              }
            }
          }
        }

        .theme-bar {
          display: flex;
          align-items: center;
          padding: 5px 0;
          font-size: 1.2rem;
          background: #ccc;
          min-height: 30px;

          .text {
            padding-left: 5px;
            line-height: normal;
          }

          img.icon {
            width: 21px;
            margin: 0 15px;
          }
        }

        ul.theme-list {
          padding-left: 52px;

          li {
            position: relative;
            font-size: 1.2rem;
            padding-left: 5px;
            // margin-bottom: 1rem;
            line-height: 32px;
            height: 32px;

            &:last-child {
              margin-bottom: 0px;
            }

            &:active {
              // background-color: #fff;
            }

            &.active {
              &:after {
                content: '';
                background-image: url('~@/assets/img/mobile/icon_check_blue.svg');
                background-position: center center;
                background-repeat: no-repeat;
                position: absolute;
                width: 20px;
                height: 100%;
                top: 0;
                right: 0;
                margin-right: 10px;
              }
            }
          }
        }
      }

      .secondaryPanel {
        display: flex;
        flex-flow: column nowrap;
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        background: #e4e4e4;
        transform: translateX(100%);
        transition: 350ms ease;

        &.open {
          opacity: 1;
          transform: translateX(0);
          box-shadow: 0 0 100px rgba(0, 0, 0, 0.8);
        }

        .secondaryPanel-header {
          flex-shrink: 0;
          height: 3.5rem;
          width: 100%;
          // background: #ccc;
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          border: 1px solid #aaa;
          box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
          z-index: 1;

          .title {
            font-size: 1.5rem;
            font-weight: bold;
            line-height: normal;
            white-space: nowrap;
          }

          .btn-back {
            width: 1.5rem;
            height: 1.5rem;
            margin: 0.6rem;
            transform: rotate(90deg);
            filter: contrast(0);
          }
        }

        .secondaryPanel-body {
          flex-grow: 1;
          background: #e4e4e4;
          overflow: auto;
        }
      }
    }
  }
  #mMenuPanel {
    .theme1 {
      position: relative;
      display: inline-block;
      margin: 4px;
      cursor: pointer;
    }
    .theme1-current {
      position: absolute;
      bottom: 6px;
      right: 2px;
      color: green;
      pointer-events: none;
    }
    .theme2-wrap {
      position: relative;
      display: inline-block;
      width: 24px;
      height: 24px;
      margin-bottom: 2px;
    }
    .theme2 {
      position: absolute;
      width: 16px;
      height: 16px;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      border-radius: 50%;
      cursor: pointer;
    }
    .theme2-current {
      outline: 1px solid gray;
      outline-offset: 3px;
    }
  }
</style>
