<template>
  <div id="mRulesPage">
    <iframe id="my-iframe" :src="src"></iframe>
  </div>
</template>

<script>
  import i18n from '@/i18n';
  export default {
    name: 'mRulesPage',
    computed: {
      src() {
        const path = `rules/${i18n.locale}`;
        return path;
      },
    },
  };
</script>

<style lang="scss" scoped>
  #mRulesPage {
    flex: 1;

    iframe {
      height: 100%;
      width: 100%;
      border: none;
    }
  }
</style>
